import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../history';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, Divider, Form, Grid, Image, Responsive, Header } from 'semantic-ui-react';
import { loginUser, forgotPw, clearPwErrors } from '../actions';
import googleBtn from '../images/sign-in-with-google.svg';
import './styles/auth-modal.scss';

class AuthModal extends Component {
  state = {
    forgotPassword: false
  }

  googleLogin () {
    return window.location.href = '/auth/google';
  }

  renderInput = ({ input, label, type, meta }) => {
    return (
      <div className="field form-field">
        <label> {label} </label>
        <input {...input} type={type} autoComplete="off" />
      </div>
    )
  }

  onSubmit = formValues => {
    formValues['source'] = history.location.pathname;
    this.props.loginUser({ source: this.props.source, code: this.props.code, ...formValues});
  }

  resetPw = formValues => {
    formValues['source'] = history.location.pathname;
    this.props.forgotPw({ source: this.props.source, code: this.props.code, ...formValues});
  }
  

  render () {
    return (
      <div className="inline">
      <Responsive {...Responsive.onlyMobile} className="inline">
        <Modal trigger={<span className="login-span">Login</span>} size='small' centered={true} onClose={() => {this.setState({forgotPassword: false}); this.props.clearPwErrors()}}>
            <Modal.Content>
            {this.props.auth ?
              <div>
                <Header />
                  <div className="logged-in-already">
                  You are already logged in!
                </div>
              </div>
              :
              <Grid columns={2} relaxed='very' stackable className="login-grid">
                <Grid.Row>
                  <Grid.Column>
                    <div className="ala-login-header"> <b>Login with your ALA Account</b> </div>
                    <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                      <Field
                      className="login-form"
                      name="username"
                      component={this.renderInput}
                       icon='user'
                       iconPosition='left'
                       label='Username'
                       placeholder='Username'
                     />
                     <Field
                      className="login-form"
                      name="password"
                      component={this.renderInput}
                       icon='lock'
                       iconPosition='left'
                       label='Password'
                       type='password'
                     />
                     <Button className="ala-login-btn" content='Login' primary />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="flex-center">
                    <b>-- OR --</b>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column verticalAlign='middle'>
                    <Image className="g-signin-btn" onClick={this.googleLogin} src={googleBtn} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="flex-center">
                    <div className="reg-error-header">{this.props.authErrors ? this.props.authErrors.message : null}</div>
                    <div className="login-footer"> <b>New to ALA?</b> <div> Sign in with Google or <a href="/register">register here</a>.</div> </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            }
            </Modal.Content>
          </Modal>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="inline">
          <Modal trigger={<span className="login-span">Login</span>} size='small' centered={true} onClose={() => {this.setState({forgotPassword: false}); this.props.clearPwErrors()}}>
            <Modal.Content>
            {!this.props.auth && this.state.forgotPassword && (
              <div>
                <Grid columns={1} relaxed='very' stackable>
                  <Grid.Column>
                    <div className="ala-login-header"> <b>Enter your ALA username and/or email to reset your password.</b> </div>
                    <Form onSubmit={this.props.handleSubmit(this.resetPw)}>
                      <Field
                        name="username"
                        component={this.renderInput}
                        icon='user'
                        iconPosition='left'
                        label='Username'
                        placeholder='Username'
                      />
                      <Field
                        name="email"
                        component={this.renderInput}
                        icon='lock'
                        iconPosition='left'
                        label='Email'
                        type='email'
                      /> 
                      <div className="forgot-pw-container">
                        <Button className="forgot-pw-btn" content='Reset Password' primary />
                      </div>     
                    </Form>
                  </Grid.Column>
                  <Grid.Column textAlign='center'>
                    <div className="reg-error-header">{!this.props.pwReset.success ? this.props.pwReset.message : null}</div>
                    <div className="pw-reset-success">{this.props.pwReset.success ? this.props.pwReset.message : null}</div>
                    <div className="return-login" onClick={() => this.setState({forgotPassword: false})}> <b>Return to Login Screen</b> </div>
                  </Grid.Column>
                </Grid>
              </div>
            )}
            
            {this.props.auth && !this.state.forgotPassword && (
              <div>
                <Header />
                  <div className="logged-in-already">
                  You are already logged in!
                </div>
              </div>
            )}
            {!this.props.auth && !this.state.forgotPassword && (
              <div>
                <Grid columns={2} relaxed='very' stackable className="login-grid">
                  <Grid.Column>
                    <div className="ala-login-header"> <b>Login with your ALA Account</b> </div>
                    <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                      <Field
                      className="login-form"
                      name="username"
                      component={this.renderInput}
                      icon='user'
                      iconPosition='left'
                      label='Username'
                      placeholder='Username'
                    />
                    <Field
                      className="login-form"
                      name="password"
                      component={this.renderInput}
                      icon='lock'
                      iconPosition='left'
                      label='Password'
                      type='password'
                    />
                    <Grid columns={2} relaxed='very' stackable>
                      <Grid.Column>
                        <div className="forgot-pw" onClick={() => this.setState({forgotPassword: true})}> <b>Forgot Password?</b></div>
                      </Grid.Column>
                      <Grid.Column>
                        <Button className="ala-login-btn" content='Login' primary />
                      </Grid.Column>
                    </Grid>
                    </Form>
                  </Grid.Column>
                  <Grid.Column verticalAlign='middle'>
                    <Image className="g-signin-btn" onClick={this.googleLogin} src={googleBtn} />
                  </Grid.Column>
                </Grid>
                <Divider vertical hidden>Or</Divider>
                <div className="reg-error-header">{this.props.authErrors ? this.props.authErrors.message : null}</div>
                <div className="login-footer"> <b>New to ALA?</b> Sign in with Google to create an account or <a href="/register">register here</a>. </div>
              </div>
            )}
            </Modal.Content>
          </Modal>
        </Responsive>
      </div>
    )
  }
}

const formWrapped = reduxForm({
  form: 'login'
})(AuthModal);

function mapStateToProps ({ auth, authErrors, pwReset }) {
  return { auth, authErrors, pwReset }
}

export default connect(mapStateToProps, { loginUser, forgotPw, clearPwErrors })(formWrapped);
