import React from 'react';
import { Icon } from 'semantic-ui-react';

const Lives = props => {
  let lives = [];
  for (let i=0; i<props.lives; i++) {
    lives.push(
      <Icon key={i} name="user outline" />
    )
  }
  return lives;
}

export default Lives;
