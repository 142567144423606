import React from 'react';
import { Icon, List, Item, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ContextPopup from './../other_helpers/ContextPopup';

function CreateAPool () {
  return (
    <Item.Group className="create-pool-instructions">
      <Item>
        <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/create_dame.jpeg' />
        <Item.Content>
          <Item.Header><Icon name='lab' style={{marginRight: '7px'}}/> STEP 1: CREATE A LEAGUE.</Item.Header>
          <Item.Meta><Icon name='angle right'/><i>Create your ALA Survivor League through the <Link as='a' to='/leagues/create'><b>Create a League</b></Link> page.</i></Item.Meta>
          <Item.Description>
            The first step toward creating an ALA Survivor Pool is creating an ALA Survivor League, which is a one-time action. Once you've created a league, you never need to repeat that process again -- just skip to Step 2 and use your league homepage to create a new pool. Your league comes with a host of features and functions, including:
            <List style={{margin: 10}}>
              <List.Item>
                <List.Icon name='bullhorn' />
                <List.Content><b>Pool Start-Up Notifications.</b> Every time you create a new ALA Survivor Pool, your league members will be notified via email so that they can join the fun.</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='line graph' />
                <List.Content><b>All-Time Rankings.</b> See how your performance stacks up against your friends, your fellow league members, and the global ALA community over years' worth of Survivor Pools.</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='lock' />
                <List.Content><b>Public, or Private?</b> Decide whether your league -- and your pools -- should be open to the public or restricted to those who receive invitations.</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='mail' />
                <List.Content><b>Invitations.</b> Your league homepage contains a link that you can send to friends so they can quickly join your league -- or you can type in a friend's email address and ALA will send them a customized invitation. Once a friend has joined your league, they will be able to join your pools through your league homepage.</List.Content>
              </List.Item>              
              <List.Item>
                <List.Icon name='talk' />
                <List.Content><b>Messaging.</b> Reach out to other league members to mock their picks, boast about your success, or discuss potential prize splits.</List.Content>
              </List.Item>
            </List>
          </Item.Description>
        </Item.Content>
      </Item>
      <Divider />
      <Item>
        <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/create_trout.jpeg' />
        <Item.Content>
        <Item.Header><Icon name='lab' style={{marginRight: '7px'}}/> STEP 2: CREATE AND CUSTOMIZE YOUR POOL.</Item.Header>
          <Item.Meta><Icon name='angle right'/><i>After you've created your league, you can create a pool via the <b>'Create Pool'</b> tab found on your league homepage.</i></Item.Meta>
          <Item.Description>
            When you are creating an ALA Survivor Pool, you will have a number of setup and configuration options at your disposal:
            <List style={{margin: 10}}>
              <List.Item>
                <List.Icon name='dollar' />
                <List.Content><b>Initial Buy-in.</b> Set the standard entry fee for participation in your pool. </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='group' />
                <List.Content><b>Extra Lives/Rebuys.</b> Set the number of lives your pool's entrants should be allowed over the course of the season. ALA recommends <b>three</b> lives for NBA/MLB Survivor.</List.Content>
              </List.Item>              
              <List.Item>
                <List.Icon name='money bill alternate outline' />
                <List.Content><b>Rebuy Cost.</b> Set a dollar amount for each rebuy/extra life. If you'd prefer rebuys be free, you can set this to $0.</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='shield alternate' />
                <List.Content><b>Insurance?</b> Permit entrants to pay a one-time fee that protects them against missed picks. 
                  <ContextPopup
                    header="What's Insurance?"
                    content="Enter a dollar value to allow your pool's entrants to buy a one-time insurance policy that protects them against missed picks. If an entrant purchases insurance and forgets to make a pick in a given week, the entrant will automatically be given the most popular pick in your pool that the entrant still has available. The proceeds from insurance policies are automatically included in your league's prize pool calculations."
                    trigger={<span style={{marginLeft: 2}}><b><u>Learn more.</u></b></span>}
                  />
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='rocket' />
                <List.Content><b>Bonus Pool?</b> Decide whether your league will have a Bonus Pool -- an advanced game configuration option for the true degenerates amongst us -- and configure your Bonus Pool to your liking. To learn more about ALA Survivor Bonus Pools, check out the <b>How to Play</b> page.
                </List.Content>
              </List.Item>
            </List>
          </Item.Description>
        </Item.Content>
      </Item>
      <Divider />
      <Item>
        <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/create_nique.png' />
        <Item.Content>
        <Item.Header><Icon name='lab' style={{marginRight: '7px'}}/> STEP 3: INVITE YOUR FRIENDS.</Item.Header>
          <Item.Meta><Icon name='angle right'/><i>Send out email invitations, or copy and send out your league's link-to-join, through your league homepage.</i></Item.Meta>
          <Item.Description>
            Once you have created your ALA Survivor League, you can begin inviting your friends. If your friends join your league before you create your first pool, they will receive an email notification when you open a pool for entry. Click the image to the left for a zoomed-in view showing how to send out invitiations; you can either send them the league-specific link-to-join URL that's found on your league homepage, or you can type in your friends' email addresses and ALA will send them custom invitations to lead them straight into your leagues and pools.
          </Item.Description>
        </Item.Content>
      </Item>
      <Divider />
      <Item>
        <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/create_xander.jpeg' />
        <Item.Content>
        <Item.Header><Icon name='lab' style={{marginRight: '7px'}}/> STEP 4: START MAKING PICKS!</Item.Header>
          <Item.Meta><Icon name='angle right'/><i>Plot out your season-long strategy with ease using ALA's full-season Team Schedule Viewer.</i></Item.Meta>
          <Item.Description>
            ALA's platform takes the hard work out of your season-long Survivor strategy by pairing our lightning-fast Team Schedule Viewer with functionality that allows you to submit selections for as many weeks as you'd like at any time. Wondering why all the other major Survivor Pool providers offer this same functionality and flexibility? We're not sure, either.
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

export default CreateAPool;
