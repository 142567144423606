import { FETCH_POOL_HEALTH, CLEAR_POOL } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case FETCH_POOL_HEALTH:
      return action.payload;
    case CLEAR_POOL:
      return null;
    default:
      return state;
  }
}
