import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Image, Pagination, Transition, Modal, Header, Button, Icon, Loader, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { makeUserPick, fetchActiveGameWeek } from '../../actions';
import logos from '../../modules/logos';
import RenderWeekSchedule from '../table_helpers/RenderWeekSchedule';
import '../styles/team-schedules.scss';

class MakePicks extends Component {
  state = {
    visible: false,
    activeTeam: null,
    userActionDesc: null,
    userAction: null,
    render: false,
    weeks: [],
    schedWeek: this.props.activeGameWeek,
    locked: false,
    userSeason: this.props.auth && this.props.auth[0].userSeasons.find(us => us.league_season === this.props.activePool.id) || false,
  };

  show = () => this.setState({ visible: true });
  close = () => this.setState({ visible: false });

  componentDidMount () {
    const { activePool } = this.props;
    if (!this.state.schedWeek) {
      fetchActiveGameWeek(activePool.game, activePool.season, activePool.season_type)
    }
    let weeks = [];
    if (moment().isAfter(moment(this.props.deadlines[this.state.schedWeek-1]))) {
      this.setState({ locked: true });
    };
    this.props.scheduleWeeks.forEach((week, idx, arr) => {
      weeks.push(idx + 1);
      if (idx === arr.length-1) {
        this.setState({ weeks })
        this.setState({ render: true});
      }
    })
    setInterval(() => {
      if (moment().isAfter(moment(this.props.deadlines[this.state.schedWeek-1]))) {
        this.setState({ locked: true });
      } else {
        this.setState({ locked: false });
      }}, 30000);
  }

  componentDidUpdate() {
    const { auth, activePool } = this.props;
    const { userSeason, schedWeek} = this.state;
    if (auth && auth[0].userSeasons.find(us => us.league_season === activePool.id)) {
      let authPick = auth[0].userSeasons.find(us => us.league_season === activePool.id).selections[0][schedWeek - 1];
      if (userSeason.selections[0][schedWeek - 1] !== authPick) {
        this.setState({userSeason: auth[0].userSeasons.find(us => us.league_season === activePool.id)});
      }
    }
  }

  changeWeek = (e, { activePage }) => {
    this.setState({ schedWeek: activePage});
    if (moment().isAfter(moment(this.props.deadlines[activePage-1]))) {
      this.setState({ locked: true });
    } else {
      this.setState({ locked: false });
    }
  };

  setPickData = (activeTeam, userAction) => {
    let userActionDesc = null;
    if (userAction === 'confirm') {
      userActionDesc = `You are selecting the ${this.fullTeamName(activeTeam).toUpperCase()} for Week ${this.state.schedWeek}. You are free to change this pick at anytime up until the first game of Week ${this.state.schedWeek} begins.`;
    } else if (userAction === 'cancel') {
      userActionDesc = `You are cancelling your selection of the ${this.fullTeamName(activeTeam).toUpperCase()} for Week ${this.state.schedWeek}.`;
    };
    this.setState({
      activeTeam,
      userAction,
      userActionDesc,
      visible: true
    });
  }

  fullTeamName = abb => `${this.props.teamDetails[abb].c.toUpperCase()} ${this.props.teamDetails[abb].name.toUpperCase()}`;

  makeUserPick = action => {
    const {auth, activePool, league} = this.props;
    const pick = {
      auth,
      team: this.state.activeTeam,
      week: this.state.schedWeek,
      activePool,
      league_name: league.info.name
    };

    if (moment().isAfter(moment(this.props.deadlines[this.state.schedWeek-1]))) {
      alert("Sorry, this week's pick deadline has passed. Consider begging the Commish for mercy?")
      this.setState({visible: false, activeTeam: null})
    } else {
      this.props.makeUserPick({...pick, action});
      this.setState({visible: false, activeTeam: null})
    }
  }

  renderHeaders (week = this.state.schedWeek) {
    return (
      this.props.scheduleWeeks[(week - 1)].map(day =>
        <Table.HeaderCell key={day} className="sched-date-head"> {moment(day).format("ddd M/D").toUpperCase()} </Table.HeaderCell>
      )
    )
  }

  renderPickBox (team) {
    const { auth, deadlines, activeGameWeek } = this.props;
    const { userSeason } = this.state;
    if (userSeason) {
      if (team === userSeason.selections[0][this.state.schedWeek - 1] && moment().isBefore(moment(deadlines[this.state.schedWeek-1]))) {
        return (
          <Table.Cell
            selectable
            className="selectedTeam">
            <a onClick={() => this.setPickData(team, "cancel")}></a>
          </Table.Cell>
        )
      } else if (userSeason.selections[0].indexOf(team) !== -1) {
        if ((userSeason.selections[0].indexOf(team) + 1) < activeGameWeek) {
          return (
            <Table.Cell
              className="used">
              {`Week ${userSeason.selections[0].indexOf(team) + 1}`}
            </Table.Cell>
          )
        } else {
          return (
            <Table.Cell
              className="unavailable">
              {`Week ${userSeason.selections[0].indexOf(team) + 1}`}
            </Table.Cell>
          )
        }
      } else if (
        userSeason.selections[0].indexOf(team) === -1 &&
        moment().isAfter(moment(deadlines[this.state.schedWeek-1]))
      ) {
        return <Table.Cell style={{backgroundColor: '#F0F1F6'}}>  </Table.Cell>
      } else if (userSeason.extinct) {
        return (
          <Table.Cell
            className="eliminated">
            ELIMINATED
          </Table.Cell>
        )
      } else if (!userSeason.alive) {
        return (
          <Table.Cell
            className="rebuyToPick">
            REBUY TO PICK
          </Table.Cell>
        )
      } else {
        return (
          <Table.Cell
            selectable
            className="selectTeam">
            <a onClick={() => this.setPickData(team, "confirm")}>SELECT</a>
          </Table.Cell>
        )
      }
    } else {
      return (
        <Table.Cell
          className="regToPick">
          {auth ? 'REGISTER' : 'LOGIN'} TO PICK
        </Table.Cell>
      )
    }
  }

  renderRows () {
    const { teamSchedules, activePool, teamDetails } = this.props;
    return (
      Object.keys(teamSchedules).map((team, idx) => {
        return (
          <Table.Row key={team}>
            <Table.Cell className={`schedGridTeam ${activePool.game}_cell`}>
              <span>{team}</span>
              <Image src={logos[`${activePool.game}_${team}`]} size='mini'/>
            </Table.Cell>
            {this.renderPickBox(team)}
            <RenderWeekSchedule
              key={`${team}_${idx}`}
              activeGame={activePool.game}
              schedule={teamSchedules[team]}
              team={team}
              week={this.state.schedWeek}
              teamDetails={teamDetails}
            />
          </Table.Row>
        )
      })
    )
  }

  renderTable() {
    const { visible, schedWeek, userAction, userActionDesc, activeTeam, userSeason } = this.state;
    const { activePool, deadlines } = this.props;
    return (
      <Table celled compact className="scheduleTable" unstackable={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Your Pick: {
                userSeason && schedWeek ?
                  (userSeason.selections[0][schedWeek - 1].length > 1 ?
                  <Image src={logos[userSeason.selections[0][schedWeek - 1]]} className="user-pick-img"/> : <Icon className="no-pick-icon" name="question" />)
                :
                null
              }
            </Table.HeaderCell>
            <Table.HeaderCell className='click-select'>
              {moment().isBefore(moment(deadlines[schedWeek-1])) ? <span>Click to Select! <Icon name='arrow down' /></span> :
                 <span style={{color: '#FFCF8E'}}>
                  Picks Locked <Icon name="lock"/>
                </span>
              }
            </Table.HeaderCell>
            {this.renderHeaders()}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.renderRows()}
        </Table.Body>
        <Transition visible={visible} animation="fade up" duration={1000}>
          <Modal size="small" centered open={visible} onClose={this.close} className="confirm-pick-modal">
          <Header as='h2'>
            {activeTeam ?
              (`${userAction === 'confirm' ? 'Confirm Pick' : 'Cancel Pick'} of ${this.fullTeamName(activeTeam)} for Week ${schedWeek}`)
              : null}
            <img src={logos[`${activePool.game}_${activeTeam}`]} alt='logo' className="modal-logo-img"></img>
          </Header>
            <Modal.Content>
              {userActionDesc} Please confirm below.
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={this.close}>
                Back
              </Button>
              <Button primary onClick={() => this.makeUserPick(userAction)}>
                <Icon name='check circle' />
                  {userAction === 'confirm' ? 'MAKE PICK' : 'CANCEL PICK'}
              </Button>
            </Modal.Actions>
          </Modal>
        </Transition>
      </Table>
    )
  }

  render () {
    const { deadlines, teamDetails, scheduleWeek, teamSchedules, liveWeek, activePool, activeGameWeek, scheduleWeeks } = this.props;
    const { render, weeks, schedWeek } = this.state;
    if (render && deadlines !== null && weeks.length > 0 && teamDetails !== null && teamSchedules !== null && activeGameWeek !== null && scheduleWeeks.length > 0 && schedWeek !== null) {
      return (
        <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game}`}>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  <Icon name='clipboard list' circular inverted size='large'/>
                </div>
                <Header.Content>
                  Make Your Picks Here.
                  <Header.Subheader>
                    - Browse the {activePool.game.toUpperCase()} schedule and make your selections here. <br />
                    - You can submit picks for whichever weeks of the season you would like at anytime. <br />
                    {activePool.game  === 'nba' && activePool.season === 2020 ? <span className={`${activePool.game}-color`}>- The 2020 NBA season will be 21 periods long and conclude with the week of May 16th.</span> : null }
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
          {moment().isBefore(moment(deadlines[deadlines.length-1])) ?
            <div className="deadline">
              <Icon name="warning sign"/>
              WEEK {liveWeek + 1} PICK DEADLINE: {moment(deadlines[liveWeek])
                .local()
                .format("M/D/YY, h:mm a")}
              <Icon name="warning sign"/>
            </div>
          : null}
          <div className="make-picks-top-row">
            <span className="week-header">View Week: </span>
            <Pagination
              className="picks-pagination"
              siblingRange={5}
              totalPages={weeks.length}
              onPageChange={this.changeWeek}
              activePage={schedWeek}
            />
          </div>
          {this.renderTable()}
        </div>
      )
    } else {
      return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
    }
  }
}

function mapStateToProps ({
  teamSchedules, scheduleWeeks, activeGameWeek, auth, userSelections, liveWeek, deadlines, activePool, teamDetails, seasonParams, league
}) {
  return { teamSchedules, scheduleWeeks, activeGameWeek, auth, userSelections, liveWeek, deadlines, activePool, teamDetails, seasonParams, league };
}

export default connect(mapStateToProps, { makeUserPick, fetchActiveGameWeek })(MakePicks);
