import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Header, Icon, Form, Grid, Button, Checkbox, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { createLeague } from '../../actions';
import AuthModal from '../AuthModal';
import '.././styles/leagues.scss';

class CreateLeague extends Component {
  state = {
    privateLg: true
  }

  renderError( meta, type ) {
    if (type!=='password' && meta.touched && meta.error) {
      return (
        <div className="create-lg-error"> {meta.error} </div>
      )
    }
  }

  renderInput = ({ input, label, type, meta, exp }) => {
    return (
      <div className="field form-field">
        <label> {label} </label>
        <input {...input} type={type} autoComplete="off" />
        {this.renderError(meta)}
        {exp ? <div className="form-exp">{exp}</div> : null}
      </div>
    )
  }

  renderPublicLg = ({ input, label, type, meta, exp }) => {
    return (
      <div className="field form-field">
        <label> {label} </label>
        <Checkbox {...input} type='checkbox' label='Allow anyone to join my league.' onChange={() => this.setState({privateLg: !this.state.privateLg})}/>
        {this.renderError(meta)}
        {exp ? <div className="form-exp">{exp}</div> : null}
      </div>
    )
  }

  renderPublicVis = ({ input, label, type, meta, exp }) => {
    return (
      <div className="field form-field">
        <label> {label} </label>
        <Checkbox {...input} type='checkbox' label='Please hide my league from public view.'/>
        {this.renderError(meta)}
        {exp ? <div className="form-exp">{exp}</div> : null}
      </div>
    )
  }

  onSubmit = formValues => {
    this.props.createLeague({ username: this.props.auth[0].username, userId: this.props.auth[0].id, ...formValues})
  }

  render () {
    if (this.props.auth !== null) {
      return (
        <div>
          <Header as='h2' icon textAlign='center'>
            <Icon name='users' circular/>
            <div className="reg-header">Create an ALA Survivor League</div>
            <Header.Subheader>
              <div style={{width: '70%', marginLeft: '15%'}}>
                Creating an ALA Survivor League is a quick, one-time process and is the first step toward creating an ALA Survivor Pool.
              </div>
              <br />
              <div style={{width: '60%', marginLeft: '20%'}}>
                Leagues are the umbrella under which pools are organized, and track a group of entrants' picks and performance across all ALA Survivor games over time.
              </div>
              <br />
              <div style={{width: '60%', marginLeft: '20%'}}>
                <span style={{color: '#c73230'}}><b>Note: You do not need to create a new league every time you want to create a new pool!</b></span> Instead, navigate to the homepage of the league you have already created, and create your pool that way. Your league's members will then be emailed to let them know that there is a new pool that is open for them to join!
              </div>
            </Header.Subheader>
          </Header>
          {this.props.auth ? (
            <Form error onSubmit={this.props.handleSubmit(this.onSubmit)} className="create-league-form">
              <div className="reg-error-header">{this.props.authErrors.leagueError ? this.props.authErrors.leagueError.message : null}</div> 
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={5}>
                  <Field name="name" component={this.renderInput} label="League Name*" type="text"/>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={5}>
                  <Field name="abb" component={this.renderInput} label="Abbreviation (this will be your league's URL)*" type="text"/>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Field name="desc" component={this.renderInput} label="Description (optional)" type="text" exp="This will be displayed on your league's homepage. If left blank, a default message will be used. You can edit this later."/>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={5}>
                  <Field name="logo" component={this.renderInput} label="League Logo URL (optional)" exp="Include the web URL of an image to use as your league's logo, which will be prominently displayed on your league's homepage. If left blank, a default logo will be used. You can edit this later."/>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={5}>
                  <Field name="joinable" component={this.renderPublicLg} label="Open League for Public Entry?" exp=
                  "By default, your league is private, and you will need to send invitations or approve entrants' requests in order for them to join. If you would like your league to be public, check this box. Public leagues' pools are also public by default, and anyone who joins them will automatically join your league."/>
                </Grid.Column>
                {this.state.privateLg ?
                  <Grid.Column mobile={16} tablet={8} computer={6}>
                    <Field name="hidden" component={this.renderPublicVis} label="Hide League from Public?" exp="Check this box if you want your league to be hidden from public visibility and excluded from global ALA leaderboards."/>
                  </Grid.Column>
                  : null}
                <Grid.Column textAlign='center' className="confirm-row" mobile={16} computer={16}>
                  <Button size='massive' primary> Create Your League </Button>
                  <div className='button-subtext'>YOU CAN CREATE YOUR FIRST POOL IN THE NEXT STEP.</div>
                </Grid.Column>
              </Grid>
            </Form>
          ) :
            <div className='login-div'>
              You are not logged in! <AuthModal source='leagues'/> to create a league.
            </div>
          }
        </div>
      )
    } else {
      return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS ... </Loader>;
    }
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "You must include your league's name";
  }

  if (formValues.name && formValues.name.length > 40) {
    errors.name = "Your league's name cannot be longer than 40 characters"
  }

  if (!formValues.abb) {
    errors.abb = "You must include your league's abbreviation";
  }

  if (/\s/g.test(formValues.abb)) {
    errors.abb = "Your league's abbreviation can only include alphanumeric characters"
  }

  if (formValues.abb && formValues.abb.length > 20) {
    errors.abb = "Your league's abbreviation cannot be longer than 20 characters"
  }

  if (formValues.desc && formValues.desc.length > 255) {
    errors.desc = "Your league's description cannot be longer than 255 characters"
  }

  let url=/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  if (formValues.logo && !url.test(formValues.logo)) {
    errors.logo = "The URL you have provided is invalid. Please try again."
  }

  return errors;
}

const formWrapped = reduxForm({
  form: 'createLeague',
  validate
})(CreateLeague);

function mapStateToProps ({ auth, authErrors }) {
  return { auth, authErrors }
}

export default connect(mapStateToProps, { createLeague })(formWrapped);
