import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { addPayment } from '../../actions';
import { Grid, Header, Form, Button, Message, Table } from 'semantic-ui-react';
import formatDollars from '../../modules/helperFuncs/formatDollars';
import { getDiffieHellman } from 'crypto';

const payTypes = [
  {key: 'payment', text: 'payment from', value: 'payment'},
  {key: 'prize', text: 'prize paid to', value: 'prize'}
]

function Finances ({ activePool, gameEntrants, addPayment, leagueTransactions }) {
  const [payUser, setPayUser] = useState(null);
  const [payAmount, setPayAmount] = useState(0.00);
  const [payType, setPayType] = useState(null);
  const [payNotes, setPayNotes] = useState('');
  const [formError, setFormError] = useState(false);
  const [alphEntrants, setAlphEntrants] = useState([...gameEntrants].sort((a, b) => a.username.localeCompare(b.username, undefined, {sensitivity: 'base'})))

  function addUserPayment() {
    if (!payUser) {
      setFormError("You didn't include a username.");
      setTimeout(() => setFormError(false), 3000);
    } else if (!payAmount) {
      setFormError("You didn't include an amount.");
      setTimeout(() => setFormError(false), 3000);
    } else if (!payType) {
      setFormError("You didn't include a transaction type.");
      setTimeout(() => setFormError(false), 3000);
    } else {
      addPayment({
        activePool,
        username: payUser,
        notes: payNotes,
        amount: payAmount,
        type: payType,
      })
    }
  }

  const showUserPayments = user => {
    return leagueTransactions
      .filter(t => t.username === user && t.game === activePool.game && t.season === activePool.season)
      .map(t => {
        return <Table.Row>
          <Table.Cell>{moment(t.updated_at).format("M/D/YY")}</Table.Cell>
          <Table.Cell>{formatDollars(t.amount)}</Table.Cell>
          <Table.Cell>{t.notes}</Table.Cell>
        </Table.Row>
      }) 
  }

  if (leagueTransactions !== null) {
    return (
      <Grid className='commish-tools-cont'>
        <Grid.Row column={2}>
          <Grid.Column>
            <div className="user-pg-header">Finances</div>
            <Header size='medium'>Use this page to record payments from, or prizes paid to, your game entrants. <br />
            </Header>
            <Form error={formError} onSubmit={() => addUserPayment()}>
              <Message
                error
                content={formError}
              />
              <Grid relaxed>
                <Grid.Column width={6}>
                  <Grid.Row style={{paddingBottom: 10}}>
                    <Form.Select search label='user' options={alphEntrants} placeholder='user' value={payUser} onChange={(e, {value}) => setPayUser(value)}/>
                  </Grid.Row>
                  <Grid.Row style={{paddingBottom: 10}}>
                    <Form.Select label='transaction type' options={payTypes} placeholder='payment from/prize paid to' value={payType} onChange={(e, {value}) => setPayType(value)}/>
                  </Grid.Row>
                  <Grid.Row>
                    <Button primary> Submit </Button>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid.Row style={{paddingBottom: 10}}>
                    <Form.Input label='amount' placeholder='0.00' type='number' step='0.01' onChange={e => setPayAmount(e.target.value)}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Input label='notes (optional, visible to user)' placeholder='payment notes/memo' onChange={e => setPayNotes(e.target.value)}
                    />
                  </Grid.Row>              
                </Grid.Column>
                <Grid.Column width={6}>
                  {payUser ?
                        <>
                        <div><b>{payUser}</b>'s {`${activePool.game} ${activePool.season} payments`}</div> 
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Date Entered</Table.HeaderCell>
                              <Table.HeaderCell>Amount</Table.HeaderCell>
                              <Table.HeaderCell>Memo</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {showUserPayments(payUser)}
                          </Table.Body>
                        </Table>
                        </>
                      : null}
                </Grid.Column>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  } else {
    return <div> Loading ... </div>
  } 
}

function mapStateToProps ({ leagueTransactions }) {
  return ({ leagueTransactions })
}

export default connect(mapStateToProps, {addPayment})(Finances);
