import React  from 'react';
import { connect } from 'react-redux';
import AuthModal from '../AuthModal';
import { Button, Table, Header, Image, Icon } from 'semantic-ui-react';
import { acceptInvite } from '../../actions';
import '../styles/leagues.scss';

function LeagueInvite ({ members, auth, league, code, acceptInvite }) {
  function renderLeagueMembers () {
    return league.members.map(member => {
      return (
        <Table.Row key={member.id}>
          <Table.Cell>
            <Header as='h2' image>
              {member.photo_url ?
                <Image src={member.photo_url} rounded size='mini' />
              : <Icon rounded size='mini' name='user circle' />}
              <Header.Content>
                {member.username}
                <Header.Subheader>{member.tagline || null}</Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          {/* <Table.Cell> {member.real_name} </Table.Cell>
          <Table.Cell> {member.email} </Table.Cell> */}
        </Table.Row>
      )
    })
  }

  function joinLeague () {
    acceptInvite({
      league_id: league.info.id,
      league_name: league.info.name,
      league_abb: league.info.abb,
      user_id: auth[0].id
    })
  }

  return (
    <>
    <div className="invite-box">
      <div>You have been invited to join <span className="purple-text">{league.info.name}!</span></div>
      {!auth[0] ?
        <div> Please <AuthModal source={'invite'} code={code} /> to join this league. </div>
        :
        <div> <Button primary size='huge' onClick={joinLeague}> CLICK HERE </Button> to join the league. </div>
      }
      <div> <i>League Commissioner</i>: <b className="purple-text ">{league.info.founder}</b></div>
    </div>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>League Members</Table.HeaderCell>
            {/* <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderLeagueMembers()}
        </Table.Body>
      </Table>
    </>

  )
}

function mapStateToProps ({ auth, league }) {
  return ({ auth, league })
}

export default connect (mapStateToProps, {acceptInvite}) (LeagueInvite);
