import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, NavLink, Switch } from 'react-router-dom';
import { Tab} from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react';
import { fetchAllPools } from '../actions';
import ALAHeader from './Header';
import lillard from '../images/lillard_game_winner.jpg';
import CreateLeague from './leagues/CreateLeague';
import JoinLeague from './leagues/JoinLeague';
import AllPools from './AllPools';
import LeagueBanner from './leagues/LeagueBanner';

const lgTheme = {
  "--color-main": "#6638f0"
};

const panes = [
  {
    menuItem: {
      as: NavLink,
      id: "tab1",
      content: "ALL POOLS",
      to: `/leagues/allpools`,
      exact: true,
      key: "allpools"
    },
    pane: (
      <Route
        key="league:allpools"
        exact
        path="/leagues/allpools"
        render={() => (
          <Tab.Pane><AllPools /></Tab.Pane>
        )}
      />
    )
  },
  {
    menuItem: {
      as: NavLink,
      id: "tab2",
      content: "FIND A LEAGUE",
      to: `/leagues/find`,
      exact: true,
      key: "find"
    },
    pane: (
      <Route
        path="/leagues/find"
        key="leagues:find"
        render={() => (
          <Tab.Pane><JoinLeague /></Tab.Pane>
        )}
      />
    )
  },
  {
    menuItem: {
      as: NavLink,
      id: "tab1",
      content: "CREATE A LEAGUE",
      to: `/leagues/create`,
      exact: true,
      key: "create"
    },
    pane: (
      <Route
        key="league:create"
        exact
        path="/leagues/create"
        render={() => (
          <Tab.Pane><CreateLeague /></Tab.Pane>
        )}
      />
    )
  }
]

function Leagues ({ allLeagues, auth, allPools, fetchAllPools }) {
  useEffect(() => {
    fetchAllPools();
    Object.keys(lgTheme).map(key => {
      const value = lgTheme[key];
      document.documentElement.style.setProperty(key, value);
    });
  }, [])

  if ( allLeagues !== null && auth !== null && allPools !== null) {
    return (
      <div style={{minHeight: 1400}}>
        <ALAHeader />
        <div className="master-container">
          <img src={lillard} className="home-bg" alt='dame'></img>
          <LeagueBanner name='POOLS & LEAGUES'/>
          <Switch>
            <Tab renderActiveOnly={false} activeIndex={-1} panes={panes} className="league-menu" />
          </Switch>
        </div>
      </div>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS ... </Loader>;
  }

}

function mapStateToProps ({ auth, allLeagues, allPools }) {
  return { auth, allLeagues, allPools }
}

export default connect (mapStateToProps, {fetchAllPools}) (Leagues)
