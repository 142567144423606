import { REG_ERROR } from '../actions/types';
import { LOGIN_ERROR } from '../actions/types';
import { LEAGUE_CREATE_ERROR } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case REG_ERROR:
      return action.payload;
    case LOGIN_ERROR:
      return action.payload;
    case LEAGUE_CREATE_ERROR:
      return {...state, leagueError: action.payload};
    default:
      return state;
  }
}
