import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Select, Button, Table, Grid, Input, Modal, Transition, Header, Icon } from 'semantic-ui-react';
import RenderLives from './../table_helpers/RenderLives';
import { processPrizeSplit } from './../../actions/index';

function SplitPrizes ({ activePool, gameEntrants, prizes, processPrizeSplit }) {
  const [prizeWinners, setPrizeWinners] = useState([]);
  const [activeEntrant, setActiveEntrant] = useState(null);
  const [rows, setRows] = useState([]);
  const [pctDist, setPctDist] = useState(0);
  const [totalSplitValue, setTotalSplitValue] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [splitShares, setSplitShares] = useState({});
  const [prizeObj, setPrizeObj] = useState({});
  const [confirmSplitVis, setConfirmSplitVis] = useState(false);

  const formatDollars = d => d >= 0 ? `$${(d).toFixed(2)}` : `-$${(Math.abs(d)).toFixed(2)}`;
  const sumObjValues = obj => {
    if (Object.keys(obj).length > 0) {
      return Object.values(obj).reduce((a, b) => a + b);
    } else {
      return 0;
    }
  }

  useEffect(() => {
    setTotalSplitValue((prizes.prize_pool - prizes.allocated) * (pctDist/100));
  }, [pctDist])

  useEffect(() => {
    let prizes = {};
    let totalShares = 0;
    if (prizeWinners.length) {
      totalShares = sumObjValues(splitShares);
      setTotalShares(totalShares);
      if (totalShares > 0) {
        prizeWinners.forEach(entrant => {
          prizes[entrant.username] = (splitShares[entrant.username]/totalShares) * totalSplitValue;
        })
        setPrizeObj({ ...prizes});
      } else {
        setPrizeObj({});
      }
    } else {
      setTotalShares(0);
    }
  }, [prizeWinners, totalSplitValue, splitShares])

  useEffect(() => {
    populateTable();
  }, [prizeObj, prizeWinners])

  const mappedEntrants = [...gameEntrants]
    .filter(entrant => !prizeObj.hasOwnProperty(entrant.username))
    .sort((a, b) => a.username.localeCompare(b.username))
    .map((entrant, idx) => {
      return {
        key: idx,
        value: entrant.username,
        text: entrant.username
      }
  })

  function addEntrant(u) {
    let prevWinners = prizeWinners;
    let entrant = gameEntrants.filter(entrant => entrant.username === u);
    prevWinners.push(entrant[0]);
    modifySplitShares(entrant[0].username, 1);
    setPrizeWinners([...prevWinners]);
  }

  function addRemainingEntrants() {
    const entrants = gameEntrants.filter(entrant => entrant.lives > 0);
    entrants.forEach(entrant => addEntrant(entrant.username));
  }

  function removeEntrant(u) {
    let prevWinners = prizeWinners;
    let newWinners = prevWinners.filter(entrant => entrant.username !== u);
    setPrizeWinners(newWinners);
    let prevSplitShares = { ...splitShares};
    delete prevSplitShares[u];
    setSplitShares({ ...prevSplitShares});
  }

  function modifyPctDist (pct) {
    if (pct < 0) {
      setPctDist(0);
    } else if (pct > 100) {
      setPctDist(100);
    } else {
      setPctDist(pct);
    }
  }

  function modifySplitShares (u, v) {
    let prevSplits = splitShares;
    prevSplits[u] = parseFloat(v) || 0;
    setSplitShares({ ...prevSplits});
  }

  function confirmPrizeSplit() {
    processPrizeSplit({
      prizeObj,
      activePool,
      totalSplitValue,
      totalPrizePool: prizes.prize_pool
    });
  }

  function populateTable() {
    setRows(prizeWinners.map((entrant, idx) => {
      return (
        <Table.Row key={idx}>
          <Table.Cell> {entrant.username} <Icon name='cancel' color="red" onClick={() => removeEntrant(entrant.username)}/> </Table.Cell>
          <Table.Cell> <RenderLives lives={entrant.lives} /> </Table.Cell>
          <Table.Cell> {formatDollars(entrant.prizes)} </Table.Cell>
          <Table.Cell> <Input defaultValue={1} onChange={e => modifySplitShares(entrant.username, e.target.value)}/>
            <span><i> {((splitShares[entrant.username]/totalShares)*100).toFixed(2)}% </i></span>
          </Table.Cell>
          <Table.Cell> {formatDollars(prizeObj[entrant.username])}</Table.Cell>
        </Table.Row>
      )
    }))
  }

  function closeModal () {
    setConfirmSplitVis(false);
  }

  function renderSplitConf () {
    return prizeWinners.map((entrant, idx) => {
      return (
        <div key={idx}>
          <b>{entrant.username}</b> will receive <b>{formatDollars(prizeObj[entrant.username])}</b> ({((splitShares[entrant.username]/totalShares)*100).toFixed(2)}% of {formatDollars(totalSplitValue)})
        </div>
      )
    })
  }

  return (
    <Grid className='commish-tools-cont'>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div className="user-pg-header">Process Prize Split</div>
          <div> Use this tool to process an agreed-upon prize split between your pool's entrants.</div>
          <div> To most effectively use this tool: </div>
          <ol type="1">
            <li> Select the percentage of the remaining prize pool that is being split. </li>
            <li> Use the dropdown to add each entrant that is receiving a portion of the prize split, or use the <b>Add Remaining Entrants</b> button, which will add all pool players who have not yet been eliminated from the pool. </li>
            <li> If the prize split is not being evenly distributed, modify an entrant's Split Share (for example: if one entrant is receiving twice as much as the other entrants, change their Split Share value to "2"). Each entrant's split share is set to "1" by default under the assumption of an even split.</li>
          </ol>
          <Button style={{marginLeft: 5}} onClick={() => addRemainingEntrants()}> Add Remaining Entrants </Button> <b> - OR - </b> <Select placeholder='Select an entrant' options={mappedEntrants} onChange={(e, {value}) => setActiveEntrant(value)}/> <Button onClick={e => addEntrant(activeEntrant)} disabled={!activeEntrant}> Add Entrant </Button>
        </Grid.Column>
        <Grid.Column>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell> TOTAL PRIZE POOL: </Table.Cell>
                <Table.Cell> {formatDollars(prizes.prize_pool)} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell> PREVIOUSLY ALLOCATED: </Table.Cell>
                <Table.Cell> {formatDollars(prizes.allocated)} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell> REMAINING PRIZES: </Table.Cell>
                <Table.Cell> {formatDollars(prizes.prize_pool - prizes.allocated)} </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell> <b>PERCENTAGE TO AWARD:</b> </Table.Cell>
                <Table.Cell>
                  <Input value={pctDist} label='%' labelPosition='right' type='number' min="0" max="100" step=".01" onChange={e => modifyPctDist(e.target.value)}/>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell> VALUE OF PRIZE SPLIT: </Table.Cell>
                <Table.Cell> {formatDollars(totalSplitValue)} </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      {(prizes.prize_pool - prizes.allocated) <= 0.01 ?
        <Grid.Row columns={1} centered>
          <Grid.Column textAlign='center' style={{fontSize: 18, color: 'red'}}>
            You have no remaining prizes available in your prize pool to allocate to entrants.
          </Grid.Column>
        </Grid.Row>
      : null}
      <Grid.Row columns={1}>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell> Entrant </Table.HeaderCell>
                <Table.HeaderCell> Lives </Table.HeaderCell>
                <Table.HeaderCell> Prizes to Date </Table.HeaderCell>
                <Table.HeaderCell> Split Share (<i>total shares: {totalShares}</i>)</Table.HeaderCell>
                <Table.HeaderCell> Prize to Be Awarded </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column textAlign='center'>
          {(prizeWinners.length > 0 && pctDist > 0) ?
            (prizes.prize_pool - prizes.allocated) <= 0.01 ?
            <Button size="massive" disabled> PROCESS SPLIT </Button>
            :
            <Button size="massive" onClick={() => setConfirmSplitVis(true)}> PROCESS SPLIT </Button>
          : null}
          <Transition visible={confirmSplitVis} animation="fade up" duration={1000}>
            <Modal size="small" centered open={confirmSplitVis} className="confirm-pick-modal">
            <Header as='h2'>
              Process Prize Split?
            </Header>
              <Modal.Content>
                <p>
                  Proceeding will process a prize split with the following parameters. Processing this prize split will update your league's finances with the noted prize allotments, and will post an update to your league's news feed sumarrizing the split.
                </p>
                <p>
                  SUMMARY: You are allocating <b>{formatDollars(totalSplitValue)}</b> in prizes, which is <b>{pctDist}%</b> of the <b>{formatDollars(prizes.prize_pool - prizes.allocated)}</b> in remaining prizes from your <b>{formatDollars(prizes.prize_pool)}</b> total prize pool, as follows:
                </p>
                <p>
                  {renderSplitConf()}
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => closeModal()}>
                  GO BACK
                </Button>
                <Button primary onClick={() => confirmPrizeSplit()}>
                  <Icon name='check circle' />
                    PROCESS SPLIT
                </Button>
              </Modal.Actions>
            </Modal>
          </Transition>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default connect (null, {processPrizeSplit}) (SplitPrizes);
