import { GAME_BONUS_TOTALS } from '../actions/types';

export default function gameBonusTotals (state = null, action) {
  switch (action.type) {
    case GAME_BONUS_TOTALS:
      return action.payload;
    default:
      return state;
  }
}
