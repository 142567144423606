import { SET_LEAGUE, CLEAR_LEAGUE } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case SET_LEAGUE:
      return action.payload;
    case CLEAR_LEAGUE:
      return null;
    default:
      return state;
  }
}
