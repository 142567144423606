import React from 'react';
import { Grid, Image, Header, Icon, Divider } from 'semantic-ui-react';
import { SRLWrapper } from "simple-react-lightbox";
import { Icon as Iconify } from '@iconify/react';
import scoreboardIcon from '@iconify-icons/mdi/scoreboard'  ;

function Features () {
  return (
    <SRLWrapper>
      <Grid id="get-started-grid">
        <Grid.Row centered>
          <Header as='h2' icon>
            Survivor Features You Didn't Know You Needed.
            <Header.Subheader>
              A Level Above Survivor Pools are loaded with functionality and game components that go above and beyond those offered by other pool providers.
            </Header.Subheader>
          </Header>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -25}}>
          <Grid.Column width={12}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -40}}>
          <Grid.Column width={8}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -40}}>
          <Grid.Column width={4}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/leagues.jpg' fluid alt="Keep tabs on your opponents and sign up for pools via your league's homepage."/>
          <Header as='h2'>
            <div>
              <Icon name='line chart' />
              <Header.Content> Leagues & Rankings </Header.Content>
            </div>
            <Header.Subheader> ALA Survivor Leagues are the umbrella under which pools are organized, and track a group of entrants' performance over time across that league's pools. </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/scoreboard.jpg' fluid alt='The Scoreboard updates game results, box score leaders, and bonus achievements in real-time.' />
          <Header as='h2'>
            <Iconify className="iconify-icon" icon={scoreboardIcon}/>
            <Header.Content> Live Scoring </Header.Content>
            <Header.Subheader> Unnecessary and gratuitous? Probably -- but this is A Level Above. Scoreboards light up while games are in progress to reflect real-time results. Hovering over a game will display live game data, box score leaders, and bonus achievements. Integration with official NBA and MLB APIs gets you results even faster than you'll see on TV. </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/bonuses.jpg' fluid alt='Bonus Pools are an optional game component, available for pools and entrants that want a little more on the line.'/>
          <Header as='h2'>
            <div>
              <Icon name='rocket' />
              <Header.Content> Performance Bonus Pools </Header.Content>
            </div>
            <Header.Subheader> The Bonus Pool is a one-of-a-kind Survivor game component that rewards entrants for outstanding statistical achievements from their weekly selections. It's optional for Commissioners to include in pools, and participation is optional for that pool's entrants if included.</Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/custompool.jpg' fluid alt="Set up your Survivor Pool however your league would like to play."/>
          <Header as='h2'>
            <div>
              <Icon name='cogs' />
              <Header.Content> Customizable Pools </Header.Content>
            </div>
            <Header.Subheader> ALA's Survivor configurations give you a wide but simple swath of options to use in setting up your pool. How many lives an entrant is allowed, the cost of a re-buy, whether pick protection insurance is offered, or whether you will incorporate a Bonus Pool is up to you. </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/schedule.jpg' fluid alt="Strategize and plan for the season by submitting picks for any week at any time."/>
          <Header as='h2'>
            <div>
              <Icon name='calendar alternate outline' />
              <Header.Content> Team Schedules: Simplified </Header.Content>
            </div>
            <Header.Subheader> NBA and MLB schedules are more complex and lengthy than those of the NFL — so we make the hard work easy on you with a speedy tool that lets you quickly compare teams’ weekly schedules over the course of a season.</Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/finances.jpg' alt="A pool's Finances page provides a detailed breakdown of where everyone in the league stands." fluid />
          <Header as='h2'>
            <div>
              <Icon name='dollar sign' />
              <Header.Content> Finance Tracking </Header.Content>
            </div>
            <Header.Subheader> Buy-ins, re-buys, insurance proceeds, bonus pool prizes and payouts — all are accounted for in a pool’s Finances tab. Pool Commissioners can quickly log transactions through the Commish Tools, and can kiss those ugly old Excel spreadsheets goodbye.</Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/newsfeed.jpg' fluid alt="A pool's news feed details all the latest and greatest events in your pool." />
          <Header as='h2' textAlign='center'>
            <div>
              <Icon name='newspaper' />
              <Header.Content> News Feeds </Header.Content>
            </div>
            <Header.Subheader> Keep up to date on eliminations, bonus pool achievements, re-buys and other pool activity. Gaze longingly at the many pretty icons. </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/yourselections.jpg' fluid alt="Plan out your season and submit picks for any week at any time."/>
          <Header as='h2'>
            <div>
              <Icon name='clipboard' />
              <Header.Content> Plan Ahead </Header.Content>
            </div>
            <Header.Subheader> Ever wonder why the major Survivor Pool providers prevent you from making your picks more than a week ahead of time? We have, too. Here, you can submit a pick for any week of a season at any time.</Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign='center'>
          <Image src='https://alasurvivor.s3-us-west-2.amazonaws.com/insurance.jpg' fluid alt="Pool Commissioners can allow their entrants to purchase insurance policies and protect them from missed picks."/>
          <Header as='h2'>
            <div>
              <Icon name='shield alternate' />
              <Header.Content> Insurance Policies </Header.Content>
            </div>
            <Header.Subheader> No one loves being eliminated from a pool because they forgot to make a pick. Commissioners can configure their pools to allow entrants to purchase insurance policies, which will automatically award them the pool's most popular selection that week if life gets in the way. Insurance proceeds are included in a pool's prize payouts. </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </SRLWrapper>
  )
}

export default Features;
