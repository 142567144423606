import { FETCH_USER, UPDATE_USER_GAME_DETAILS, UPDATE_USER_LEAGUE_SETTINGS, USER_LEAGUE_SETTINGS_CHANGE_SUCCESS } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case UPDATE_USER_GAME_DETAILS:
      let newState = state;
      newState[0].gameDetails = action.payload;
      return newState;
    // case UPDATE_USER_LEAGUE_SETTINGS:
    //   let cleanState = { ...state};
    //   cleanState[0].leagues = [
    //     ...cleanState[0].leagues.filter(l => l.league_id !== action.payload.league_id),
    //     action.payload
    //   ]
    //   return cleanState;
    case USER_LEAGUE_SETTINGS_CHANGE_SUCCESS:
      return { ...state, leagueSettingsUpdated: action.payload}
    default:
      return state;
  }
}
