import { FETCH_POOL_SELECTIONS } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_POOL_SELECTIONS:
      return action.payload;
    default:
      return state;
  }
}
