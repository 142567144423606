import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Grid, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { finalizePasswordReset, fetchUserByResetToken } from '../actions';
import lillard from '../images/lillard_game_winner.jpg';
import './styles/register.scss';
import Header from './Header';

class ResetPassword extends Component {
  state = {
    token: this.props.match.params.token
  }

  async componentDidMount () {
    this.props.fetchUserByResetToken(this.props.match.params.token);
  }

  renderError( meta, type ) {
    if (type!=='password' && meta.touched && meta.error) {
      return (
        <div className="form-error"> {meta.error} </div>
      )
    }
  }

  renderInput = ({ input, label, type, meta }) => {
    return (
      <div className="field form-field">
        <label> {label} </label>
        <input {...input} type={type} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    )
  }

  onSubmit = formValues => {
    this.props.finalizePasswordReset({ ... formValues, token: this.state.token})
  }

  render () {
    if (this.props.allLeagues) {
      switch (this.props.auth) {
        case null:
          return (
            <div>
              <Header />
              <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
            </div>
          )
        case false:
          return (
            <div>
              <Header />
              <img src={lillard} alt='dame' className="home-bg"></img>
              <Form error onSubmit={this.props.handleSubmit(this.onSubmit)} className="reset-pw-form">
                <div className="reg-header">Reset Your Password</div>
                {this.props.pwReset.userSuccess && !this.props.pwReset.resetConfirm && (<>
                  <div className='reset-pw-summary'> 
                    Username: <b>{this.props.pwReset.user.username}</b> <br/>
                    Email: <b>{this.props.pwReset.user.email}</b>
                  </div>
                  <Grid>
                    <Grid.Column>
                      <Field name="password" component={this.renderInput} label="Password" type="password"/>
                      <Field name="confirmPassword" component={this.renderInput} label="Confirm Password" type="password"/>
                      <div className="forgot-pw-container">
                        <Button primary disabled={this.props.invalid && this.props.dirty}> Reset Password </Button>
                      </div>
                    </Grid.Column>
                  </Grid>
                </>)}
                {!this.props.pwReset.userSuccess && !this.props.pwReset.resetConfirm && (
                  <div className="logged-in-already" style={{marginTop: 100, lineHeight: 1}}>
                    Your password reset token has expired or was not found. <br/> <br/>
                    If you still need to reset your password, please return to the login screen to try again.
                  </div>
                )}    
              </Form>
              <div className="logged-in-already" style={{marginTop: 100, lineHeight: 1}}>
                {this.props.pwReset.resetConfirm ? 
                <>
                  {this.props.pwReset.message } <br/>
                  You will be redirected momentarily.
                </> : null}</div>
              <div className="reg-error-header">{!this.props.pwReset.resetConfirm ? this.props.pwReset.message : null}</div>
            </div>
          );
        default:
          return (
            <div>
              <Header />
              <div className="logged-in-already" style={{marginTop: 200}}>
                You are already logged in!
              </div>
            </div>
          )
      }
    } else {
      return (
        <div>
          <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
        </div>
      )
    }
  }
}

const validate = formValues => {
  const errors = {};

  if (formValues.password !== formValues.confirmPassword) {
    errors.confirmPassword = "Your passwords don't match";
  }

  return errors;
}

const formWrapped = reduxForm({
  form: 'register',
  validate
})(ResetPassword);

function mapStateToProps ({ auth, authErrors, allLeagues, pwReset }) {
  return { auth, authErrors, allLeagues, pwReset }
}

export default connect(mapStateToProps, { finalizePasswordReset, fetchUserByResetToken })(formWrapped);
