import React from 'react';
import { Grid, Icon, List, Popup, Header, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import NBABonusExample from '../howtoplay/nbaBonusExample';
import NBABonusTable from '../howtoplay/nbaBonusTable';
import BonusPoolFaq from '../howtoplay/bonusPoolFaq';

function HowToPlay () {
  document.documentElement.style.setProperty('--color-main', '#c73230');
  return (
    <div style={{width: '96%', marginLeft: '2%', marginTop: 40, marginBottom: 60}}>
      <Grid className='get-started-grid-2'>
        <Grid.Row centered>
          <Header as='h2' icon>
            Learn the Ropes.
            <Header.Subheader>
              ALA Survivor Pools come equipped with a default format and a slew of configurable options and game components.
            </Header.Subheader>
          </Header>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -25}}>
          <Grid.Column width={12}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -40}}>
          <Grid.Column width={8}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{marginTop: -40}}>
          <Grid.Column width={4}>
            <Divider style={{backgroundColor: '#c73230'}}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="how-to-play-div">
        <div className="full-theme-banner">
          THE BASICS
        </div>
        <div className="how-to-play-sec">
          <h1> Your primary objective is simple: <span className='game-color'>stay alive</span>. </h1>
          <ul>
            <li><span style={{fontSize: '1.2em'}}><b>Pick one team per week.</b></span></li>
            <li>Your team must not lose more games than they win <b>(e.g. they must go .500 or better)</b> for you to advance. If your team finishes with a sub-.500 record, <b>you lose a life</b>.</li>
            <li>Once you select a team, you cannot use them again for the remainder of the season.</li>
            <li>If you lose all of your lives, you are eliminated.</li>
          </ul>
        </div>
        <div className="full-theme-banner">
          CONFIGURABLE POOL SETTINGS
        </div>
        <div className="how-to-play-sec-2">
          <Grid>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='dollar sign' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>BUY-IN</h1>
                <div className='how-to-exp'> A pool's Commissioner can set the pool buy-in to any value, including $0. A pool's prizes are calculated based on the buy-in and re-buy values configured by the Commissioner.</div>
              </Grid.Column>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='rocket' circular /></h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>BONUS POOL:
                  <span className='how-to-val'> ACTIVE </span>
                  OR
                  <span className='error-color'> DISABLED </span>
                </h1>
                <div className='how-to-exp'> A pool's Commissioner can activate or deactivate the Bonus Pool component, which would be optional for any pool entrants to participate in. See additional details below. </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='user outline' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>LIVES</h1>
                <div className='how-to-exp'>A pool's Commissioner can configure the pool to give entrants as many lives as deemed appropriate for the length of the NBA/MLB seasons, which are typically 26 weeks long.</div>
              </Grid.Column>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='exchange' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>IN/OUT CADENCE</h1>
                <div className='how-to-exp'> If a pool is configured to incorporate the Bonus Pool, the Bonus Pool can be configured to be open for entry/exit on a weekly, monthly, or 'anytime' cadence. </div>
                <span className='how-to-bonus-label'><Icon name='rocket' /> BONUS POOL</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='life ring' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>RE-BUY COST</h1>
                <div className='how-to-exp'> If a Commissioner has set up a pool to allow entrants more than one life, additional lives can have a cost attached to them -- or they can be free. If a cost is associated with re-buys, re-buy proceeds will be included in a pool's calculated prize totals.</div>
              </Grid.Column>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='level down alternate' circular /></h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1 style={{position: 'relative'}}>SHUTDOWN</h1>
                <span className='how-to-bonus-label'><Icon name='rocket' /> BONUS POOL</span>
                <div className='how-to-exp'> If a pool is configured to incorporate the Bonus Pool, it can be set to automatically shut down when the Bonus Pool is reduced to a certain number of entrants. Bonus Pool penalties can become expensive as a Bonus Pool shrinks in size. A pool's Commissioner can always manually shut down or reactivate a Bonus Pool.
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className='how-to-icon-col' width={1}>
                <h1> <Icon name='shield alternate' circular /> </h1>
              </Grid.Column>
              <Grid.Column width={7}>
                <h1>INSURANCE:
                  <span className='how-to-val'> AVAILABLE </span>
                  or
                  <span className='error-color'> DISABLED </span>
                </h1>
                <div className='how-to-exp'> A pool Commissioner can configure a pool to allow entrants to purchase insurance policies, which protect an entrant in the event that they forget to make a pick by allocating them the most popular choice in a pool that week that remains available to them. Insurance can be purchased through a pool's homepage, and proceeds from insurance purchases are automatically included in a pool's prize distribution. </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
      <div className="how-to-play-div">
        <div className="full-theme-banner">
          <Icon name='rocket' style={{color: 'white'}} /> WHAT'S THIS ABOUT A BONUS POOL?
        </div>
        <div className="how-to-play-sec-2">
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <List size='large' bulleted id="how-to-bonus-list" >
                <List.Item style={{lineHeight: 1.4}}>
                  The Performance Bonus Pool awards prizes to participating entrants for outstanding team or player achievements from their weekly Survivor selection, which are collectively paid out by the other entrants who have opted-in to the Peformance Bonus Pool. If activated, participation in the Bonus Pool will be optional for your league's entrants.
                </List.Item>
                <List.Item style={{lineHeight: 1.4}}>
                  During Pool setup, a Commissioner can choose to use all or any of the available Performance Bonuses for a game, and can also configure the prize amounts for each bonus.
                </List.Item>
                <List.Item style={{lineHeight: 1.4}}>
                  <NBABonusExample />
                </List.Item>
                <List.Item style={{lineHeight: 1.4}}>
                  Bonus Pool achievements will show up both within the box scores on a pool's <Link to={`/nba/alevelabove/2019/reg/0/scoreboard`}>Scoreboard</Link> and in the News Feed on a pool's <Link to={`/nba/alevelabove/2019/reg/0/scoreboard`}>homepage</Link>. Bonus Pool prizes and payouts are automatically tracked and calculted by the Survivor software, and can be viewed in detail within a pool's <Link to={`/nba/alevelabove/2019/reg/0/bonuses`}>Bonuses</Link> page.
                </List.Item>
                <List.Item style={{lineHeight: 1.4}}>
                  For some more information, take a look at the <Popup content={<BonusPoolFaq />} wide='very' trigger={<b><u>Bonus Pool FAQ</u></b>} />. If you'd like to get a better sense of the monetary scale of participating in a Bonus Pool, go check out the Bonus Pool balances on the <a href='/nba/alevelabove/2019/reg/0/finances'>Finances</a> page from NBA Survivor's first season, or dig into the <a href='/nba/alevelabove/2019/reg/0/bonuses'>cornucopia of bonuses</a> that were awarded in the pool's inaugural year.
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={6} textAlign='center'>
              <Header size='large'>Available NBA Bonuses </Header>
              <Header.Subheader style={{marginTop: -10, fontSize: 14, color: 'black !important'}}><i>(prize values shown are default ALA values) </i></Header.Subheader>
                <NBABonusTable width={100} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </div>
      </div>
    </div>
  )
}

export default HowToPlay;
