import React from 'react';
import { Table, Image, Popup, Grid, Label, Icon } from 'semantic-ui-react';
import logos from '../../modules/logos';
import Diamond from './Diamond';
import tinycolor from 'tinycolor2';

function renderBonus (type, bonuses, color) {
  return bonuses.map(bonus => {
    return (
      <div key={`${bonus.id}_${bonus.bonusStats}`} >
        <div style={{color: color, display: 'flex', alignItems: 'center'}}>
          <Image
            className="player-bonus-img" style={{display:'inline'}} src={`https://securea.mlb.com/mlb/images/players/head_shot/${bonus.id}.jpg`} rounded
          />
          <div style={{display: 'inline-block', textAlign: 'center', width: '100%'}}>
            <b>{bonus.name.split(' ').slice(-1)} {type === 'slam' || type === 'beans' ? `(${bonus.bonusStats})` : null}</b>
          </div>

        </div>
      </div>
    )
  })
}

function renderScoringPlays (plays, hColor, vColor, hAbb, vAbb) {
  return plays.map((play, idx) => {
    const activeColor = play.about.isTopInning ? tinycolor(vColor).lighten(65).toString() : tinycolor(hColor).lighten(65).toString();
    return (
      <div className="scoring-play" style={{backgroundColor: activeColor}} key={idx + 1}>
        <span> <b>{play.about.halfInning.slice(0, 3).toUpperCase()} {play.about.inning}: </b></span>
        <span> {play.result.description} </span>
        <span> <span style={{color: vColor}}><b>{vAbb} {play.result.awayScore}</b></span>, <span style={{color: hColor}}><b>{hAbb} {play.result.homeScore}</b></span></span>
      </div>
    )
  })
}

export default function MLBBonusBox ({ idx, style, props, game, vColor, hColor }) {
  return (
    <Popup key={idx} flowing hoverable style={style} className={`bonus-box bonus-box-${props.activeGame}`}
      trigger = {
        <Table.Cell key={game.id} className={`schedGridTeam ${props.activeGame}_cell ${game.statusColor}`}>
          <b>{game.matchup}</b>
          <Image src={logos[`${props.activeGame}_${game.matchup.split(" ")[1]}`]}/>
          {game.status !== 'inactive' ?
            <div className="result-text">
              <a href={`https://www.mlb.com/gameday/${game.gid}`} target="_blank" rel="noopener noreferrer">
                {game.result}
              </a>
              <div className="sched-game-status">
                {game.status !== 'inactive' ?
                  <span> {game.status}
                    {(props.team === game.vAbb && game.stats.vBonus) || (props.team === game.hAbb && game.stats.hBonus) ?
                        <Label circular className="sched-bonus-label" color='orange'>
                          <Icon name='rocket' className='bonus-icon'/>
                        </Label>
                      : null}
                  </span>
                : <div></div>}
              </div>
            </div> : null}
        </Table.Cell>
      }
    >
      <Popup.Content>
      <Grid celled='internally'>
          <Grid.Column width={8} className="bonus-box-left">
            <Grid celled='internally'>
              <Grid.Row style={{backgroundColor: "#EFF2F4"}}>
                <Grid.Column width={16} className="bonus-box-head">
                  <span style={{color: vColor}}>BONUS</span> <span style={{color: hColor}}>BOX</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{backgroundColor: '#EFF2F4'}}>
                <Grid.Column width={5} textAlign='center' style={{backgroundColor: vColor, color: 'white'}} className="bonus-box-team">
                  <Image className='popup-logo' src={logos[`${props.activeGame}_${game.vAbb}`]} /> <b>{game.vAbb}</b>
                </Grid.Column>
                <Grid.Column width={6} textAlign='center' className="bonus-box-scores-col">
                  <div style={{marginTop: 5}}><span style={{color: vColor}}>{game.stats.vScore}</span> - <span style={{color: hColor}}>{game.stats.hScore}</span></div> <div style={{fontSize: 8}}> {game.status} </div>
                </Grid.Column>
                <Grid.Column width={5} textAlign='center' style={{backgroundColor: hColor, color: 'white'}} className="bonus-box-team">
                  <Image className='popup-logo' src={logos[`${props.activeGame}_${game.hAbb}`]} /> <b>{game.hAbb}</b>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.vHits}</span>
                </Grid.Column>
                <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                  HITS
                </Grid.Column>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.hHits}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.vGs.length > 0 ? renderBonus('slam', game.stats.vGs, vColor) : null}</span>
                </Grid.Column>
                <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                  GRAND SLAMS
                </Grid.Column>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.hGs.length > 0 ? renderBonus('slam', game.stats.hGs, hColor) : null}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.vCycles.length > 0 ? renderBonus('cycle', game.stats.vCycles, vColor) : null}</span>
                </Grid.Column>
                <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                  CYCLES
                </Grid.Column>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.hCycles.length > 0 ? renderBonus('cycle', game.stats.hCycles, hColor) : null}</span>
                </Grid.Column>
              </Grid.Row>
              {game.vAbb === 'HOU' || game.hAbb === 'HOU' ?
                <Grid.Row>
                  <Grid.Column width={5} textAlign='center'>
                    <span>{game.stats.vAstroBeans > 0 ? renderBonus('beans', game.stats.astrosBeaned, vColor) : null}</span>
                  </Grid.Column>
                  <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                    ASTROS BEANED
                  </Grid.Column>
                  <Grid.Column width={5} textAlign='center'>
                    <span>{game.stats.hAstroBeans > 0 ? renderBonus('beans', game.stats.astrosBeaned, hColor) : null}</span>
                  </Grid.Column>
                </Grid.Row>
              : null}
              <Grid.Row>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.vNoH === true || game.stats.vPerfect === true ?
                    <> {game.stats.vPerfect ? <>
                    <Label circular className="sched-bonus-label" color='orange'>
                      <Icon name='rocket' className='bonus-icon'/>
                    </Label> PERFECT GAME! </>
                      : <>
                      <Label circular className="sched-bonus-label" color='orange'>
                        <Icon name='rocket' className='bonus-icon'/>
                      </Label> NO-HITTER! </>
                    }
                    </>
                  : null}</span>
                </Grid.Column>
                <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                  PITCHING FEATS
                </Grid.Column>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.hNoH === true || game.stats.hPerfect === true ?
                    <> {game.stats.hPerfect ? <>
                    <Label circular className="sched-bonus-label" color='orange'>
                      <Icon name='rocket' className='bonus-icon'/>
                    </Label> PERFECT GAME! </>
                      : <>
                      <Label circular className="sched-bonus-label" color='orange'>
                        <Icon name='rocket' className='bonus-icon'/>
                      </Label> NO-HITTER! </>
                    }
                    </>
                  : null}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.vScore > 14 ? <>
                  <Label circular className="sched-bonus-label" color='orange'>
                    <Icon name='rocket' className='bonus-icon'/>
                  </Label> 15+ RUNS! </>
                  : null}</span>
                </Grid.Column>
                <Grid.Column width={6} className="bonus-box-cat" verticalAlign='middle'>
                  TEAM BONUSES
                </Grid.Column>
                <Grid.Column width={5} textAlign='center'>
                  <span>{game.stats.hScore > 14 ? <>
                  <Label circular className="sched-bonus-label" color='orange'>
                    <Icon name='rocket' className='bonus-icon'/>
                  </Label> 15+ RUNS! </>
                  : null}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{padding: 5}}>
                <Grid.Column width={16} className="bonus-box-subhead">
                  {game.stats ?
                    (game.stats.lastPlay && game.stats.lastPlay.result.description ?
                      <span><b> LAST PLAY: </b>{game.stats.lastPlay.result.description.trim()}</span>
                    :
                      (game.stats.secondToLastPlay && game.stats.secondToLastPlay.result.description ?
                        <span><b> LAST PLAY: </b>{game.stats.secondToLastPlay.result.description.trim()}</span>
                      : null)
                    )
                    : null}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="scoring-plays-header" style={{backgroundColor: "#EFF2F4"}}>
                <Grid.Column width={16} className="bonus-box-head-low">
                  <span> SCORING PLAYS </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="scoring-plays-container">
                <Grid.Column width={16}>
                  {game.stats.scoringPlays ? renderScoringPlays(game.stats.scoringPlays, hColor, vColor, game.hAbb, game.vAbb) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={8} className="bonus-box-right">
            <Diamond currentPlay={game.stats.currentPlay} game={game} hColor={hColor} vColor={vColor}/>
          </Grid.Column>
        </Grid>
      </Popup.Content>
    </Popup>
  )
}
