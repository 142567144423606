import React from 'react';
import { Fade } from 'react-slideshow-image';
import logos_nba_arr from '../../modules/logos_nba_arr';
import logos_mlb_arr from '../../modules/logos_mlb_arr';
import '../styles/slide-container.scss';

const durations = [1500, 2000, 2500, 3000]

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const Slideshow = props => {
  const logos = {
    'nba': logos_nba_arr,
    'mlb': logos_mlb_arr
  }

  return (
    <div className="slide-container">
      <Fade
        duration={durations[getRandomInt(3)]}
        transitionDuration={durations[getRandomInt(3)]}
        infinite={true}
        indicators={false}
        arrows={false}
      >
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
            <img src={logos[props.game][getRandomInt(29)]} alt=''/>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Slideshow;
