import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BonusRows from '../table_helpers/BonusRows';
import { Button, Grid, Header, Dropdown, Image, Icon, Table } from 'semantic-ui-react';

function ManageBonuses ({ activePool, poolBonuses: bonusEvents }) {
  console.log('bonusEvents is ', bonusEvents);
  console.log('activePool is ', activePool);
  return (
    <Table compact className="perf-bonus">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Edit</Table.HeaderCell>
          <Table.HeaderCell>Week</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Game</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Team</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <BonusRows bonusEvents={bonusEvents} game={activePool.game} admin={true} poolId={activePool.id}/>
      </Table.Body>
    </Table>
  );
}


function mapStateToProps ({ poolBonuses }) {
  return { poolBonuses }
}

export default connect (mapStateToProps, {}) (ManageBonuses)
