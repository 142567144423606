import React, { useState } from 'react'
import {
  Header,
  Segment,
  Portal,
} from 'semantic-ui-react'

export default function NotifPortal (props) {
  const [open, setOpen] = useState(props.open)

  return (
    <Portal
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Segment
        style={{ left: '40%', position: 'fixed', top: '50%', zIndex: 1000 }}
      >
        <Header>This is an example portal</Header>
        <p>Portals have tons of great callback functions to hook into.</p>
        <p>To close, simply click the close button or click away</p>
      </Segment>
    </Portal>
  )
}
