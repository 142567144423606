import React, {useEffect}  from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Header, Icon, Grid, Table, Popup } from 'semantic-ui-react';
import ContextPopup from './other_helpers/ContextPopup';
import { clearLeague, clearPool } from '../actions';

const formatDollars = d => d >= 0 ? `$${(d).toFixed(0)}` : `-$${(Math.abs(d)).toFixed(0)}`;

function AllPools ({ auth, allPools, allLeagues, clearLeague, clearPool }) {
  useEffect(() => {
    return () => {
      clearLeague();
      clearPool();
    }
  })

  function renderChamps (champs) {
    return champs.map(c => {
      return (
        <div key={c}>
          <Icon name='trophy' /> {c}
        </div>
      )
    })
  }

  function renderPools () {
    return allPools.map(p => {
      let joinable = allLeagues.find(l => l.id === p.league_id).joinable;
      let inLeague = auth ? auth[0].userSeasons.find(us => us.league_season === p.id) : false;
      return (
        <Table.Row key={p.id}>
        <Table.Cell >
          <b>
            {joinable || inLeague ?
              <Link className={`${p.game}-color`} to={`/${p.game}/${p.league_abb}/${p.season}/${p.season_type}/${p.pool_idx}`}>
                <Icon name={p.game === 'nba' ? 'basketball ball' : 'baseball ball'} />
                {p.game_name}
              </Link>
            :
              <Popup
                content={<><div>This is a private league.</div><div>To request access, visit the league homepage.</div></>}
                trigger={
                  <div className={`${p.game}-color`} style={{position: 'relative'}}><Icon name={p.game === 'nba' ? 'basketball ball' : 'baseball ball'} />
                  {p.game_name}
                  <Icon name='lock' style={{color: 'black', marginLeft: 5, fontSize: 10, position: 'absolute', top: -5}}/>
                  </div>
                }
                position='top center'
              />
            }
          </b>
        </Table.Cell>
        <Table.Cell> <b><Link className='lg-link' to={`/league/${p.league_abb}`}>{allLeagues.find(l => l.id === p.league_id).name}</Link></b>
        </Table.Cell>
        <Table.Cell> {p.final ? <span className={`${p.game}-color`}> {renderChamps(JSON.parse(p.champions))}</span>
        :
          <>
            {moment().isAfter(p.start) ? 'In Progress' : <span style={{position: 'relative'}}>Open For Entry {!joinable ? <Popup
              content={<><div>This is a private league.</div><div>To request access, visit the league homepage.</div></>}
              trigger={
                <Icon name='lock' style={{color: 'black', fontSize: 10,  position: 'absolute', top: -7, marginLeft: 3}}/>
              }
              position='top center'
            /> : null}</span>}
          </>
        } </Table.Cell>
        <Table.Cell textAlign='center'> {p.entrants} </Table.Cell>
        <Table.Cell textAlign='center'> {1 + p.rebuys} </Table.Cell>
        <Table.Cell textAlign='center'> {formatDollars(p.buyin)}/{formatDollars(p.rebuy_price)} </Table.Cell>
        <Table.Cell textAlign='center'> {p.bonus_active ? <span className={`${p.game}-color`}><b><Icon name='rocket'/> Active</b></span> : <span>Disabled</span>} </Table.Cell>
        <Table.Cell textAlign='center'> {p.insurance > 0 ? <span className={`${p.game}-color`}><b><Icon name='shield alternate'/> Available ({formatDollars(p.insurance)})</b></span> : <span>Not Offered</span>} </Table.Cell>
        </Table.Row>
      )
    })
  }

  return (
    <div style={{minHeight: 600}}>
      <Grid style={{marginBottom: 20, marginTop: 5, position: 'relative'}}>
        <Grid.Row className={`page-header`}>
          <Grid.Column width={16}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='dollar sign' circular inverted size='large'/>
              </div>
              <Header.Content>
                All Pools
                <Header.Subheader>
                  - This page display details and configuration settings for all ALA Survivor Pools. <br />
                  - To join a pool, head to the pool's homepage. If a pool is private, you will first need to request access to its league through its league's homepage.
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> Game </Table.HeaderCell>
            <Table.HeaderCell> League </Table.HeaderCell>
            <Table.HeaderCell> Status </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'> Entrants </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'> <Icon name='user outline'/>Lives </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'> <Icon name='dollar sign'/>Buyin/Rebuy </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'> <Icon name='rocket'/>Bonus Pool </Table.HeaderCell>
            <Table.HeaderCell textAlign='center' style={{position: 'relative'}}> <Icon name='shield alternate' />Insurance
              <ContextPopup
              header="What's Insurance?"
              content="If allowed by a pool's Commissioner, entrants are permitted to purchase an insurance policy which offers protection in case they forget to make a pick. An insurance policy is a one-time cost and covers an entrant for the remainder of a season after it is purchased. If an entrant purchases an insurance policy and forgets to make a pick, that entrant will be assigned the most popular pick from their pool that week that they still have available for selection. Proceeds from insurance policies are included in a pool's prize totals and are detailed in the Finances tab."
              trigger={<Icon style={{fontSize: 10, top: 0}} className="context-icon" name='question circle' />}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderPools()}
        </Table.Body>
      </Table>
    </div>
  )
}

function mapStateToProps ({ auth, allPools, allLeagues }) {
  return { auth, allPools, allLeagues }
}

export default connect (mapStateToProps, {clearLeague, clearPool})(AllPools);
