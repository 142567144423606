import mlb_ARI from '../images/logos/mlb/ari.svg';
import mlb_ATL from '../images/logos/mlb/atl.svg';
import mlb_BAL from '../images/logos/mlb/bal.svg';
import mlb_BOS from '../images/logos/mlb/bos.svg';
import mlb_CHC from '../images/logos/mlb/chc.svg';
import mlb_CIN from '../images/logos/mlb/cin.svg';
import mlb_CLE from '../images/logos/mlb/cle.svg';
import mlb_COL from '../images/logos/mlb/col.svg';
import mlb_CWS from '../images/logos/mlb/cws.svg';
import mlb_DET from '../images/logos/mlb/det.svg';
import mlb_HOU from '../images/logos/mlb/hou.svg';
import mlb_KC from '../images/logos/mlb/kc.svg';
import mlb_LAA from '../images/logos/mlb/laa.svg';
import mlb_LAD from '../images/logos/mlb/lad.svg';
import mlb_MIA from '../images/logos/mlb/mia.svg';
import mlb_MIL from '../images/logos/mlb/mil.svg';
import mlb_MIN from '../images/logos/mlb/min.svg';
import mlb_NYM from '../images/logos/mlb/nym.svg';
import mlb_NYY from '../images/logos/mlb/nyy.svg';
import mlb_OAK from '../images/logos/mlb/oak.svg';
import mlb_PHI from '../images/logos/mlb/phi.svg';
import mlb_PIT from '../images/logos/mlb/pit.svg';
import mlb_SD from '../images/logos/mlb/sd.svg';
import mlb_SEA from '../images/logos/mlb/sea.svg';
import mlb_SF from '../images/logos/mlb/sf.svg';
import mlb_STL from '../images/logos/mlb/stl.svg';
import mlb_TB from '../images/logos/mlb/tb.svg';
import mlb_TEX from '../images/logos/mlb/tex.svg';
import mlb_TOR from '../images/logos/mlb/tor.svg';
import mlb_WSH from '../images/logos/mlb/wsh.svg';

export default [
  mlb_ARI,
  mlb_ATL,
  mlb_BAL,
  mlb_BOS,
  mlb_CHC,
  mlb_CIN,
  mlb_CLE,
  mlb_COL,
  mlb_CWS,
  mlb_DET,
  mlb_HOU,
  mlb_KC,
  mlb_LAA,
  mlb_LAD,
  mlb_MIA,
  mlb_MIL,
  mlb_MIN,
  mlb_NYM,
  mlb_NYY,
  mlb_OAK,
  mlb_PHI,
  mlb_PIT,
  mlb_SD,
  mlb_SEA,
  mlb_SF,
  mlb_STL,
  mlb_TB,
  mlb_TEX,
  mlb_TOR,
  mlb_WSH
];
