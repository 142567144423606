import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Segment, Loader, Grid, Transition} from 'semantic-ui-react';
import ALAHeader from './Header';
import Features from './getstarted/Features';
import HowToPlay from './getstarted/HowToPlay';
import CreateAPool from './getstarted/CreateAPool';
import JoinAPool from './getstarted/JoinAPool';
import StartHere from './getstarted/StartHere';
import lillard from '../images/lillard_game_winner.jpg';
import jordan from '../images/jordan_banner.jpg';
import logo from '../images/landing-logo.png';
import logoBlack from '../images/logo_black.png';
import './styles/getstarted.scss';

function GetStarted ({ allLeagues, location }) {
  const [activeItem, setActiveItem] = useState(location.state ? location.state.activeItem : 'starthere');

  function renderActiveItem () {
    if (activeItem === 'features') {
      return <Features  />;
    }  else if (activeItem === 'howtoplay') {
      return <HowToPlay  />;
    } else if (activeItem === 'starthere') {
      return <StartHere />
    }
  }
  function zoomOutMobile() {
    var viewport = document.querySelector('meta[name="viewport"]');

    if ( viewport ) {
      viewport.content = "initial-scale=0.4";
    }
  }
  zoomOutMobile();

  if (allLeagues) {
    return (
      <div className='get-started-container'>
        <img src={lillard} className="home-bg" alt='dame'></img>
        <ALAHeader />
        <div style={{height: 275, width: '100%', display: 'inline-block'}}>
          <img style={{maxHeight: '100%', width: '100%', position: 'relative', zIndex: '-999'}} src={jordan} alt=
          'getstarted'/>
          <div className='get-started-header'>
            <div className='logo-cont'>
              <img src={logoBlack} className='get-started-logo'/>
            </div>
            <div className='text-cont'>
              <div>
              <div style={{color: 'white'}}><Icon name='level up alternate' className='flipped' style={{color: '#c73230'}}/> LEVEL UP <Icon name='level up alternate' style={{color: '#c73230'}} /></div> Your Survivor Pool.
              </div>
            </div>
          </div>
          <Segment inverted className='get-started-buttons' textAlign='center'>
            <Button size='massive' active={activeItem==='starthere'} onClick={() => setActiveItem('starthere')}>
              START HERE
            </Button>
            <Button size='massive' active={activeItem==='howtoplay'} onClick={() => setActiveItem('howtoplay')}>
              HOW TO PLAY
            </Button>
            <Button size='massive' active={activeItem==='features'} onClick={() => setActiveItem('features')}>
              KICK-ASS FEATURES
            </Button>
          </Segment>
        </div>
        <div style={{border: 'solid 1px #c73230', backgroundColor: 'white', marginTop: '-55px'}}>
          {renderActiveItem()}
        </div>
      </div>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
  }
}

function mapStateToProps ({ allLeagues }) {
  return { allLeagues }
}

export default connect (mapStateToProps, {}) (GetStarted)
