import React from 'react';
import { Image } from 'semantic-ui-react';
import logos from '../../modules/logos';

export default function Diamond ({ currentPlay, game, hColor, vColor }) {
  const { stats, hAbb, vAbb } = game;
  const { secondToLastPlay } = stats;
  const hitting = currentPlay ? (currentPlay.about.isTopInning ? vAbb : hAbb) : null;
  const pitching = currentPlay ? (currentPlay.about.isTopInning ? hAbb : vAbb) : null;
  const hittingColor = currentPlay ? (currentPlay.about.isTopInning ? vColor : hColor) : null;
  const pitchingColor = currentPlay ? (currentPlay.about.isTopInning ? hColor : vColor) : null;
  return (
    <div id="stadium">
      <div id="field" className="mowed-grass"></div>
      <div id="summary">
        {game.status !== 'Final' ?
        <span style={{paddingLeft: 3}}><b> LIVE! </b></span> :
        (game.stats.decisions.winner ?
          <>
            <div> <b>W</b>: {game.stats.decisions.winner.fullName} </div>
            <div> <b>L</b>: {game.stats.decisions.loser.fullName} </div>
            {game.stats.decisions.save ?
              <div> <b>S</b>: {game.stats.decisions.save.fullName} </div>
            : null}
          </>
          : null)
        }
      </div>
      <div id="in-field"></div>
      <div id="in-field-grass" className="mowed-grass"></div>
      <div id="batting-circle"></div>
      <div id="first-base" className="base">
        {secondToLastPlay ?
          (secondToLastPlay.matchup.postOnFirst ?
            <div className="pulse" style={{borderColor: hittingColor}}></div>
          : null)
          : null
        }
        <div className="action-area"></div>
      </div>
      <div id="second-base" className="base">
        {secondToLastPlay ?
          (secondToLastPlay.matchup.postOnSecond ?
            <div className="pulse" style={{borderColor: hittingColor}}></div>
          : null)
          : null
        }
        <div className="action-area"></div>
      </div>
      <div id="third-base" className="base">
        {secondToLastPlay ?
          (secondToLastPlay.matchup.postOnThird ?
            <div className="pulse" style={{borderColor: hittingColor}}></div>
          : null)
          : null
        }
        <div className="action-area"></div>
      </div>
      <div id="hitters-logo">
        <Image src={logos[`mlb_${hitting}`]} avatar />
      </div>
      <div id="home-plate">
        <div className="action-area"></div>
      </div>
      <div id="hitters-name" className="di-name">
        <div style={{borderColor: hittingColor}}>{
          currentPlay ?
            (currentPlay.matchup.batter.fullName.split(' ').slice(-1) === 'Jr.' ?
              currentPlay.matchup.batter.fullName.split(' ').slice(-2, -11) : currentPlay.matchup.batter.fullName.split(' ').slice(-1)
            )
          : null}
        </div>
      </div>
      <div id="base-lines"></div>
      <div id="pitchers-logo">
        <Image src={logos[`mlb_${pitching}`]} avatar />
      </div>
      <div id="pitchers-mound"></div>
      <div id="pitchers-name" className="di-name">
        <div style={{borderColor: pitchingColor}}>{
          currentPlay ?
          (currentPlay.matchup.pitcher.fullName.split(' ').slice(-1) === 'Jr.' ?
            currentPlay.matchup.pitcher.fullName.split(' ').slice(-2, -1) : currentPlay.matchup.pitcher.fullName.split(' ').slice(-1)
          )
            : null}
        </div>
      </div>
      <div id="pitchers-plate"></div>
      <div id="first-circle" className="half-circle"></div>
      {secondToLastPlay ?
        (secondToLastPlay.matchup.postOnFirst ?
          <div id="first-runner-name" className="di-name">
            <div style={{borderColor: hittingColor}}>{secondToLastPlay.matchup.postOnFirst.fullName.split(' ').slice(-1)}
            </div>
          </div>
        : null)
        : null
      }
      <div id="second-circle" className="half-circle"></div>
      {secondToLastPlay ?
        (secondToLastPlay.matchup.postOnSecond ?
          <div id="second-runner-name" className="di-name">
            <div style={{borderColor: hittingColor}}>{secondToLastPlay.matchup.postOnSecond.fullName.split(' ').slice(-1)}
            </div>
          </div>
        : null)
        : null
      }
      <div id="third-circle" className="half-circle"></div>
      {secondToLastPlay ?
        (secondToLastPlay.matchup.postOnThird ?
          <div id="third-runner-name" className="di-name">
            <div style={{borderColor: hittingColor}}>{secondToLastPlay.matchup.postOnThird.fullName.split(' ').slice(-1)}
            </div>
          </div>
        : null)
        : null
      }
      <div id="batters-box-right" className="batters-box"></div>
      <div id="batters-box-left" className="batters-box"></div>
      {game.status !== 'Final' ?
        (currentPlay ? <div id="counter">
          <div> <b>B</b>: {currentPlay.count.balls} </div>
          <div> <b>S</b>: {currentPlay.count.strikes} </div>
          <div> <b>O</b>: {currentPlay.count.outs} </div>
        </div> : null)
      : null}
      <div id="first-base-thing"></div>
    </div>
  )
}
