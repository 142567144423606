import nba_ATL from '../images/logos/nba/atl.svg';
import nba_BKN from '../images/logos/nba/bkn.svg';
import nba_BOS from '../images/logos/nba/bos.svg';
import nba_CHA from '../images/logos/nba/cha.svg';
import nba_CHI from '../images/logos/nba/chi.svg';
import nba_CLE from '../images/logos/nba/cle.svg';
import nba_DAL from '../images/logos/nba/dal.svg';
import nba_DEN from '../images/logos/nba/den.svg';
import nba_DET from '../images/logos/nba/det.svg';
import nba_GSW from '../images/logos/nba/gsw.svg';
import nba_HOU from '../images/logos/nba/hou.svg';
import nba_IND from '../images/logos/nba/ind.svg';
import nba_LAC from '../images/logos/nba/lac.svg';
import nba_LAL from '../images/logos/nba/lal.svg';
import nba_MEM from '../images/logos/nba/mem.svg';
import nba_MIA from '../images/logos/nba/mia.svg';
import nba_MIL from '../images/logos/nba/mil.svg';
import nba_MIN from '../images/logos/nba/min.svg';
import nba_NOP from '../images/logos/nba/nop.svg';
import nba_NYK from '../images/logos/nba/nyk.svg';
import nba_OKC from '../images/logos/nba/okc.svg';
import nba_ORL from '../images/logos/nba/orl.svg';
import nba_PHI from '../images/logos/nba/phi.svg';
import nba_PHX from '../images/logos/nba/phx.svg';
import nba_POR from '../images/logos/nba/por.svg';
import nba_SAC from '../images/logos/nba/sac.svg';
import nba_SAS from '../images/logos/nba/sas.svg';
import nba_TOR from '../images/logos/nba/tor.svg';
import nba_UTA from '../images/logos/nba/uta.svg';
import nba_WAS from '../images/logos/nba/was.svg';

export default [
  nba_ATL,
  nba_BKN,
  nba_BOS,
  nba_CHA,
  nba_CHI,
  nba_CLE,
  nba_DAL,
  nba_DEN,
  nba_DET,
  nba_GSW,
  nba_HOU,
  nba_IND,
  nba_LAC,
  nba_LAL,
  nba_MEM,
  nba_MIA,
  nba_MIL,
  nba_MIN,
  nba_NOP,
  nba_NYK,
  nba_OKC,
  nba_ORL,
  nba_PHI,
  nba_PHX,
  nba_POR,
  nba_SAC,
  nba_SAS,
  nba_TOR,
  nba_UTA,
  nba_WAS
];
