import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Header, Icon } from 'semantic-ui-react';
import LeagueBanner from './LeagueBanner';
import ALAHeader from '../Header';
import lillard from '../../images/lillard_game_winner.jpg';
import "../styles/leagues.scss";

export default function LeagueError ({ history }) {
  return (
    <div>
      <ALAHeader />
      <div className="master-container">
        <img src={lillard} className="home-bg" alt='dame'></img>
        <LeagueBanner name={"YOU'RE OUT OF BOUNDS."} />
        <Segment placeholder className="league-error">
          <Header as={'h1'} icon>
            <Icon name='ban' color='red'/>
            <div>No league was found matching these parameters.</div>
            <div>Please <Link to="/leagues">head back to the Leagues page</Link> or email support if you think this is a mistake.</div>
          </Header>
        </Segment>
      </div>
    </div>
  )
}
