import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu, Grid, Header, Icon } from 'semantic-ui-react';
import CommishPost from './commish_tools/CommishPost';
import ManageEntrants from './commish_tools/ManageEntrants';
import RequestsToJoin from './commish_tools/RequestsToJoin';
import SplitPrizes from './commish_tools/SplitPrizes';
import EditPicks from './commish_tools/EditPicks';
import ManageBonuses from './commish_tools/ManageBonuses';
import EmailPool from './commish_tools/EmailPool';
import Finances from './commish_tools/Finances';
import './styles/nba-landing.scss';
import './styles/commish-tools.scss';

function CommishTools ({ umbrella, activePool, gameEntrants, prizes, poolSelections }) {
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    if (umbrella === 'pool') {
      setActiveItem('manageEntrants')
    } else {
      setActiveItem('reqToJoin')
    }
  }, [umbrella])

  function renderActiveItem () {
    if (umbrella === 'pool') {
      if (activeItem === 'manageEntrants') {
        return <ManageEntrants activePool={activePool} gameEntrants={gameEntrants} prizes={prizes} />;
      } else if (activeItem === 'commishDesk') {
        return <CommishPost activePool={activePool} />;
      } else if (activeItem === 'finances') {
        return <Finances activePool={activePool} gameEntrants={gameEntrants} />;
      }else if (activeItem === 'splitPrizes') {
        return <SplitPrizes activePool={activePool} gameEntrants={gameEntrants} prizes={prizes}/>;
      } else if (activeItem === 'emailPool') {
        return <EmailPool umbrella={umbrella} />;
      } else if (activeItem === 'editPicks') {
        return <EditPicks poolSelections={poolSelections} activePool={activePool} gameEntrants={gameEntrants} />;
      } else if (activeItem === 'bonuses') {
        return <ManageBonuses activePool={activePool} />;
      }
    } else {
      if (activeItem === 'reqToJoin') {
        return <RequestsToJoin />;
      } else if (activeItem === 'commishDesk') {
        return <CommishPost activePool={activePool} />;
      } else if (activeItem === 'emailPool') {
        return <EmailPool umbrella={umbrella} />;
      } else if (activeItem === 'editPicks') {
        return <EditPicks poolSelections={poolSelections} activePool={activePool} gameEntrants={gameEntrants} />;
      }
    }
  }

  const gameMenuItems = [
    <Menu.Item
      key='manageEntrants'
      name='manageEntrants'
      active={activeItem === 'manageEntrants'}
      onClick={() => setActiveItem('manageEntrants')}
    >
      Manage Entrants
    </Menu.Item>,
    <Menu.Item
      key='commishDesk'
      name='commishDesk'
      active={activeItem === 'commishDesk'}
      onClick={() => setActiveItem('commishDesk')}
    >
      Commish's Desk
    </Menu.Item>,
    <Menu.Item
      key='finances'
      name='finances'
      active={activeItem === 'finances'}
      onClick={() => setActiveItem('finances')}
    >
      Finances
    </Menu.Item>,
    <Menu.Item
      key='editPicks'
      name='editPicks'
      active={activeItem === 'editPicks'}
      onClick={() => setActiveItem('editPicks')}
    >
      Edit Picks
    </Menu.Item>,
    <Menu.Item
      key='splitPrizes'
      name='splitPrizes'
      active={activeItem === 'splitPrizes'}
      onClick={() => setActiveItem('splitPrizes')}
    >
      Split Prizes
    </Menu.Item>,
    <Menu.Item
      key='bonuses'
      name='bonuses'
      active={activeItem === 'bonuses'}
      onClick={() => setActiveItem('bonuses')}
    >
      Bonuses
    </Menu.Item>
  ]

  const leagueMenuItems = [
    <Menu.Item
      key='reqToJoin'
      name='reqToJoin'
      active={activeItem === 'reqToJoin'}
      onClick={() => setActiveItem('reqToJoin')}
    >
      Requests to Join
    </Menu.Item>
  ];

  function renderMenu () {
    let menu = [
      <Menu.Item
        key='emailPool'
        name='emailPool'
        active={activeItem === 'emailPool'}
        onClick={() => setActiveItem('emailPool')}
      >
        {umbrella === 'pool' ? 'Email Pool' : 'Email League'}
      </Menu.Item>
    ];

    if (umbrella === 'pool') {
      menu.unshift( ...gameMenuItems);
    } else {
      menu.unshift( ...leagueMenuItems);
    }
    return menu;
  }

  return (
    <div>
      <Grid style={{marginBottom: 20, marginTop: 5, position: 'relative'}}>
        <Grid.Row className={`page-header ${activePool ? activePool.game : 'league'} commish`}>
          <Grid.Column width={16}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='gavel' circular inverted size='large'/>
              </div>
              <Header.Content>
                Commish Tools
                <Header.Subheader>
                  - {umbrella === 'pool' ? 'Pool' : 'League'} Commissioners can use these features to manage entrants and modify various game setting and configuration options.
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Menu id='commish-menu'>
        {renderMenu()}
      </Menu>
      <div style={{backgroundColor: '#f4f4f4'}}>
        {renderActiveItem()}
      </div>
    </div>
  )
}


function mapStateToProps ({ poolSelections }) {
  return ({
    poolSelections
  })
}

export default connect (mapStateToProps, {}) (CommishTools);
