import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUser, allSeasonParams, fetchAllLeagues } from '../actions';
import ToastContainer from './other_helpers/ToastContainer';
import history from '../history';
import './styles/landing.scss';
import GetStarted from './GetStarted';
import Landing from './Landing';
import NBA from './NBA';
import Register from './Register';
import ResetPassword from './ResetPassword';
import User from './User';
import Leagues from './Leagues';
import LeagueMain from './leagues/LeagueMain';
import LeagueError from './leagues/LeagueError';
import ScrollToTop from './other_helpers/ScrollToTop';
import ReactGA from 'react-ga';

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
    this.props.allSeasonParams();
    this.props.fetchAllLeagues();
  }

  render () {
    return (
      <div>
        <Router history={history}>
          <ScrollToTop />
          <ToastContainer />
          <Route key='landing' exact path="/" component={Landing} />
          <Route key='getstarted' exact path='/getstarted' component={GetStarted} />
          <Route key='register' exact path="/register" component={Register} />
          <Route key='resetpassword' exact path="/reset-password/:token" component={ResetPassword} />
          <Route key='profile' exact path='/profile' component={User} />
          <Route key='leagues' path='/leagues' component={Leagues} />
          <Route key='invite' path="/invite/league/:code" render={props => <LeagueMain {...props} invite={true} />}/>
          <Route key='league:error' exact path="/league/error/notfound" component={LeagueError}/>
          <Route key='league' path="/league/:abb" component={LeagueMain}/>
          <Route key='game' path='/:game/:leagueAbb/:season/:type/:poolidx' component={NBA} />
          <Route key='nba-offline' path='/nba19/offline' component={() => {
            window.location.href = 'https://docs.google.com/spreadsheets/d/17rWG0se9aYv8__0rZs9fZTxzsTxOK8O4x5m1fj5Svl4/edit?usp=sharing';
            return null;
          }} />
        </Router>
      </div>
    )
  }
}

export default connect(null, {fetchUser, allSeasonParams, fetchAllLeagues})(App);
