import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, NavLink, useParams } from 'react-router-dom';
import { Tab, Icon, Loader } from 'semantic-ui-react';
import lillard from '../images/lillard_game_winner.jpg';
import harper from '../images/harper_hr.jpg';
import lillardWp from '../images/lillard-wp.jpg';
import yankeeWp from '../images/yankee-wp.jpg';
import {
  fetchTeamSchedules, loadActivePool, fetchPoolSelections, fetchTeamDetails, fetchTeamResults, fetchFeed, fetchPayments, fetchSeasonParams, fetchLeague, clearPool, getBonusOptions, clearLeague
} from '../actions';
import './styles/nba-landing.scss';
import './styles/game-home.scss';
import Header from './Header';
import NBAHome from './nba/Home';
import MakePicks from './nba/MakePicks';
import Standings from './Standings';
import Scoreboard from './Scoreboard';
import HowToPlay from './HowToPlay';
import PerfBonus from './PerfBonus';
import RebuyModal from './RebuyModal';
import Finances from './Finances';
import CommishTools from './CommishTools';

const gameTheme = {
  'nba': {
    "--color-main": "#D06C35"
  },
  'mlb': {
    '--color-main': '#042F70'
  },
  'default': {
    '--color-main': '#184271'
  }
}

function NBALanding ({ auth, activePool, deadlines, gameEntrants, prizes, league, fetchTeamSchedules, loadActivePool, fetchPoolSelections, fetchTeamDetails, fetchTeamResults, fetchFeed, fetchPayments, fetchSeasonParams, fetchLeague, clearPool, getBonusOptions, allLeagues, clearLeague, activeGameWeek }) {
  let { game, leagueAbb, season, poolidx, type } = useParams();
  const [activeSeason, setActiveSeason] = useState(season);
  const [activeLeague, setActiveLeague] = useState(leagueAbb);
  const [activeType, setActiveType] = useState(type);
  const [activePoolIdx, setActivePoolIdx] = useState(poolidx);

  function zoomOutMobile() {
    var viewport = document.querySelector('meta[name="viewport"]');

    if ( viewport ) {
      viewport.content = "initial-scale=0.5";
    }
  }
  zoomOutMobile();

  useEffect(() => {
    Object.keys(gameTheme[game]).map(key => {
      const value = gameTheme[game][key];
      document.documentElement.style.setProperty(key, value);
    });

    if (!leagueAbb) {setActiveLeague('alevelabove')}
    if (!season) {setActiveSeason(2019)};
    if (!poolidx) {setActivePoolIdx(0)};
    if (!type) {setActiveType('reg')};

    if (game && activeLeague && activeSeason && activePoolIdx) {
      loadActivePool(game, activeSeason, activeLeague, activePoolIdx, activeType);
      fetchTeamSchedules(game, activeSeason, activeType);
      // fetchPoolSelections(game, activeSeason, activeLeague, activePoolIdx, activeType);
      fetchTeamDetails(game); // n/a
      fetchTeamResults(game, activeSeason, activeType); // n/a
      fetchPayments(game, activeSeason, activeLeague, activePoolIdx, activeType);
      fetchSeasonParams(game, activeSeason, activeType);
      fetchLeague('abb', activeLeague);
      getBonusOptions(game);
    }
  }, [game, leagueAbb, season, poolidx, type])

    const panes = [
      {
        menuItem: {
          as: NavLink,
          id: "tab1",
          content: "Home",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}`,
          exact: true,
          key: "home"
        },
        pane: (
          <Route
            key="game:home"
            exact
            path="/:game/:leagueAbb/:season/:type/:poolidx"
            render={() => (
              <Tab.Pane className="nba-content">
                <NBAHome/>
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "tab2",
          content: "How to Play",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/howtoplay`,
          exact: true,
          key: "howtoplay"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/howtoplay"
            key="game:howtoplay"
            render={() => (
              <Tab.Pane className="nba-content how-to-play-tab">
                <HowToPlay activePool={activePool} auth={auth} league={league}/>
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "tab3",
          content: "Make Picks",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/makepicks`,
          exact: true,
          key: "makepicks"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/makepicks"
            key="game:makepicks"
            render={() => (
              <Tab.Pane className="nba-content">
                <MakePicks/>
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "tab4",
          content: "Scoreboard",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/scoreboard`,
          exact: true,
          key: "scoreboard"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/scoreboard"
            key="game:scoreboard"
            render={() => (
              <Tab.Pane className="nba-content">
                <Scoreboard game={game}/>
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "tab5",
          content: "Standings",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/standings`,
          exact: true,
          key: "standings"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/standings"
            key="game:standings"
            render={() => (
              <Tab.Pane className="nba-content">
                <Standings game={game}/>
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "tab6",
          content: "Bonuses",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/bonuses`,
          exact: true,
          key: "bonuses"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/bonuses"
            key="game:bonuses"
            render={() => (
              <Tab.Pane className="nba-content">
                <PerfBonus />
              </Tab.Pane>
            )}
          />
        )
      },
      {
        menuItem: {
          as: NavLink,
          id: "tab7",
          content: "Finances",
          to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/finances`,
          exact: true,
          key: "finances"
        },
        pane: (
          <Route
            path="/:game/:leagueAbb/:season/:type/:poolidx/finances"
            key="game:finances"
            render={() => (
              <Tab.Pane className="nba-content">
                <Finances />
              </Tab.Pane>
            )}
          />
        )
      }
    ];

    if (auth !== null && activePool !== null && deadlines !== null && gameEntrants !== null && league !== null && allLeagues !== null && activeGameWeek !== null)  {
      if (auth && JSON.parse(league.info.admins).includes(auth[0].username)) {
        panes.push(
          {
            menuItem: {
              as: NavLink,
              id: "tab8",
              content: "Commish Tools",
              to: `/${game}/${activeLeague}/${activeSeason}/${activeType}/${activePoolIdx}/commishtools`,
              exact: true,
              key: "commishtools"
            },
            pane: (
              <Route
                path="/:game/:leagueAbb/:season/:type/:poolidx/commishtools"
                key="game:commishtools"
                exact
                render={() => (
                  <Tab.Pane className="nba-content">
                    <CommishTools umbrella={'pool'} activePool={activePool} gameEntrants={gameEntrants} prizes={prizes}/>
                  </Tab.Pane>
                )}
              />
            )
          }
        )
      }

      return (
        <div>
          <img src={game === 'nba' ? lillard : harper} className="home-bg" alt={game}></img>
          <Header />
          <div className="master-container">
            <div className="game-susp"><Icon name="warning sign"/>
              {activePool.league_abb === 'alevelabove' ?
                <> You're viewing the homepage for the global ALA {activePool.game.toUpperCase()} Survivor Pool, which is open to the public. </>
              : <>
                  You're viewing the homepage for {league.info.name}'s {activePool.season} {activePool.game.toUpperCase()} Survivor Pool. To navigate to ALA's global {activePool.season} {activePool.game.toUpperCase()} Survivor Pool, <a href={`/${activePool.game}/alevelabove/${activePool.season}/${activePool.season_type}/${activePool.pool_idx}`}>click here</a>.  </> 
              }
            <Icon name="warning sign"/></div>
            <div className="nba-banner">
              <img src={activePool.game === 'nba' ? lillardWp : yankeeWp} alt='dameWp' className={`${activePool.game}-wp`}/>
              <div className="banner-text">{activePool.game.toUpperCase()} {activeType === 'reg' ? 'SURVIVOR' : '[PRE] SURVIVOR'} {activePool.season}</div>
            </div>
            <Switch>
              <Tab renderActiveOnly={false} activeIndex={-1} panes={panes} className="nba-menu" />
            </Switch>
          </div>
        </div>
      )
    } else {
      return (
        <div className="master-container">
          <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
        </div>
      )
    }
}

function mapStateToProps ({ auth, activePool, deadlines, gameEntrants, prizes, league, allLeagues, activeGameWeek }) {
  return { auth, activePool, deadlines, gameEntrants, prizes, league, allLeagues, activeGameWeek }
}

export default connect(mapStateToProps, {
  fetchTeamSchedules,
  loadActivePool,
  fetchPoolSelections,
  fetchTeamDetails,
  fetchTeamResults,
  fetchFeed,
  fetchPayments,
  fetchSeasonParams,
  fetchLeague,
  clearPool,
  getBonusOptions,
  clearLeague
} )(NBALanding);
