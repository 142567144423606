import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader, Pagination, Table, Icon, Image, Grid, Header } from 'semantic-ui-react';
import TableHeaders from './table_helpers/TableHeaders';
import RenderWeekSchedule from './table_helpers/RenderWeekSchedule';
import RenderLives from './table_helpers/RenderLives';
import { fetchTeamSchedules } from '../actions';
import { Icon as Iconify } from '@iconify/react';
import scoreboardIcon from '@iconify-icons/mdi/scoreboard';
import logos from '../modules/logos';
import "./styles/selections.scss";

const userStyle = (entrant, activeUser) => {
  if (entrant === activeUser) {
    return 'activeUser'
  }
}

function Scoreboard ({ activePool, selectedSeasons, teamSchedules, scheduleWeeks, liveWeek, poolSelections, activeGameWeek, teamResults, teamDetails, auth, fetchTeamSchedules, seasonParams, gameEntrants }) {
  const [schedWeek, setSchedWeek] = useState(liveWeek);
  const [render, setRender] = useState(false);
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    let weeks = [];
    scheduleWeeks.forEach((week, idx, arr) => {
      weeks.push(idx + 1);
      if (idx === arr.length-1) {
        setWeeks(weeks);
        setRender(true);
      }
    })

    const refreshBoard = setInterval(() => {
      if (liveWeek >= 1 && !seasonParams.final) {
        fetchTeamSchedules(activePool.game, activePool.season, activePool.season_type, true);
      }
    }, 15000)

    if (activePool.final) {
      setSchedWeek(gameEntrants[0].weeks_alive);
    }

    return () => clearInterval(refreshBoard);
  }, [])

  const changeWeek = (e, { activePage }) => {
    setSchedWeek(activePage);
  };

  function renderRows () {
    const activeUser = auth ? auth[0].username : null;
    return poolSelections
      .filter(e => e.status !== 0)
      .map((user, idx) => {
        const { username, selections, lives } = user;
        let team = selections[0][schedWeek - 1];
        return (
          <Table.Row key={`${username}_${idx}`}>
            <Table.Cell className={`${userStyle(`${username}`, `${activeUser}`)}`}> {username} </Table.Cell>
            <Table.Cell className={`lives-cell ${userStyle(`${username}`, `${activeUser}`)}`}> <RenderLives lives={lives} /> </Table.Cell>
            <Table.Cell className={`scoreboard-pick ${userStyle(`${username}`, `${activeUser}`)}`}>
              {team.length>1 && team !== 'xxx' ?
              <Image src={logos[`${activePool.game}_${team}`]}/> :
              <div className={`no-pick ${userStyle(`${username}`, `${activeUser}`)}`}>{lives > 0 ? 'NO PICK' : <span className={activeUser === username ? 'white' : 'game-color'}>ELIMINATED</span>}</div>}
            </Table.Cell>
            <Table.Cell className={`${userStyle(`${username}`, `${activeUser}`)}`}> {
              schedWeek <= liveWeek ?
                (team.length>1 && team !== 'xxx' ?
                    `${teamResults[team][schedWeek - 1][0]} - ${teamResults[team][schedWeek - 1][1]}`
                  :
                null)
              : null
            }
            </Table.Cell>
            <RenderWeekSchedule
              key={`${username}_${idx}`}
              activeGame={activePool.game}
              schedule={teamSchedules[team]}
              team={team}
              week={schedWeek}
              teamDetails={teamDetails}
              className={`${userStyle(`${username}`, `${activeUser}`)}`}
            />
          </Table.Row>
        )
    })
  }

  if (!render || !teamSchedules) {
    return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
  } else {
    return (
      <div style={{minHeight: 800}}>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game}`}>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  <Iconify className="iconify-icon" icon={scoreboardIcon} />
                </div>
                <Header.Content>
                  Live Scoring
                  <Header.Subheader>
                    - The scoreboard will update live with game results, box score leaders, and bonus <Icon name='rocket' />achievements. <br />
                    - Hover over (or click, on mobile) a game that has gotten underway for details.
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="make-picks-top-row">
          <span className="week-header">Week: </span>
          <Pagination
            className="picks-pagination"
            siblingRange={5}
            totalPages={weeks.length}
            onPageChange={changeWeek}
            activePage={schedWeek}
          />
        </div>
        {poolSelections.length ?
          <>
            { schedWeek <= liveWeek && liveWeek > 0 ?
              <div>
              <div className="hover-exp"> - Hover over active games for leaders & bonus details - </div>
              <Table celled className="selectionsTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell> Entrant </Table.HeaderCell>
                    <Table.HeaderCell> Lives </Table.HeaderCell>
                    <Table.HeaderCell> Selection </Table.HeaderCell>
                    <Table.HeaderCell> Week </Table.HeaderCell>
                    <TableHeaders
                      view={"By Day"}
                      days={scheduleWeeks[schedWeek-1]}
                    />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {renderRows()}
                </Table.Body>
              </Table>
              </div>
              :
                <div className="picks-hidden">
                  <Icon name='lock' size='massive' />
                  <div className="picks-hidden">Picks will become visible once selections lock for this week.</div>
                </div>
            }
          </>
        :
          <div className="picks-hidden">
            <Icon name='sun' size='massive' />
            <div className="picks-hidden">Picks will show up here once this pool contains active users.</div>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps ({ activePool, selectedSeasons, teamSchedules, scheduleWeeks, liveWeek, poolSelections, activeGameWeek, teamResults, teamDetails, auth, seasonParams, gameEntrants }) {
  return ({ activePool, selectedSeasons, teamSchedules, scheduleWeeks, liveWeek, poolSelections, activeGameWeek, teamResults, teamDetails, auth, seasonParams, gameEntrants })
}

export default connect (mapStateToProps, {fetchTeamSchedules}) (Scoreboard);
