import { SET_FEED, CLEAR_POOL } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case SET_FEED:
      return action.payload;
    case CLEAR_POOL:
      return null;
    default:
      return state;
  }
}
