export * from './user/types';

export const FETCH_USER = 'fetch_user';
export const UPDATE_USER_GAME_DETAILS = 'update_user_games';
export const FETCH_TEAM_SCHEDULES = 'fetch_team_schedules';
export const FETCH_SCHEDULE_WEEKS = 'fetch_schedule_weeks';
export const FETCH_ACTIVE_WEEK = 'fetch_active_week';
export const SET_ACTIVE_GAME = 'set_active_game';
export const SET_ACTIVE_DATA = 'set_active_data';
export const REGISTER_USER = 'register_user';
export const REG_ERROR = 'reg_error';
export const LOGIN_USER = 'login_user';
export const LOGIN_ERROR = 'login_error';
export const REGISTER_FOR_GAME = 'register_for_game';
export const FETCH_GAME_SELECTIONS = 'fetch_game_selections';
export const FETCH_SEASON_LENGTH = 'fetch_season_length';
export const SET_GAME_ENTRANTS = 'set_game_entrants';
export const SET_ALPH_TEAMS = 'set_alph_teams';
export const LOAD_ACTIVE_POOL = 'load_active_pool';
export const SET_LIVE_WEEK = 'set_live_week';
export const SET_CAL_WEEK = 'set_cal_week';
export const SET_DEADLINES = 'set_deadlines';
export const FETCH_POOL_SELECTIONS = 'fetch_pool_selections';
export const FETCH_TEAM_RESULTS = 'fetch_team_results';
export const SET_WEEKLY_TOTALS = 'set_weekly_totals';
export const SET_FEED = 'fetch_feed';
export const FILTER_FEED = 'filter_feed';
export const USER_FEED = 'filter_feed';
export const UPDATE_FEED = 'update_feed';
export const FETCH_TEAM_DETAILS = 'fetch_team_details';
export const WEEKLY_BONUS_TOTALS = 'weekly_bonus_totals';
export const GAME_BONUS_TOTALS = 'game_bonus_totals';
export const SET_BONUS_EVENTS = 'fetch_bonus_events';
export const FETCH_POOL_BONUSES = 'fetch_pool_bonuses';
export const USER_REBUY = 'user_rebuy';
export const SET_COMMISH_POSTS = 'set_commish_posts';
export const FETCH_POOL_HEALTH = 'fetch_pool_health';
export const SET_USERS = 'set_users';
export const SET_PAYMENTS = 'set_payments';
export const FETCH_SEASON_PARAMS = 'fetch_season_params';
export const SET_PRIZE_DATA = 'set_prize_data';
export const LEAGUE_CREATE_ERROR = 'league_create_error';
export const SET_LEAGUE = 'fetch_league';
export const SEASON_PARAMS_INDEX = 'season_params_index';
export const PENDING_SEASON_PARAMS = 'pending_season_params';
export const SET_JOIN_MODAL_VIS = 'set_join_modal_vis';
export const GET_BONUS_OPTIONS = 'get_bonus_options';
export const CLEAR_LEAGUE = 'clear_league';
export const CLEAR_POOL = 'clear_pool';
export const EMAIL_RESULT = 'email_result';
export const ALL_LEAGUES = 'all_leagues';
export const SET_LOADING = 'set_loading';
export const ALL_POOLS = 'all_pools';
export const LEAGUE_TRANSACTIONS = 'league_transactions';
export const SET_CURRENT_SEASONS = 'set_current_seasons';
export const SET_PW_RESET = 'set_pw_reset';
export const SET_PW_RESET_USER = 'set_pw_reset_user';
