import React from 'react';
import { Item, List, Icon, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";

function JoinAPool () {
  return (
    <SRLWrapper>
      <Item.Group className="join-pool-instructions">
        <Item>
          <div className='item-group-image-container'>
            <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/pools_page.png' alt="This is the Pools page, which displays all public and private ALA Survivor pools, along with their associated League and the pool's configuration settings."/>
            <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/join_pool_button.png' alt="A pool's SIGN UP button is found in the upper-lefthand quadrant of its pool homepage."/>
          </div>
          <Item.Content>
            <Item.Header> <Icon name='group' style={{marginRight: '7px'}}/>JOIN ONE OF ALA'S GLOBAL SURVIVOR POOLS</Item.Header>
            <Item.Meta><Icon name='angle right'/><i>ALA's pools can always be found on the <Link as='a' to='/leagues/allpools'><b>Pools</b></Link>page or <Link as='a' to='/league/alevelabove'><b>ALA's league homepage.</b></Link>They can also be accessed through the <b>Global ALA Pool</b>links in the <Icon name='basketball ball' style={{marginRight: 0}}/>NBA and <Icon name='baseball ball'/>MLB  menus.</i></Item.Meta>
            <Item.Description>
              A Level Above's annual NBA and MLB Survivor pools are open to anyone who would like to participate -- that means YOU. Jump in!
              <List style={{margin: 10}}>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content><b>The <Link as='a' to='/leagues/allpools'><b>Pools</b></Link> page shows a complete list of ALA Survivor Pools across all sports and leagues, including ALA's global pools.</b> Any pool with an <i>Open For Entry</i> status has not yet gotten underway and can be joined by eligible users (for ALA's global pools ... that's everyone). </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content><b>From the <Link as='a' to='/leagues/allpools'><b>Pools</b></Link> page, click on a pool's name to visit its homepage.</b> Once there, you can view that pool's rules and settings through its <i>How to Play</i> tab.</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content><b>To join the pool, click the sign <i>SIGN UP!</i> button (see image to left)</b>. Depending on the league's configuration, you may also be asked whether you would like to participate in the pool's Bonus Pool or purchase insurance. </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content>Want to make sure you're always in the loop when the next global ALA pool gets up and running? Go and join the global <Link as='a' to='/league/alevelabove'><b>ALA Survivor league</b></Link> and you'll get an email when new pools are launched. Pools can also be accessed and joined through that league homepage.</List.Content>
                </List.Item>
              </List>
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <div className='item-group-image-container'>
            <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/request_to_join_league.png' alt="Visit a league's homepage to request access if the league is private, or to immediately join if the league is public."/>
            <Item.Image size='tiny' src='https://alasurvivor.s3.us-west-2.amazonaws.com/league_invite_friends.png' alt="A league's homepage makes it easy to get your friends"/>
          </div>
          <Item.Content>
            <Item.Header><Icon name='group' style={{marginRight: '7px'}}/> JOIN A PRIVATE POOL</Item.Header>
            <Item.Meta><Icon name='angle right'/><i>See a complete list of public and private pools on the <Link as='a' to='/leagues/allpools'><b>Pools</b></Link> page.</i></Item.Meta>
            <Item.Description>
              Anyone can create and run their own public or private NBA or MLB Survivor Pool using the ALA Survivor Series software.
              <List style={{margin: 10}}>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content><b>Pools with a <Icon name='lock' style={{color: 'black'}}/>icon on the <Link as='a' to='/leagues/allpools'><b>Pools</b></Link> page are private</b>, and can only be joined by users who are members of that pool's league. </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content>To join a private pool, you can request access or ask a league's Commissioner to send you an invitation. <b>To request to join a league, visit that league's homepage</b> and click the <i>REQUEST TO JOIN</i> button (see image to left). </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='bullhorn' />
                  <List.Content>A league's Commissioner(s) can also invite friends through their league's homepage. A league's homepage displays a custom League Invite URL that they can copy and send to friends, and there's also a field that can be used to invite friends via email.</List.Content>
                </List.Item>
              </List>
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
        <div style={{width: '9%'}}></div>
          <Item.Content>
            <Item.Header><Icon name='question circle outline' style={{marginRight: '3px'}}/> ANY QUESTIONS?</Item.Header>
            <Item.Description>
              Get in touch with ALA support through The Commish's <a href='https://www.mikeduin.com/alevelabove/contact' target='_blank'>contact form</a> -- he'll get back to you promptly.
            </Item.Description>
          </Item.Content>
        </Item>
      </Item.Group>
    </SRLWrapper>
  )
}

export default JoinAPool;
