import React from 'react';
import { Popup } from 'semantic-ui-react';

export default function ContextPopup ({ trigger, content, header }) {
  return (
    <Popup
      trigger={trigger}
      className="context-popup"
    >
      <Popup.Header><span>{header ? header.toUpperCase() : "WHAT'S THIS?"}</span></Popup.Header>
      <Popup.Content>
        {content}
      </Popup.Content>
    </Popup>
  )
}
