import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect, useSelector } from 'react-redux';
import { Grid, Header, Icon, Table, Checkbox, Button } from 'semantic-ui-react';
import { userLeagues } from '../../data/selectors/user';
import { toast } from 'react-semantic-toasts';

function LeagueSettings ({ league, auth, userLeagues }) {
  const [newPoolEmails, setNewPoolEmails] = useState(userLeagues.find(ul => ul.league_id === league.info.id).email_new_pools);
  const [needToSave, setNeedToSave] = useState(false);
  const [initialSettings, updateInitialSettings] = useState([userLeagues.find(ul => ul.league_id === league.info.id).email_new_pools])

  async function saveUserSettings() {
    const settings = {
      userId: auth[0].id,
      leagueId: league.info.id, 
      settings: {
        newPoolEmails
      }
    }

    try {
      const res = await axios.post('/user/updateSettings', settings);
      const newSettings = res.data;
      updateInitialSettings([newSettings.email_new_pools]);
      toast({
        type: 'warning',
        icon: 'check circle',
        color: 'violet',
        title: 'League Settings Saved',
        description: 'Your settings for this league have been successfully updated.',
        animation: 'slide down',
        time: 3000
      });
    } catch (e) {
      console.log('error updating user settings was ', e);
    }
  }

  useEffect(() => {
    const currentSettings = [
      newPoolEmails
    ];
    if (JSON.stringify(currentSettings) !== JSON.stringify(initialSettings)) {
      setNeedToSave(true);
    } else {
      setNeedToSave(false);
    }
  }, [initialSettings, newPoolEmails])

  return (
    <>
      <Grid style={{marginBottom: 20, marginTop: 5}}>
        <Grid.Row className='page-header'>
          <Grid.Column width={13}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='cogs' circular size='large'/>
              </div>
              <Header.Content>
                Your League Settings
                <Header.Subheader>
                - Configure your notifications, alerts, and other settings for this league here. <br/>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={3} className='purple-text' textAlign='right' style={{fontSize: 20}} verticalAlign='middle'>
            <Button className='lg-btn' disabled={!needToSave} onClick={() => saveUserSettings()}>Save Settings</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell singleLine>Setting Type</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>On/Off</Table.HeaderCell>
                <Table.HeaderCell>Comments</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Email Notifications
                </Table.Cell>
                <Table.Cell singleLine><Header as='h4'>New League Pool Created</Header></Table.Cell>
                <Table.Cell>
                  <Checkbox 
                    toggle 
                    onChange={() => setNewPoolEmails(!newPoolEmails)}
                    checked={newPoolEmails}
                  />
                </Table.Cell>
                <Table.Cell>
                  Turn on to receive an email when your league's Commissioner creates a new pool.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
    </>
  )
}

function mapStateToProps ( state ) {
  return ({ 
    league: state.league, 
    auth: state.auth, 
    userLeagues: userLeagues(state)
  })
}

export default connect (mapStateToProps, null) (LeagueSettings);
