import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './authReducer';
import teamSchedules from './teamSchedulesReducer';
import scheduleWeeks from './scheduleWeeksReducer';
import activeGameWeek from './activeGameWeekReducer';
import authErrors from './authErrorReducer';
import seasonLength from './seasonLengthReducer';
import gameEntrants from './gameEntrantsReducer';
import alphTeams from './alphTeamsReducer';
import activePool from './activePoolReducer';
import liveWeek from './liveWeekReducer';
import deadlines from './deadlineReducer';
import poolSelections from './poolSelectionsReducer';
import teamResults from './teamResultsReducer';
import weeklyTotals from './weeklyTotalsReducer';
import feedEvents from './feedReducer';
import filteredEvents from './filterFeedReducer';
import teamDetails from './teamDetailsReducer';
import weeklyBonusTotals from './weeklyBonusReducer';
import gameBonusTotals from './gameBonusReducer';
import bonusEvents from './bonusEventsReducer';
import poolBonuses from './bonusesMappedReducer';
import commishPosts from './commishPostsReducer';
import poolHealth from './poolHealthReducer';
import allUsers from './allUsersReducer';
import payments from './paymentsReducer';
import seasonParams from './seasonParamsReducer';
import prizes from './prizeReducer';
import league from './leagueReducer';
import seasonParamsIndex from './seasonParamsIndexReducer';
import pendingSeasonParams from './seasonParamsPendingReducer';
import joinModalVis from './joinModalVisReducer';
import bonusOptions from './bonusOptionsReducer';
import emailResult from './emailResultReducer';
import allLeagues from './allLeaguesReducer';
import allPools from './allPoolsReducer';
import loading from './loadingReducer.js';
import leagueTransactions from './leagueTransactionsReducer.js';
import currentSeasons from './seasonCurrentReducer';
import pwReset from './pwResetReducer';

export default combineReducers({
  auth,
  form: formReducer,
  teamSchedules,
  scheduleWeeks,
  activeGameWeek,
  authErrors,
  seasonLength,
  gameEntrants,
  alphTeams,
  activePool,
  liveWeek,
  deadlines,
  poolSelections,
  teamResults,
  weeklyTotals,
  feedEvents,
  filteredEvents,
  teamDetails,
  weeklyBonusTotals,
  gameBonusTotals,
  bonusEvents,
  poolBonuses,
  commishPosts,
  poolHealth,
  allUsers,
  payments,
  seasonParams,
  prizes,
  league,
  seasonParamsIndex,
  pendingSeasonParams,
  joinModalVis,
  bonusOptions,
  emailResult,
  allLeagues,
  loading,
  allPools,
  leagueTransactions,
  currentSeasons,
  pwReset
});
