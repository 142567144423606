import axios from 'axios';
import history from '../history';
import moment from 'moment';
import emailjs from 'emailjs-com';
import _ from 'lodash';
import { FETCH_POOL_BONUSES, FETCH_USER, FETCH_TEAM_SCHEDULES, FETCH_SCHEDULE_WEEKS, FETCH_ACTIVE_WEEK, REG_ERROR, LOGIN_ERROR, LEAGUE_CREATE_ERROR, FETCH_SEASON_LENGTH, SET_GAME_ENTRANTS, SET_ALPH_TEAMS, LOAD_ACTIVE_POOL, SET_LIVE_WEEK, SET_DEADLINES, FETCH_POOL_SELECTIONS, FETCH_TEAM_RESULTS, SET_WEEKLY_TOTALS, SET_FEED, FILTER_FEED, FETCH_TEAM_DETAILS, WEEKLY_BONUS_TOTALS, GAME_BONUS_TOTALS, SET_BONUS_EVENTS, SET_COMMISH_POSTS, FETCH_POOL_HEALTH, SET_USERS, SET_PAYMENTS, FETCH_SEASON_PARAMS, SET_PRIZE_DATA, SET_LEAGUE, SEASON_PARAMS_INDEX, PENDING_SEASON_PARAMS, SET_JOIN_MODAL_VIS, GET_BONUS_OPTIONS, CLEAR_LEAGUE, CLEAR_POOL, EMAIL_RESULT, ALL_LEAGUES, SET_LOADING, ALL_POOLS, LEAGUE_TRANSACTIONS, SET_CURRENT_SEASONS, SET_PW_RESET, SET_PW_RESET_USER } from './types';

const fetchUserProfile = async username => {
  const gameCheck = await axios.get(`/user/userProfile/${username}`);
  return gameCheck.data;
}

const fetchUserTransact = async u => {
  const t = await axios.get(`/user/transactions/${u}`);
  return t.data;
}

export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/currentUser');
  const user = res.data;
  if (user.length > 0) {
    const data = await fetchUserProfile(user[0].username);
    const t = await fetchUserTransact(user[0].username);
    user[0].games = data.array;
    user[0].leagues = data.leagues;
    user[0].gameDetails = data.games;
    user[0].transactions = t;
    user[0].userSeasons = data.userSeasons;
    user[0].lgRequests = data.lgRequests;
  }
  dispatch({ type: FETCH_USER, payload: user });
  dispatch({ type: SET_LOADING, payload: false });
}

export const fetchTeamSchedules = (g, s, t, reload = false) => async dispatch => {
  const res = await axios.get(`/api/teamSchedules/${g}/${s}/${t}`);
  const schedule = res.data;
  dispatch({ type: FETCH_TEAM_SCHEDULES, payload: schedule });
}

export const allSeasonParams = () => async dispatch => {
  const allSeasonParams = await axios.get('/api/seasonParams/index');
  const pendingSeasonParams = allSeasonParams.data.filter(sp => !sp.final && moment(sp.end).subtract(7, 'days').isAfter(moment()));
  const games = allSeasonParams.data.map(sp => sp.game);
  const currentSeasonObj = {};
  games.forEach(game => {
    currentSeasonObj[game] = Math.max.apply(Math, 
      allSeasonParams.data
        .filter(sp => sp.active)
        .map(sp => sp.season))
  });
  dispatch({ type: SET_CURRENT_SEASONS, payload: currentSeasonObj});
  
  dispatch({ type: SEASON_PARAMS_INDEX, payload: allSeasonParams.data });
  if (pendingSeasonParams.length) {
    pendingSeasonParams.forEach((s, idx) => {
      if (moment(s.start).isAfter(moment())) {
        let item = pendingSeasonParams.splice(idx, 1);
        pendingSeasonParams.push(item[0]);
      }
    })
    dispatch({ type: PENDING_SEASON_PARAMS, payload: pendingSeasonParams});
  } else {
    dispatch({ type: PENDING_SEASON_PARAMS, payload: false});
  }
}

export const fetchSeasonParams = (g, s, t) => async dispatch => {
  const seasonParams = await axios.get(`/api/seasonParams/${g}/${s}/${t}`);
  const gameWkDashedArrays = JSON.parse(seasonParams.data.dashed_date_arrays).slice(1); // this removes the zero-index/"game week 0"
  const deadlines = JSON.parse(seasonParams.data.deadlines);

  // schedWeeks here determines whether full schedule is loaded
  // TESTING DISABLING THIS
  // const schedWeeks = gameWkDashedArrays.length === deadlines.length ? gameWkDashedArrays : gameWkDashedArrays.slice(0, ((gameWkDashedArrays.length - deadlines.length) + 1));
  
  dispatch({ type: FETCH_SCHEDULE_WEEKS, payload: gameWkDashedArrays });
  dispatch({ type: FETCH_SEASON_PARAMS, payload: seasonParams.data});
}


export const fetchActiveGameWeek = (g, s, t) => async dispatch => {
  const activeWeek = await axios.get(`/api/activeWeek/${g}/${s}/${t}`);
  dispatch({ type: FETCH_ACTIVE_WEEK, payload: activeWeek.data.activeGameWeek });
}

export const loadActivePool = (g, s, l, idx, t = 'reg') => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true })
  const entrants = await axios.get(`/api/gameEntrants/${g}/${s}/${l}/${idx}/${t}`);
  const pool = await axios.get(`/api/loadPool/${g}/${s}/${l}/${idx}/${t}`);
  dispatch(fetchActiveGameWeek(g, s, t));
  dispatch(fetchFeed(pool.data[0].league_id, pool.data[0].id));
  dispatch(fetchBonusEvents(pool.data[0].id));
  dispatch(fetchPoolBonuses(pool.data[0].id));
  dispatch(fetchBonusTallys(g, s, t, pool.data[0].id));
  dispatch(fetchPoolSelections(g, s, l, idx));
  dispatch(pullLeagueTransactions(l));
  let liveWeek = 0;
  let i = 0;
  while (moment().isAfter(JSON.parse(pool.data[0].deadlines)[i])) {
    liveWeek++;
    i++;
  };
  if (g.trim() === 'nba' && parseInt(s) === 2019) {
    liveWeek = 21;
  }
  const activeEntrants = entrants.data.filter(e => e.status !== 0);
  const rebuys = activeEntrants.reduce((sum, e) => sum + e.rebuys, 0);
  const insurance = activeEntrants.reduce((sum, e) => sum + e.insurance, 0);
  const allocated = entrants.data.reduce((sum, e) => sum + e.prizes, 0);
  let prizeData = {
    entrants: activeEntrants.length,
    rebuys,
    prize_pool: Math.round(((pool.data[0].buyin * activeEntrants.length) + insurance + (pool.data[0].rebuy_price * rebuys))*(1-pool.data[0].ala_pct)),
    allocated
  };
  dispatch({ type: SET_PRIZE_DATA, payload: prizeData });
  dispatch({ type: SET_DEADLINES, payload: JSON.parse(pool.data[0].deadlines) });
  dispatch({ type: SET_LIVE_WEEK, payload: liveWeek });
  dispatch({ type: LOAD_ACTIVE_POOL, payload: pool.data[0] });
  dispatch({ type: SET_GAME_ENTRANTS, payload: entrants.data });
  dispatch({ type: FETCH_SEASON_LENGTH, payload: JSON.parse(pool.data[0].deadlines).length});
}

export const fetchBonusEvents = ls => async dispatch => {
  let b = await axios.get(`/api/bonus/events/${ls}`);
  dispatch({ type: SET_BONUS_EVENTS, payload: b.data });
}

export const fetchPoolBonuses = ls => async dispatch => {
  let b = await axios.get(`/api/bonus/mapped/${ls}`);
  dispatch({ type: FETCH_POOL_BONUSES, payload: b.data });
}

export const fetchBonusTallys = (g, s, t, ls) => async dispatch => {
  let totals = await axios.get(`/api/bonus/tallys/${g}/${s}/${t}/${ls}`);
  dispatch({ type: WEEKLY_BONUS_TOTALS, payload: totals.data.weekTotals });
  dispatch({ type: GAME_BONUS_TOTALS, payload: totals.data.gameTotals });
}

export const registerUser = formValues => async dispatch => {
  axios.post('/auth/register', formValues)
    .then(async res => {
      dispatch(fetchUser());
      history.push('/');
    })
    .catch(err => {
      dispatch({ type: REG_ERROR, payload: err.response.data });
    });
}

export const forgotPw = formValues => async dispatch => {
  axios.post('/auth/forgotPw', formValues)
    .then(async res => {
      console.log('forgot pw response is ', res.data);
      dispatch({ type: SET_PW_RESET, payload: {success: true, message: res.data.message}});
    })
    .catch(err => {
      console.log('err in forgot pw is ', err);
      dispatch({ type: SET_PW_RESET, payload: {success: false, message: err.response.data.message} })
    })
}

export const fetchUserByResetToken = token => async dispatch => {
  console.log('token is ', token);
  axios.post('/auth/fetchUserByResetToken', {token})
    .then(async res => {
      console.log('finalize pw reset response is ', res.data);
      dispatch({ type: SET_PW_RESET_USER, payload: {userSuccess: true, user: res.data.user}})
    })
    .catch(err => {
      console.log('err in finalize pw reset is ', err);
      dispatch({ type: SET_PW_RESET_USER, payload: {userSuccess: false, user: null}})
    })
}

export const finalizePasswordReset = formValues => async dispatch => {
  axios.post('/auth/finalizePasswordReset', formValues)
    .then(async res => {
      console.log('finalize pw reset response is ', res.data);
      dispatch({ type: SET_PW_RESET, payload: {resetConfirm: true, message: res.data.message, userSuccess: true}})
      setTimeout(() => history.push('/'), 5000);
    })
    .catch(err => {
      console.log('err in finalize pw reset is ', err);
      dispatch({ type: SET_PW_RESET, payload: {resetConfirm: false, message: err.response.data.message} })
    })
}

export const clearPwErrors = () => async dispatch => {
  dispatch({ type: SET_PW_RESET, payload: {}})
}

export const loginUser = formValues => async (dispatch, getState) => {
  axios.post('/auth/login', formValues)
    .then(async res => {
      dispatch(fetchUser());
      history.push({
        pathname: formValues.source,
        state: {modal: false}
      });
    })
    .catch(err => {
      console.log('err is ', err);
      dispatch({ type: LOGIN_ERROR, payload: err.response.data })
    })
}

export const registerForGame = info => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true })
  axios.post('/user/regForGame', info)
    .then(async res => {
      const {game, season, league_abb, pool_idx, season_type, league_id, league_season} = res.data;
      dispatch(fetchUser());
      dispatch(loadActivePool(game, season, league_abb, pool_idx, season_type));
      dispatch(fetchBonusTallys(game, season, season_type, league_season));
      dispatch(filterFeed(null, 0, null, league_id, league_season));
      history.push(`/${game}/${league_abb}/${season}/${season_type}/${pool_idx}`);
    })
    .catch(err => {
      console.log('err received')
    })
}

export const makeUserPick = data => async dispatch => {
  axios.post('/user/makeUserPick', data)
    .then(res => {
      dispatch(fetchUser());
    })
    .catch(err => {
      console.log(err)
    })
  }

export const fetchPoolSelections = (g, s, l, idx, t = 'reg') => async dispatch => {
  const poolPicks = await axios.get(`/api/selections/all/${g}/${s}/${l}/${idx}/${t}`);
  dispatch({ type: FETCH_POOL_SELECTIONS, payload: poolPicks.data });
  const maxWeek = poolPicks.data[0] ? poolPicks.data[0].selections[0].length : g === 'mlb' ? 9 : 26;

  let weeks = [];
  await poolPicks.data.forEach(user => {
    for (let i = 0; i<maxWeek; i++) {
      if (user.selections[0][i].length > 1) {
        if (!weeks[i]) {
          weeks.push({
            picks: {[user.selections[0][i]]: 1},
            total: 1
          });
        } else if (!weeks[i]['picks'][user.selections[0][i]]) {
          weeks[i]['picks'][user.selections[0][i]] = 1;
          weeks[i].total++;
        } else {
          weeks[i]['picks'][user.selections[0][i]]++;
          weeks[i].total++;
        }
      } else {
        if (!weeks[i]) {
          weeks.push({
            picks: {},
            total: 0
          });
        }
      }
    }
  })
  await weeks.forEach(week => {
    week.picks = _.fromPairs(_.sortBy(_.toPairs(week.picks), 1).reverse());
  })
  dispatch({ type: SET_WEEKLY_TOTALS, payload: weeks});
}

export const fetchTeamResults = (g, s, t) => async dispatch => {
  const results = await axios.get(`/api/results/${g}/${s}/${t}`);
  dispatch({ type: FETCH_TEAM_RESULTS, payload: results.data });
}

export const toggleBonusStatus = data => async dispatch => {
  axios.post('/user/toggleBonus', data)
    .then(async res => {
      dispatch(fetchUser());
      dispatch(fetchFeed(res.data.league_id, res.data.league_season))
    })
    .catch(err => {
      console.log(err);
    });
}

export const toggleAutoRebuys = data => async dispatch => {
  axios.post('/user/toggleRebuys', data)
    .then(async res => {
      dispatch(fetchUser());
      dispatch(fetchFeed(res.data.league_id, res.data.league_season))
    })
    .catch(err => {
      console.log(err);
    });
}

export const fetchFeed = (l=null, ls=null) => async dispatch => {
  let res = await axios.get(`/api/feed/${l}/${ls}`);
  dispatch({ type: SET_FEED, payload: res.data });
}

export const filterFeed = (events=null, filter=0, username=null, l=null, ls=null) => async dispatch => {
  // filters
  // 0 = all non-user-pick or user-feed-only events
  // 1 = user-specific events
  // 2 = performance bonuses
  // 3 = survival events?
  const types = await axios.get('/admin/eventTypes');
  const userPickTypes = types.data
    .filter(type => type.user_feed_only)
    .map(type => type.type_number); // used with filter 0 below, for events that should only be shown on a user's profile page and not the main league feed page

  let filtered = [];
  if (events !== null) {
    if (filter === 0) {
      filtered = events.filter(ev => userPickTypes.indexOf(ev.type) === -1)
    } else if (filter === 1) {
      filtered = events.filter(ev => ev.username === username);
    } else if (filter === 2) {
      filtered = events.filter(ev => ev.type === 7);
    }
  } else {
    let res = await axios.get(`/api/feed/${l}/${ls}`);
    filtered = res.data.filter(ev => userPickTypes.indexOf(ev.type) === -1);
  }

  dispatch({ type: FILTER_FEED, payload: filtered });
}

export const fetchTeamDetails = g => async dispatch => {
  let details = await axios.get(`/api/teams/details/${g}`);
  dispatch({ type: SET_ALPH_TEAMS, payload: details.data.abbs });
  dispatch({ type: FETCH_TEAM_DETAILS, payload: details.data.data });
}

export const userRebuy = data => async dispatch => {
  axios.post('/user/rebuy', data)
  .then(async ret => {
    const {game, league_abb, league_id, season, season_type, pool_idx, league_season} = ret.data;
    dispatch(fetchUser());
    dispatch(fetchFeed(league_id, league_season));
    history.push(`/${game}/${league_abb}/${season}/${season_type}/${pool_idx}`);
  })
  .catch(err => {
    console.log(err);
  });
}

export const addCommishPost = data => async dispatch => {
  axios.post('/admin/commishPosts', data)
  .then(async ret => {
    window.location.reload();
  })
  .catch(err => {
    console.log(err);
  })
}

export const fetchCommishPosts = ls => async dispatch => {
  let e = await axios.get(`/admin/commishPosts/${ls}`);
  dispatch({ type: SET_COMMISH_POSTS, payload: e.data});
}

export const fetchPoolHealth = ls => async dispatch => {
  let h = await axios.get(`/api/poolHealth/${ls}`);
  dispatch({ type: FETCH_POOL_HEALTH, payload: h.data});
}

export const fetchUsers = () => async dispatch => {
  let u = await axios.get('/admin/fetchUsers');
  dispatch({ type: SET_USERS, payload: u.data});
}

export const addPayment = data => async dispatch => {
  axios.post('/admin/addPayment', data)
  .then(async ret => {
    window.location.reload();
  })
  .catch(err => {
    console.log(err);
  })
}

export const fetchPayments = (g, s, l, idx, type) => async dispatch => {
  let p = await axios.get(`/api/payments/${g}/${s}/${l}/${idx}/${type}`);
  dispatch({ type: SET_PAYMENTS, payload: p.data})
}

export const editUserLives = data => async dispatch => {
  axios.post('/admin/editUserLives', data)
  .then(async ret => {
    window.location.reload();
  })
  .catch(err => {
    console.log(err);
  })
}

export const processPrizeSplit = data => async dispatch => {
  axios.post('/admin/processPrizeSplit', data)
  .then(async ret => {
    window.location.reload();
  })
  .catch(err => {
    console.log(err);
  })
}

export const processLeagueChamps = data => async dispatch => {
  axios.post('/admin/processLeagueChamps', data)
  .then(async ret => {
    window.location.reload();
  })
  .catch(err => {
    console.log(err);
  })
}

export const fetchAllPools = () => async dispatch => {
  const pull = await axios.get('/leagueRoutes/allPools');
  dispatch({ type: ALL_POOLS, payload: pull.data})
}

export const fetchAllLeagues = () => async dispatch => {
  const pull = await axios.get('/leagueRoutes/allLeagues');
  dispatch({ type: ALL_LEAGUES, payload: pull.data})
}

export const createLeague = formValues => async dispatch => {
  axios.post('/leagueRoutes/createLeague', formValues)
    .then(async res => {
      history.push(`/league/${res.data.league.abb}`);
    })
    .catch(err => {
      dispatch({ type: LEAGUE_CREATE_ERROR, payload: err.response.data });
    });
}

export const fetchLeague = (type, search) => async dispatch => {
  // type = code or abb
  // search = value (invite code string or league abb string)
  axios.get(`/leagueRoutes/fetchLeague/${type}/${search}`)
    .then(async res => {
      dispatch({ type: SET_LEAGUE, payload: res.data})
    })
    .catch(err => {
      // history.push('/league/error/notfound');
      console.log('league not found');
    })
}

export const requestLeagueAccess = data => async dispatch => {
  axios.post('/leagueRoutes/requestAccess', data)
    .then(async res => {
      dispatch(fetchUser());
    })
    .catch(err => {
      console.log('err requesting league access');
    });
}

export const decideLeagueAccess = data => async dispatch => {
  axios.post('/leagueRoutes/decideAccess', data)
    .then(async res => {
      dispatch(fetchLeague('abb', res.data.league_abb));
    })
    .catch(err => {
      console.log('err deciding league access');
    });
}

export const acceptInvite = data => async dispatch => {
  axios.post('/leagueRoutes/acceptInvite', data)
    .then(async res => {
      dispatch(clearLeague());
      dispatch(clearPool());
      history.push(`/league/${res.data.leagueMember.league_abb}`);
    })
    .catch(err => {
      console.log('error joining league');
    });
}

export const emailLeagueInvite = data => async dispatch => {
  const { user_email, user_full_name, league_name, league_invite_url } = data;
  let templateParams = {
    user_email,
    user_full_name,
    league_name,
    league_invite_url
  };
  emailjs.send('service_gx544jx', 'template_heqnpe7', templateParams, 'user_zrkVTjTi90AAyuQPoQHr0')
    .then(function(response) {
       dispatch({ type: EMAIL_RESULT, payload: true})
       setTimeout(() => dispatch({ type: EMAIL_RESULT, payload: null}), 4000);
    }, function(error) {
       dispatch({ type: EMAIL_RESULT, payload: false})
       setTimeout(() => dispatch({ type: EMAIL_RESULT, payload: null}), 4000);
    });
}

export const activateLeaguePool = data => async dispatch => {
  axios.post('/leagueRoutes/activatePool', data)
    .then(async res => {
      const {pool} = res.data;
      history.push(`/${pool.game}/${pool.league_abb}/${pool.season}/${pool.season_type}/${pool.pool_idx}`);
    })
    .catch(err => {
      console.log('error activating pool');
    });
}

export const setJoinModalVis = bool => async dispatch => {
  dispatch({ type: SET_JOIN_MODAL_VIS, payload: bool });
}

export const getBonusOptions = (g = null) => async dispatch => {
  const options = await axios.get(`/api/bonus/options/${g}`);
  dispatch({ type: GET_BONUS_OPTIONS, payload: options.data });
}

export const clearLeague = () => async dispatch => {
  dispatch({ type: CLEAR_LEAGUE })
}

export const clearPool = () => async dispatch => {
  dispatch({ type: CLEAR_POOL })
}

export const buyInsurance = data => async dispatch => {
  axios.post('/user/buyInsurance', data)
    .then(async res => {
      dispatch(fetchUser());
      dispatch(fetchFeed(res.data.league_id, res.data.league_season));
    })
    .catch(err => {
      console.log('error activating pool');
    });
}

export const pullLeagueTransactions = l => async dispatch => {
  axios.get(`/leagueRoutes/transactions/${l}`) 
    .then(async res => {
      dispatch({ type: LEAGUE_TRANSACTIONS, payload: res.data})
    })
    .catch(err => {
      console.log('error pulling league transactions');
    });
}

export const toggleUserStatus = data => async dispatch => {
  axios.post('/admin/toggleUserStatus', data)
    .then(async res => {
      window.location.reload();
    })
    .catch(err => {
      console.log('error toggling user status');
    });
}

