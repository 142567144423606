import React from 'react';
import { Icon, Responsive, Button, Grid, Divider, Header, Image, Dropdown, Menu } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './styles/landing.scss';
import ALAHeader from './Header';
import AuthModal from './AuthModal';
import logo from '../images/landing-logo.png';
import { Icon as Iconify } from '@iconify/react';
import scoreboardIcon from '@iconify-icons/mdi/scoreboard';

function renderAuthStatus(auth) {
  switch (auth) {
    case null:
      return;
    case false:
      return (
        <AuthModal />
      )
    default:
      return (
        <>
          <Dropdown text={auth[0].username} simple item>
            <Menu>
              <Menu.Item
               as={NavLink}
               to={`/profile`}
               style={{color: 'white !important'}}
              >
                Profile
              </Menu.Item>
              <Menu.Item
               href="/api/logout"
               style={{color: 'white !important'}}
              >
                Logout
              </Menu.Item>
            </Menu>
          </Dropdown>
        </>
      )
  }
}

const Landing = ({ auth }) => {
  return (
    <>
    <div className="container landing-bg"></div>
      <div id="landing-login">
        {renderAuthStatus(auth)}
      </div>
      <Grid className='landing-grid'>
        <Grid.Column className='splash-left-col'>
          <Grid.Row className='splash-header top'>
            ALA SURVIVOR SERIES
          </Grid.Row>
          <Grid.Row className='splash-menu'>
            <Link className='menu-item' to='/getstarted'><Icon name='home' style={{marginRight: 4}}/>START</Link>
            <Link className='menu-item' to='/nba/alevelabove/2024/reg/0'><Icon name='basketball ball'/>NBA</Link>
            <Link className='menu-item' to='/mlb/alevelabove/2024/reg/0'><Icon name='baseball ball'/>MLB</Link>
            <Link className='menu-item' to='/leagues/find'><Icon name='users' style={{marginRight: 5}}/>LEAGUES</Link>
          </Grid.Row>
          <Grid.Row className='splash-header'>
            LEVEL UP YOUR SURVIVOR POOL
          </Grid.Row>
          <Grid.Row>
            <img src={logo} className='splash-img'/>
          </Grid.Row>
          <Grid.Row className='splash-highlights' >
            <Grid columns={3} style={{marginBottom: 0}}>
              <Grid.Column>
                <Header as='h2' icon>
                <div>
                  <Iconify className="iconify-icon" icon={scoreboardIcon}/>
                </div>
                  <div className="lead">NBA/MLB Survivor</div>
                  <Header.Subheader>
                    <span> Pick one team per week. If your team goes .500 or better, you advance to the next week. </span>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h2' icon>
                  <Icon name='chart line'/>
                  <div className="lead">Serious Software</div>
                  <Header.Subheader>
                    <span>ALA's Survivor engine is loaded with features and functionality not offered by major pool providers.</span>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header as='h2' icon>
                  <Icon name='group'/>
                  <div className="lead">Run Your Pool.<br/> It's Free.</div>
                  <Header.Subheader>
                    <span>Create a League and have a home base to run your own pools, view all-time league rankings, and more. </span>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid.Column>

      </Grid>
    </>
  )
}


function mapStateToProps({ auth }) {
  return { auth }
}

export default connect(mapStateToProps, {})(Landing);
