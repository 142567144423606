import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

const TableHeaders = props => {
  if (props.view === 'Week' || props.view === 'Bonus Pool') {
    let headers = [];
    for (let i=0; i<props.weeks; i++) {
      headers.push(
        <Table.HeaderCell style={{textAlign: 'center'}} key={i}> {i + 1} </Table.HeaderCell>
      );
    };
    return headers;
  } else if (props.view === 'Team') {
    return props.teams.map(team =>
      <Table.HeaderCell style={{textAlign: 'center'}} key={team}> {team} </Table.HeaderCell>
    )
  } else if (props.view === 'By Day') {
    return props.days.map(day =>
      <Table.HeaderCell style={{textAlign: 'center'}} key={day}> {moment(day).format("ddd M/D").toUpperCase()} </Table.HeaderCell>
    )
  } else {
    console.log('Invalid view variable supplied to headers!');
    return
  }
}

export default TableHeaders;
