import React from 'react';
import { Table, Image } from 'semantic-ui-react';
import TableHeaders from './TableHeaders';
import RenderLives from './RenderLives';
import "../styles/usage-grid.scss";
import logos from '../../modules/logos';
import "../styles/selections.scss";
import moment from 'moment';

const formatDollars = d => d >= 0 ? `$${(d).toFixed(2)}` : `-$${(Math.abs(d)).toFixed(2)}`;
const userStyle = (entrant, activeUser) => {
  if (entrant === activeUser) {
    return 'activeUser'
  }
}

const ScrollableEntrantTable = props => {
  const activeUser = props.activeUser ? props.activeUser : null;
  const renderEntrants = entrants => {
    return entrants.map(entrant => {
      return (
        <Table.Row key={entrant.id}>
          <Table.Cell className={`${userStyle(`${entrant.username}`, `${activeUser}`)}`}> {entrant.username} </Table.Cell>
          <Table.Cell className={`lives-td ${userStyle(`${entrant.username}`, `${activeUser}`)}`}> <RenderLives lives={entrant.lives} /> </Table.Cell>
          <Table.Cell className={`${userStyle(`${entrant.username}`, `${activeUser}`)}`}> {entrant.bonus ? 'YES' : 'NO'} </Table.Cell>
          <Table.Cell className={`lives-td ${userStyle(`${entrant.username}`, `${activeUser}`)}`}> {formatDollars(props.bonusTotals[entrant.username])}</Table.Cell>
        </Table.Row>
      )
    })
  }

  const renderRows = entrants => {
    return entrants.map(entrant => {
      return (
        <Table.Row key={entrant.id}>
          {renderSelections(entrant.selections[0], entrant.username)}
        </Table.Row>
      )
    })
  }

  const renderSelections = (selections, username) => {
    const activeUser = props.activeUser ? props.activeUser : null;
    const {liveWeek, alphTeams, activeGame, activeWeek, seasonParams, teamResults, view, teams, weeklyBonusTotals} = props;
    if (view === "Week") {
      return selections.map((selection, idx) => {
        if (selections.indexOf(selection) <= (liveWeek-1) && alphTeams.indexOf(selection) !== -1) {
          return (
            <Table.Cell key={idx} className={`logo-cell standing-cell-${activeGame}
              ${(idx < (activeWeek-1) || moment().isAfter(moment(seasonParams.end).add(1, 'days')))
                ?
                ((activeGame === 'mlb' && teamResults[selection][idx][0] === 0 && teamResults[selection][idx][1]) > 5) ? 
                  'shot-moon' : 
                  (
                    ((teamResults[selection][idx][0] < teamResults[selection][idx][1]) ||
                    (teamResults[selection][idx][1] === 0 && teamResults[selection][idx][0] === 0)) ? 
                    'losing' : 'winning'
                  )
                :
                'null'
                }`}
            > <Image className={`${activeGame}-standing-box ${activeGame}-${selection}-logo`} src={logos[`${activeGame}_${selection}`]} /> </Table.Cell>
          )
        } else if (selection === 'xxx') {
          return (
            <Table.Cell key={idx} className='losing'> - </Table.Cell>
          )
        } else {
          return (
            <Table.Cell key={idx} className={`${userStyle(`${username}`, `${activeUser}`)}`}> - </Table.Cell>
          )
        }
      })
    } else if (view === "Team") {
      return teams.map(team => {
        if (selections.indexOf(team) !== -1 && selections.indexOf(team) <= (liveWeek-1)) {
          return (
            <Table.Cell key={team} className='week-cell'> {selections.indexOf(team) + 1} </Table.Cell>
          )
        } else {
          return (
            <Table.Cell className={`${userStyle(`${username}`, `${activeUser}`)}`}> - </Table.Cell>
          )
        }
      })
    } else if (view === "Bonus Pool") {
      return weeklyBonusTotals[username].map((week, idx) => {
        if (idx < liveWeek) {
          return (
            <Table.Cell className={`${userStyle(`${username}`, `${activeUser}`)}`}> {formatDollars(week)} </Table.Cell>
          )
        } else {
          return (
            <Table.Cell className={`${userStyle(`${username}`, `${activeUser}`)}`}> - </Table.Cell>
          )
        }
      })
    }
  }

  return (
    <div className="master-table-container">
      <Table className="usage-entrant-table" attached="right">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> Entrant </Table.HeaderCell>
            <Table.HeaderCell> Lives </Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='center'> Bonus Pool? </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderEntrants(props.entrants)}
        </Table.Body>
      </Table>
      <div className="usage-table-container">
      <Table className="usage-data-table" attached="left">
        <Table.Header>
          <Table.Row>
            <TableHeaders
              view={props.view}
              weeks={props.weeks}
              schedule={props.schedule}
              teams={props.teams}
            />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderRows(props.entrants)}
        </Table.Body>
      </Table>
      </div>
    </div>
  )
}

export default ScrollableEntrantTable;
