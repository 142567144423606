import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Grid, Button, Header, Icon, Popup } from 'semantic-ui-react';
import { decideLeagueAccess } from './../../actions/index';
import './../styles/commish-tools.scss';
import './../styles/global.scss';

function ManageLgEntrants ({ league, decideLeagueAccess }) {
  const [requests, setRequests] = useState(league.requests);

  useEffect(() => {
    setRequests(league.requests);
  }, [league])

  function memberVerdict (application, status) {
    decideLeagueAccess({
      application,
      league_name: league.info.name,
      status
    });
  }

  function renderRequests(r) {
    return r.map(req => {
      return (
        <Table.Row key={req.id}>
          <Table.Cell> {req.username} </Table.Cell>
          <Table.Cell> {req.full_name} </Table.Cell>
          <Table.Cell> {req.email} </Table.Cell>
          <Table.Cell> {moment(req.created_at).format('MM.DD.YY h:MM a')} </Table.Cell>
          <Table.Cell>
            {req.status === 2 ?
              <Button.Group>
                <Button positive onClick={() => memberVerdict(req, 1)}>Approve</Button>
                <Button.Or />
                <Button negative onClick={() => memberVerdict(req, 3)}>Reject</Button>
              </Button.Group>
            :
              <>
                {req.status === 1 ?
                  <Button positive disabled>APPROVED</Button>
                :
                  <>
                    <Button negative disabled>REJECTED</Button>
                      <Popup content='You previously denied this entrant access to your league. Click this button to re-open their application.'
                        trigger={
                          <Button icon onClick={() => memberVerdict(req, 2)}>
                            <Icon name='redo' />
                          </Button>}
                      />
                  </>
                }
              </>
            }

          </Table.Cell>
        </Table.Row>
      )
    })
  }

  return (
    <Grid className='commish-tools-cont'>
      <Grid.Row columns={1}>
        <Grid.Column>
          <div className="user-pg-header">Requests to Join Your League</div>
          <Header size='medium'>Use this page to approve or deny requests to join your league.</Header>
          {league.requests.length ?
            <Table id="manage-entrants">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Username</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Submitted At</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {renderRequests(requests)}
              </Table.Body>
            </Table>
            :
            <div> You have not yet received any requests to join your league. </div>
          }

        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

function mapStateToProps ({ league }) {
  return { league }
}

export default connect (mapStateToProps, {decideLeagueAccess}) (ManageLgEntrants)
