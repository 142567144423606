import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Header, Icon } from 'semantic-ui-react'
import { userRebuy } from '../actions';

class RebuyModal extends Component {
  state = { modalOpen: false };
  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  confirmRebuy () {
    this.props.userRebuy({
      userSeason: this.props.userSeason.id,
      game: this.props.activePool.game,
      season: this.props.activePool.season,
      alias: this.props.activePool.game_alias
    });
    this.handleClose();
  };

  render () {
    return (
      <Modal
        open={this.state.modalOpen}
        trigger={<Button className='buy-insurance' style={{marginRight: '20px'}} onClick={this.handleOpen}>REBUY</Button>} size='small' centered={true}>
        <Header as='h2'>
          Confirm Rebuy
        </Header>
        <Modal.Content>
          You lost a life last week and have {this.props.userSeason.lives} lives remaining. Please confirm that you would like to rebuy below for an additional ${this.props.activePool.rebuy_price}. If you have not elected to rebuy by the time this week's selections lock, your entry will be eliminated.
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.handleClose}>
            Back
          </Button>
          <Button primary onClick={() => this.confirmRebuy()}>
            <Icon name='check circle' />
              Confirm Rebuy
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

function mapStateToProps ({ auth, activePool }) {
  return { auth, activePool }
}

export default connect (mapStateToProps, { userRebuy })(RebuyModal)
