import { createSelector } from 'reselect';

const auth = state => state.auth[0];

export const userLeagues = createSelector(
  auth,
  data => data.leagues
);

export const userLeagueSettingsUpdated = createSelector(
  auth,
  data => data.userLeagueSettingsUpdated
)