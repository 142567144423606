import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Statistic, Icon, Popup, Grid, Loader } from 'semantic-ui-react';
import ScrollableEntrantTable from './table_helpers/ScrollableEntrantTable';
import "./styles/usage-grid.scss";

class Standings extends Component {
  state = {
    render: false,
    weeks: null,
    sortMethod: 'Lives',
    sortIcon: 'user outline',
    sortOptions: [
      { key: 'lives', value: 'Lives', text: 'Lives', icon: 'user outline' },
      { key: 'user', value: 'Username', text: 'Username', icon: 'sort alphabet down' },
      { key: 'bonus', value: 'Bonus $', text: 'Bonus $', icon: 'rocket'}
    ],
    view: 'Week',
    viewIcon: 'calendar',
    viewOptions: [
      { key: 'week', value: 'Week', text: 'Week', icon: 'calendar' },
      { key: 'team', value: 'Team', text: 'Team', icon: 'users' },
      { key: 'bonus', value: 'Bonus Pool', text: 'Bonus Pool', icon: 'rocket'}
    ]
  };

  componentDidMount(){
    const { scheduleWeeks, seasonParams } = this.props;
    let weekVal = scheduleWeeks.length;
    if (JSON.parse(seasonParams.dashed_date_arrays).length - 1 > weekVal) {
      weekVal = JSON.parse(seasonParams.dashed_date_arrays).length - 1; // subtract 1 here to account for zero-index not being game week
    }
    this.setState({ weeks: weekVal, render: true })
  }

  changeView = (e, {value}) => {
    let obj = this.state.viewOptions.find(option => option.value === value);
    this.setState({ view: value, viewIcon: obj.icon });
  };

  changeSort = (e, { value }) => {
    const { gameEntrants, gameBonusTotals } = this.props;
    let obj = this.state.sortOptions.find(option => option.value === value);
    if (value === 'Lives') {
      gameEntrants.sort((a, b) => {
        if (b.lives > a.lives) {return 1};
        if (b.lives < a.lives) {return -1};
        if (b.weeks_alive > a.weeks_alive) {return 1};
        if (b.weeks_alive < a.weeks_alive) {return -1};
      });
    } else if (value === 'Username') {
      gameEntrants.sort((a, b) => a.username.localeCompare(b.username));
    } else if (value === 'Bonus $') {
      gameEntrants.sort((a, b) => gameBonusTotals[b.username] - gameBonusTotals[a.username]);
    }
    this.setState({ sortMethod: value, sortIcon: obj.icon });
  };

  render () {
    const { gameEntrants, bonusEvents, alphTeams, liveWeek, activeGameWeek, gameBonusTotals, teamResults, weeklyBonusTotals, auth, activePool, seasonParams, prizes, league } = this.props;
    const entrants = gameEntrants.filter(e => e.status !== 0).length;
    const alive = gameEntrants.filter(e => !e.extinct).length;
    const bonus = gameEntrants.filter(e => e.bonus).length;
    if (this.state.render && gameBonusTotals !== null && this.activePool !== null && gameEntrants !== null && bonusEvents !== null) {
      const bonusDoll = bonusEvents.filter(e => e.dollars_total > 0).reduce((sum, e) => sum + e.dollars_total, 0);
      return (
        <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className={`page-header ${activePool.game}`}>
            <Grid.Column width={7}>
              <Statistic.Group className="flex-center">
                <Statistic color='red'>
                  <Statistic.Value>{entrants} <Icon name='users'/></Statistic.Value>
                  <Statistic.Label>Entrants</Statistic.Label>
                </Statistic>
                <Statistic color='orange'>
                  <Statistic.Value>{alive} <Icon name='heartbeat'/></Statistic.Value>
                  <Statistic.Label>Still Alive</Statistic.Label>
                </Statistic>
                <Statistic color='yellow'>
                  <Statistic.Value>{bonus} <Icon name='rocket'/></Statistic.Value>
                  <Statistic.Label>In Bonus Pool</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
            <Grid.Column width={2} className="flex-center by-numbers">
              <Icon name='angle double left' className='pointer-arrow'/>
              <span>SURVIVAL SUMMARY</span>
              <Icon name='angle double right' className='pointer-arrow'/>
            </Grid.Column>
            <Grid.Column width={7}>
              <Statistic.Group className="flex-center">
                <Statistic color='olive'>
                  <Statistic.Value>{prizes.rebuys}</Statistic.Value>
                  <Statistic.Label>Re-buys</Statistic.Label>
                </Statistic>
                <Statistic color='brown'>
                  <Statistic.Value>${prizes.prize_pool}</Statistic.Value>
                  {league.info.abb === 'alevelabove' ? <Popup content="5% is taken from prize pool to cover server and website-related costs."
                    trigger={<Statistic.Label>Main Prize Pool*</Statistic.Label>} /> : <Statistic.Label>Main Prize Pool</Statistic.Label>}
                </Statistic>
                <Statistic color='grey'>
                  <Statistic.Value>${bonusDoll}</Statistic.Value>
                  <Statistic.Label>Bonuses Earned</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
          {activePool.season === 2019 && activePool.game === 'nba' ?
            <Grid.Row className="page-subheader">
              <div className="game-susp">
                The 2019 NBA Survivol Pool was adapted for the bubble + playoffs and <Link to='/nba19/offline'>finished offline</Link> on account of the pandemic.
              </div>
            </Grid.Row>
          :
            null
          }
        </Grid>
          <div className="drop-wrapper">
          <Dropdown
            text={`View By:  ${this.state.view}`}
            onChange={this.changeView}
            options={this.state.viewOptions}
            icon={this.state.viewIcon}
            floating
            labeled
            button
            className='icon view-filter'
          >
          </Dropdown>
          </div>
          <div className="drop-wrapper">
          <Dropdown
            text={`Sort By:  ${this.state.sortMethod}`}
            onChange={this.changeSort}
            options={this.state.sortOptions}
            icon={this.state.sortIcon}
            floating
            labeled
            button
            className='icon view-filter-alt'
          >
          </Dropdown>
          </div>
          <div className="header-note">
            Picks and data will appear in the table below when selections lock each week.
          </div>
          {gameEntrants.length ?
            <ScrollableEntrantTable
              view={this.state.view}
              weeks={this.state.weeks}
              schedule={this.props.teamSchedules}
              entrants={gameEntrants.filter(e => e.status !== 0)}
              teams={alphTeams}
              liveWeek={liveWeek}
              activeWeek={activeGameWeek}
              bonusTotals={gameBonusTotals}
              teamResults={teamResults}
              weeklyBonusTotals={weeklyBonusTotals}
              alphTeams={alphTeams}
              activeUser={auth ? auth[0].username : null}
              activeGame={activePool.game}
              seasonParams={seasonParams}
            />
          :
            <div className="picks-hidden">
              <Icon name='sun' size='massive' />
              <div className="picks-hidden">Standings will show up here once this pool contains active users.</div>
            </div>
          }

        </div>
      )
    } else {
      return <Loader className="loader-down" active inline='centered' size='massive'> LOADING STANDINGS ... </Loader>
    }
  }
}

function mapStateToProps({ scheduleWeeks, teamSchedules, gameEntrants, alphTeams, liveWeek, gameBonusTotals, weeklyBonusTotals, teamResults, activeGameWeek, bonusEvents, auth, activePool, seasonParams, prizes, league }) {
  return { scheduleWeeks, teamSchedules, gameEntrants, alphTeams, liveWeek, gameBonusTotals, weeklyBonusTotals, teamResults, activeGameWeek, bonusEvents, auth, activePool, seasonParams, prizes, league };
}

export default connect(mapStateToProps)(Standings);
