import React from 'react';

export default function BonusPoolFaq () {
  return (
    <>
      <h3> If I am participating in the Bonus Pool but am eliminated from the Survivor Pool, am I still on the hook for any subsequent Bonus Pool payouts that happen after I'm eliminated?</h3>
      <p> Nope. When you run out of Survivor lives or are otherwise eliminated, you are also removed from the Bonus Pool and will not be assessed any penalties from that point onward.</p>
      <h3> If I have earned prizes in the Bonus Pool but am eliminated from the game, do I get to keep that money? Or does that go to the winner of the Survivor Pool?</h3>
      <p> Yes! That money is all yours -- any prizes earned in the Bonus Pool are yours to keep regardless of how you finish in the Survivor pool. Please note that any earned Bonus Pool prizes will not be paid out until the conclusion of the game.</p>
    </>
  )
}
