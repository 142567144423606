import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Loader, Grid, Header, Icon } from 'semantic-ui-react';
import RenderLives from './table_helpers/RenderLives';
import "./styles/finances.scss";

const formatDollars = d => d >= 0 ? `$${(d).toFixed(2)}` : `-$${(Math.abs(d)).toFixed(2)}`;

class Finances extends Component {
  renderRows () {
    const {gameEntrants, activePool, payments, gameBonusTotals} = this.props;
    const champions = JSON.parse(activePool.champions);
    let alphSort = [...gameEntrants.filter(e => e.status !== 0)];
    alphSort.sort((a, b) => a.username.localeCompare(b.username));
    return alphSort.map(entrant => {
      const champ = champions ? champions.includes(entrant.username) : false;
      entrant.buyin = activePool.buyin;
      entrant.rebuyD = activePool.rebuy_price*entrant.rebuys;
      entrant.paid = payments[entrant.username] ? payments[entrant.username]['payments'] : 0;
      entrant.prizesPaidTo = payments[entrant.username] ? payments[entrant.username]['prizesPaidTo'] : 0;
      entrant.profits = -entrant.buyin + -entrant.insurance + -entrant.rebuyD + gameBonusTotals[entrant.username] + entrant.prizes;
      entrant.total = -entrant.buyin + -entrant.insurance + -entrant.rebuyD + gameBonusTotals[entrant.username] + entrant.paid + entrant.prizes - entrant.prizesPaidTo;
      return (
        <Table.Row key={entrant.id}>
          <Table.Cell>{entrant.username}</Table.Cell>
          <Table.Cell className={champ ? 'game-color' : null}>
            {champ ? <b>CHAMPION <Icon name='trophy' /></b> : entrant.extinct ? 'ELIMINATED' :
              <RenderLives lives={entrant.lives}
            />}
          </Table.Cell>
          <Table.Cell style={{color: '#BF392F'}}>{formatDollars(-entrant.buyin)}</Table.Cell>
          <Table.Cell style={entrant.rebuyD > 0 ? {color: '#BF392F'} : null}>{formatDollars(-entrant.rebuyD)}</Table.Cell>
          <Table.Cell style={entrant.insurance > 0 ? {color: '#BF392F'} : null}>{formatDollars(-entrant.insurance)}</Table.Cell>
          <Table.Cell style={gameBonusTotals[entrant.username] < 0 ? {color: '#BF392F'} : null}>   {formatDollars(gameBonusTotals[entrant.username])}
          </Table.Cell>
          <Table.Cell className="bord-r">{formatDollars(entrant.prizes)}</Table.Cell>
          <Table.Cell>{formatDollars(entrant.paid)}</Table.Cell>
          <Table.Cell className="bord-r">{formatDollars(entrant.prizesPaidTo)}</Table.Cell>
          <Table.Cell
          style={entrant.total.toFixed(0) < 0 ? {color: '#BF392F'} : null}> <b>{formatDollars(parseFloat(entrant.total.toFixed(2)))}  </b> </Table.Cell>
        </Table.Row>
      )
    })
  }

  render () {
    if (this.props.payments !== null && this.props.gameBonusTotals !== null && this.props.activePool !== null) {
      return (
        <div>
          <Grid style={{marginBottom: 20, marginTop: 5, position: 'relative'}}>
            <Grid.Row className={`page-header ${this.props.activePool.game} finances`}>
              <Grid.Column width={16}>
                <Header as='h2'>
                  <div style={{display: 'inline-block'}}>
                    <Icon name='dollar sign' circular inverted size='large'/>
                  </div>
                  <Header.Content>
                    Finances
                    <Header.Subheader>
                      - This page tracks rebuys, bonus pool balances, prizes, and anything else finance-related. <br />
                      - Your league's Commissioner can log payments made and prizes paid in the Commish Tools.
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell> Entrant </Table.HeaderCell>
                <Table.HeaderCell> Status </Table.HeaderCell>
                <Table.HeaderCell> Buy-in </Table.HeaderCell>
                <Table.HeaderCell> Rebuys </Table.HeaderCell>
                <Table.HeaderCell> Insurance </Table.HeaderCell>
                <Table.HeaderCell> Bonus Pool </Table.HeaderCell>
                <Table.HeaderCell className="bord-r"> Game Prizes </Table.HeaderCell>
                <Table.HeaderCell> Buy-ins Paid </Table.HeaderCell>
                <Table.HeaderCell className="bord-r"> Prizes Paid Out </Table.HeaderCell>
                <Table.HeaderCell> Balance </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.renderRows()}
            </Table.Body>
          </Table>
          {!this.props.gameEntrants.length ?
            <div className="picks-hidden">
              <Icon name='sun' size='massive' />
              <div className="picks-hidden">Pool finance data will show up here once this pool has active users.</div>
            </div>
          : null}
        </div>
      )
    } else {
      return <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
    }
  }
}

function mapStateToProps ({ gameEntrants, activePool, gameBonusTotals, payments }) {
  return { gameEntrants, activePool, gameBonusTotals, payments }
}

export default connect (mapStateToProps, {})(Finances);
