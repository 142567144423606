import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Grid, Button, Message, Header } from 'semantic-ui-react';
import { addCommishPost } from '../../actions';

function CommishPost ({ activePool, addCommishPost }) {
  const [post, setPost] = useState('');
  const [summary, setSummary] = useState('');
  const [type, setType] = useState('');
  const [link, setLink] = useState('');
  const [msgVis, setMsgVis] = useState(false);
  const [error, setError] = useState(null);

  const types = [
    {key: 'alert', text: 'alert (default)', value: 17},
    {key: 'bonus', text: 'bonus-related', value: 7}
  ]

  function addPost() {
    if (!post.length) {
      setError("You didn't include anything in the body of your post!");
      setMsgVis(true);
      setTimeout(() => setMsgVis(false), 3000);
    } else if (!summary.length) {
      setError("You didn't include a headline for your post!");
      setMsgVis(true);
      setTimeout(() => setMsgVis(false), 3000);
    } else if (!(type > 0)) {
      setError("You didn't select a post type!");
      setMsgVis(true);
      setTimeout(() => setMsgVis(false), 3000);
    } else {
      addCommishPost({
        post,
        link,
        summary,
        type,
        activePool
      })
    }
  }

  return (
    <Grid className='commish-tools-cont'>
      <Grid.Row columns={1}>
        <Grid.Column>
        <div className="user-pg-header">Write a Post to Your League</div>
        <Header size='medium'>Use this space to write a message to your league, which will be posted in the Commish's Desk section of the pool homepage.</Header>
        {msgVis ? <Message negative> {error} </Message> : null }
        <Form onSubmit={addPost}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Form.Select label='Post Type' options={types} placeholder='type' value={type} onChange={(e, {value}) => setType(value)}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Input label='Headline' placeholder='write your headline here' onChange={e => setSummary(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Input label='Link (optional)' placeholder='include an optional link here' onChange={e => setLink(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.TextArea label='Post Body' placeholder='write your post here' onChange={e => setPost(e.target.value)}
                />
                <Button primary> Submit </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default connect (null, {addCommishPost}) (CommishPost);
