import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Grid, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { registerUser } from '../actions';
import lillard from '../images/lillard_game_winner.jpg';
import './styles/register.scss';
import Header from './Header';

class Register extends Component {
  state = { bonus: 0 };
  handleChange = (e, { value }) => this.setState({ bonus: value });

  renderError( meta, type ) {
    if (type!=='password' && meta.touched && meta.error) {
      return (
        <div className="form-error"> {meta.error} </div>
      )
    }
  }

  renderInput = ({ input, label, type, meta }) => {
    return (
      <div className="field form-field">
        <label> {label} </label>
        <input {...input} type={type} autoComplete="off" />
        {this.renderError(meta)}
      </div>
    )
  }

  onSubmit = formValues => {
    formValues.bonus = this.state.bonus;
    this.props.registerUser(formValues)
  }

  render () {
    if (this.props.allLeagues) {
      switch (this.props.auth) {
        case null:
          return (
            <div>
              <Header />
              <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
            </div>
          )
        case false:
          return (
            <div>
              <Header />
              <img src={lillard} alt='dame' className="home-bg"></img>
              <Form error onSubmit={this.props.handleSubmit(this.onSubmit)} className="register-form">
                <div className="reg-header">Create Your ALA Account</div>
                <div className="reg-sub-header">For access to original pools, detailed record-keeping, and the opportunity to become legendary.</div>
                <div className="reg-error-header">{this.props.authErrors ? this.props.authErrors.message : null}</div>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Field name="nameFirst" component={this.renderInput} label="First Name" type="text"/>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Field name="nameLast" component={this.renderInput} label="Last Name" type="text"/>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Field name="username" component={this.renderInput} label="Username" type="text"/>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Field name="email" component={this.renderInput} label="Email" type="email"/>
                  </Grid.Column >
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Field name="password" component={this.renderInput} label="Password" type="password"/>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Field name="passwordConfirm" component={this.renderInput} label="Confirm Password" type="password"/>
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={15}>
                    <Field name="profilePhoto" component={this.renderInput} label="Profile Photo URL (optional)"/>
                    <div className="photo-exp"> Include the web address of a photo if you would like one displayed along with your username in the future. You can also add this later. </div>
                  </Grid.Column>
                  <Grid.Column className="reg-btn-column" mobile={16} computer={16}>
                    <Button primary> Register </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          );
        default:
          return (
            <div>
              <Header />
                <div className="logged-in-already" style={{marginTop: 200}}>
                You are already logged in!
              </div>
            </div>
          )
      }
    } else {
      return (
        <div>
          <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>;
        </div>
      )
    }
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.nameFirst) {
    errors.nameFirst = 'You must include your first name';
  }

  if (!formValues.nameLast) {
    errors.nameLast = 'You must include your last name';
  }

  if (!formValues.username) {
    errors.username = 'You must create a username';
  }

  if (!formValues.email) {
    errors.email = 'You must include your email';
  }

  if (formValues.password !== formValues.passwordConfirm) {
    errors.passwordConfirm = "Your passwords don't match";
  }

  return errors;
}

const formWrapped = reduxForm({
  form: 'register',
  validate
})(Register);

function mapStateToProps ({ auth, authErrors, allLeagues }) {
  return { auth, authErrors, allLeagues }
}

export default connect(mapStateToProps, { registerUser })(formWrapped);
