import React from 'react'
import { connect } from 'react-redux';
import { Grid, Header, Icon } from 'semantic-ui-react';

function LeagueRankings () {
  return (
    <>
      <Grid style={{marginBottom: 20, marginTop: 5}}>
        <Grid.Row className='page-header'>
          <Grid.Column width={13}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='line graph' circular size='large'/>
              </div>
              <Header.Content>
                League Rankings
                <Header.Subheader>
                - Use this page to view your league roster and send messages to your leaguemates. <br/>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign={'right'} className='purple-text' style={{fontSize: 20}}>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{height: 500, paddingTop: 200}}>
          <Header as='h2' icon>
            <Icon name='hourglass half' />
            Coming Soon
            <Header.Subheader>
              This page is under construction.
            </Header.Subheader>
          </Header>
        </Grid.Row>
      </Grid>
    </>
  )
}

function mapStateToProps ({ league, auth }) {
  return ({ league, auth })
}

export default connect (mapStateToProps, null) (LeagueRankings);
