import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Table, Icon, Button, Header, Transition, Modal, Popup, TextArea, Form, Dropdown, Checkbox, Grid, Label, Loader } from 'semantic-ui-react';
import RenderLives from './../table_helpers/RenderLives';
import ContextPopup from './../other_helpers/ContextPopup';
import NotifPortal from './../other_helpers/NotifPortal';
import { toast } from 'react-semantic-toasts';
import { editUserLives, processLeagueChamps, toggleUserStatus, loadActivePool } from './../../actions/index';
import './../styles/commish-tools.scss';
import './../styles/global.scss';


const arrayToText = array => {
  let text = '';
  if (!array.length) {
    return;
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return `${array[0]} and ${array[1]}`
  } else {
    array.forEach((a, idx) => {
      if (idx !== array.length - 1) {
        text = `${text}${a}, `;
      } else {
        text = `${text} and ${a}`;
      }
    })
    return text;
  }
}

function ManageEntrants ({ activePool, gameEntrants, prizes, editUserLives, processLeagueChamps, activeGameWeek, deadlines, toggleUserStatus, league, loadActivePool }) {
  const [editLivesVis, setEditLivesVis] = useState(false);
  const [processChampVis, setProcessChampVis] = useState(false);
  const [userActivationVis, setUserActivationVis] = useState(false);
  const [addEntrantVis, setAddEntrantVis] = useState(false);
  const [lifeAction, setLifeAction] = useState('');
  const [lifeCount, setLifeCount] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [activeUserStatus, setActiveUserStatus] = useState(null);
  const [actionDesc, setActionDesc] = useState(null);
  const [submitError, setSubmitError] = useState(false);
  const [champions, setChampions] = useState([]);
  const [livesPortalVis, setLivesPortalVis] = useState(false);
  const [livesPortalShown, setLivesPortalShown] = useState(false);
  const [usersAlive, setUsersAlive] = useState([]);
  const [userWeeksAlive, setUserWeeksAlive] = useState({});
  const [entrantOptions, setEntrantOptions] = useState(null);
  const [activeAddEntrant, setActiveAddEntrant] = useState(null);
  const [addEntrantBonusStatus, setAddEntrantBonusStatus] = useState(false);

  useEffect(() => {
    setEntrantOptions(getAddEntrantOptions());
  }, [gameEntrants])
  
  const weekOptions = deadlines.map((d, idx) => {
    return {key: idx+1, value: idx+1, text: idx+1}
  });

  function getAddEntrantOptions () {
    const activeEntrants = gameEntrants.map(m => m.username);
    return league.members
      .map((m, i) => {
        return {
          key: i+1,
          value: m.user_id,
          text: m.username
        }
      })
      .filter(m => !activeEntrants.includes(m.text))
  }

  const addEntrantOptions = league.members
    .map(m => m.username)
    .filter(m => !gameEntrants.includes(m));

  function editLives (user, action, lives) {
    setActiveUser(user);
    setLifeAction(action);
    setLifeCount(lives);
    setEditLivesVis(true);
  }

  function toggleChamp(u) {
    if (!livesPortalShown) {
      setLivesPortalVis(true);
      setLivesPortalShown(true);
    }
    if (!champions.includes(u)) {
      setChampions([...champions, u]);
    } else {
      setChampions([...champions].filter(champion => champion !== u));
    }
  }

  function changeUserLives () {
    if (!actionDesc) {
      setSubmitError(true);
      setTimeout(() => setSubmitError(false), 3000);
    } else {
      editUserLives({
        game: activePool.game,
        season: activePool.season,
        username: activeUser,
        lifeAction,
        actionDesc,
        oldLives: lifeCount,
        newLives: lifeAction === 'increase' ? lifeCount + 1 : lifeCount - 1
      });
    }
  }

  function changeUserWeeksAlive (user, wk) {
    let oldWeeksAlive = { ...userWeeksAlive };
    oldWeeksAlive[user] = wk;
    setUserWeeksAlive(oldWeeksAlive);
  }

  function queueUserActivationVis (username, currentStatus) {
    setActiveUser(username);
    setActiveUserStatus(currentStatus);
    setUserActivationVis(true);
  }

  async function addEntrantToPool () {
    const newEntrant = {
      user_id: activeAddEntrant.id,
      username: activeAddEntrant.username,
      bonus: addEntrantBonusStatus,
      activePool,
      admin: false,
      inLeague: true,
      league_name: league.info.name,
      email: null,
      real_name: null,
      photo_url: null
    }

    try {
      const res = await axios.post('/user/regForGame', newEntrant);
      setAddEntrantVis(false);
      const {game, season, league_abb, pool_idx, season_type} = activePool;
      loadActivePool(game, season, league_abb, pool_idx, season_type);
      toast({
        type: 'warning',
        icon: 'check circle',
        color: 'violet',
        title: 'User successfully added',
        description: `You have successfully added ${res.data.username} to your pool and can now edit that user's picks.`,
        animation: 'slide down',
        time: 3000
      });
    } catch (e) {
      console.log('error updating user settings was ', e);
    }
  }

  function modifyUserStatus () {
    const data = {
      username: activeUser,
      currentStatus: activeUserStatus,
      leagueSeason: activePool.id
    }
    toggleUserStatus(data);
  }

  function queueChampVis () {
    let usersAlive = gameEntrants
      .filter(entrant => entrant.lives !== 0)
      .map(entrant => entrant.username);
    setUsersAlive(usersAlive);
    setProcessChampVis(true);
  }

  function processChampions () {
    if (champions.length) {
      processLeagueChamps({
        game: activePool.game,
        season: activePool.season,
        champions,
        usersAlive,
        prizesRemaining: prizes.prize_pool - prizes.allocated,
        defaultWeeksAlive: activeGameWeek,
        userWeeksAlive,
        leagueSeason: activePool.id
      })
    }
  }

  function renderRows() {
    return gameEntrants.map(entrant => {
      return (
        <Table.Row key={entrant.id}>
          <Table.Cell> 
            {entrant.username}
            <Label size='tiny' color={entrant.status ? 'green' : 'red'} style={{marginBottom: 10, marginLeft: 5}}>
              {entrant.status ? 'ACTIVE' : 'INACTIVE'} 
            </Label>
            
          </Table.Cell>
          <Table.Cell> <i>coming soon</i> </Table.Cell>
          <Table.Cell> 
            {entrant.status === 1 ? 
              <Button color='red' size='small' onClick={() => queueUserActivationVis(entrant.username, 1)}>Deactivate</Button> 
            : 
              <Button color='green' size='small' onClick={() => queueUserActivationVis(entrant.username, 0)}>Reactivate</Button> 
            }
          </Table.Cell>
          <Table.Cell className='lives-adj-cell'>
            <RenderLives lives={entrant.lives} />
            <div style={{width: 30, display: 'inline-block'}}>
              <Popup content={`Increase ${entrant.username} Lives`} trigger={
                <Icon
                className="lifeIcon"
                circular inverted name='plus' color='green'
                size='tiny'
                onClick={() => editLives(entrant.username, 'increase', entrant.lives)}
                />}
              />
              {entrant.lives > 0 ?
                <Popup content={`Decrease ${entrant.username} Lives`} trigger={
                  <Icon
                    className="lifeIcon"
                    circular inverted name='minus' color='red'
                    size='tiny'
                    onClick={() => editLives(entrant.username, 'decrease', entrant.lives)}
                  />}
                />
              : null}
            </div>
          </Table.Cell>
          <Table.Cell>
            {entrant.lives ?
              <>
                <Checkbox className={champions.includes(entrant.username) ? null : 'checkShade'} checked={champions.includes(entrant.username)} onChange={() => toggleChamp(entrant.username)}/>
                <Icon
                  className="trophy-icon"
                  size="big"
                  style={{visibility: (champions.includes(entrant.username) && champions.length) || champions.length === 0 ? 'visible' : 'hidden', color: champions.includes(entrant.username) ? '#D06C35' : 'black'}}
                  name='trophy'
                />
                <NotifPortal open={livesPortalVis}/>
                <span className="weeksAliveSpan" style={{visibility: (!champions.includes(entrant.username) && champions.length) ? 'visible' : 'hidden'}}> <i> Weeks Alive: </i>
                  <Dropdown
                    inline
                    options={weekOptions}
                    defaultValue={activeGameWeek}
                    className='weeksAliveDropdown'
                    onChange={(e, {value}) => changeUserWeeksAlive(entrant.username, value)}
                  />
                </span>
              </>
            : null }
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  if (entrantOptions) {
    return (
      <Grid className='commish-tools-cont'>
        <Grid.Row columns={1}>
          <Grid.Column> 
            <div className="user-pg-header">Add Entrant</div>
            <Header size='tiny'>Use this tool to manually add one of your league's members to your pool.</Header>
            <Dropdown
                options={entrantOptions}
                placeholder='Select League Member'
                selection
                className="addEntrantDropdown"
                onChange={(e, {value}) => {setActiveAddEntrant({id: value, username: e.target.textContent})}}
              />
              <Button style={{marginLeft: 10}} primary onClick={() => setAddEntrantVis(true)} disabled={!activeAddEntrant}>
                  Add Entrant
              </Button>
              <Transition visible={addEntrantVis} animation="fade up" duration={1000}>
                <Modal size="small" centered open={addEntrantVis} className="confirm-pick-modal">
                <Header as='h2'>
                  Add {activeAddEntrant?.username} to this pool?
                </Header>
                  <Modal.Content>
                    Please confirm that you would like to add <b>{activeAddEntrant?.username}</b> to this pool. You will need to use the <b>Edit Picks</b> tool to edit any retroactive selections for this user.
                    {activePool.bonus_active && (<div style={{marginTop: 20}}>
                        <Checkbox 
                            label='Add user to Bonus Pool'
                            checked={addEntrantBonusStatus}
                            onClick={() => setAddEntrantBonusStatus(!addEntrantBonusStatus)}
                          />
                      </div>
                    )}
                  </Modal.Content>
                  <Modal.Actions>
                    <Button negative onClick={() => {setAddEntrantVis(false); setAddEntrantBonusStatus(false)}}>
                      GO BACK
                    </Button>
                    <Button primary onClick={() => addEntrantToPool()}>
                      <Icon name='check circle' />
                        PROCEED
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Transition>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <div className="user-pg-header">Manage Entrants</div>
            <Header size='medium'>Use this page to manage your league's entrants.</Header>
            <Table id="manage-entrants">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Entrant</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>
                    <div style={{position: 'relative'}}>
                      Status
                      <ContextPopup
                        content="Toggle a user's Status to temporarily (or permanently) remove them from the league Scoreboard, Standings, Prize calculations, and everything else related to your pool. For example, you might want to use this feature if a user has joined your pool but has refused to pay his buy-in. A user's status can be toggled back off once turned on, and they will still be able to login and view the pool even if you render them Inactive."
                        trigger={<Icon className="context-icon" name='question circle' />}
                      />
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>Lives</Table.HeaderCell>
                  <Table.HeaderCell colSpan='2'>
                    <div style={{position: 'relative'}}>
                      Set Champion(s)
                      <ContextPopup
                        content="The Set Champion feature should only be used when the pool cannot conclude under normal circumstances (...like, say, when a global pandemic interrupts a Survivor season). To use this tool, toggle on the 'Yes' button for each user that will be declared champion, and then click the PROCESS button. A confirmation modal will then pop up to confirm that you know what you are doing!"
                        trigger={<Icon className="context-icon" name='question circle' />}
                      />
                    </div>
                    {champions.length ?
                      <Button primary size='tiny' style={{marginTop: 5}} onClick={() => queueChampVis()}>PROCESS </Button>
                    : null}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {renderRows()}
              </Table.Body>
            </Table>
            <Transition visible={userActivationVis} animation="fade up" duration={1000}>
              <Modal size="small" centered open={userActivationVis} className="confirm-pick-modal">
              <Header as='h2'>
                {activeUserStatus === 1 ? 'Deactivate' : 'Reactivate'} user entry for {activeUser}?
              </Header>
                <Modal.Content>
                  Please confirm that you would like to <b>{activeUserStatus === 1 ? 'deactivate' : 'reactivate'}</b> the user entry for <b>{activeUser}</b>. An <b><i>inactive</i></b> user will not appear in the standings, scoreboard, or have their buyins or rebuys accounted for in a game's prize totals. (This action is reversible; a user can always be deactivated or reactivated through this page.)
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={() => {setUserActivationVis(false); setActiveUser(null); setActiveUserStatus(null)}}>
                    GO BACK
                  </Button>
                  <Button primary onClick={() => modifyUserStatus()}>
                    <Icon name='check circle' />
                      PROCEED
                  </Button>
                </Modal.Actions>
              </Modal>
            </Transition>
            <Transition visible={editLivesVis} animation="fade up" duration={1000}>
              <Modal size="small" centered open={editLivesVis} className="confirm-pick-modal">
              <Header as='h2'>
                {`${lifeAction.slice(0, 1).toUpperCase()}${lifeAction.slice(1)}`} Lives for {activeUser}?
              </Header>
                <Modal.Content>
                  Proceeding will <b>{lifeAction}</b> <b>{activeUser}</b>'s number of lives by one life. <b>{activeUser}</b>'s number of lives will change from {lifeCount} to {lifeAction === 'increase' ? lifeCount + 1 : lifeCount - 1}. This action requires a message that will be posted to the league news feed to explain this change.
                  {(lifeAction === 'decrease' && (lifeCount - 1 === 0)) ?
                    <div className="actionWarning"><b> WARNING: Because you are reducing this user's lives to 0, this user will be eliminated from the pool if you proceed.</b></div>
                  : null}
                  <Form style={{marginTop: 10}}>
                    <TextArea placeholder="Explain to your league why you are modifying this user's lives" onChange={e => setActionDesc(e.target.value)}/>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  {submitError ?
                  <span className="error-color"> Please include a message to your league. </span>
                  : null}
                  <Button negative onClick={() => {setEditLivesVis(false); setActiveUser(null)}}>
                    GO BACK
                  </Button>
                  <Button primary onClick={() => changeUserLives()}>
                    <Icon name='check circle' />
                      PROCEED
                  </Button>
                </Modal.Actions>
              </Modal>
            </Transition>
            <Transition visible={processChampVis} animation="fade up" duration={1000}>
              <Modal size="small" centered open={processChampVis} className="confirm-pick-modal">
              <Header as='h2' textAlign="center">
                Process Pool {champions.length > 1 ? 'Champions' : 'Champion'}?
                <div className="error-color" style={{marginTop: 5}}>WARNING: THIS ACTION IS IRREVERSIBLE.</div>
              </Header>
                <Modal.Content>
                  You are electing to process winners for your pool, and you have indicated that <b>{arrayToText(champions)}</b> {champions.length > 1 ? 'are' : 'is'} your pool's {champions.length > 1 ? 'champions' : 'champion'}. <span className="error-color"><b>Please note that in a normal season, using this tool is not required or necessary; if your game concludes as scheduled, your league's champion(s) will be automatically processed when the season ends or when there are no survivors remaining</b></span>. Moving forward with this action is irreversible and will result in the following changes being made:
                  <ul>
                    <li> <b>All users who are not being declared champions will have their remaining lives reduced to 0</b>, and records will show that any such users were eliminated from the pool in the current week OR in the week that you specified they were eliminated on the previous screen.</li>
                    <li> <b>Any remaining prizes not yet allocated to your pool's entrants will be evenly distributed amongst the users whom you are setting as pool champions</b>. These adjustments will be shown in your pool's Finances tab. If this is not what you want, use the <b>Split Prizes</b> tab within the Commish Tools to first distribute your prize pool as needed before processing your pool champions.</li>
                    <li> <b>A post will be added to your league's news feed</b>, informing your league's entrants that the game has concluded and that a champion has been crowned.</li>
                    <li> <b>Your league's settings and records will be modified to indicate that your pool has concluded for this season.</b></li>
                  </ul>
                  Click the button below to move forward and put a bow on your league's Survivor season.
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={() => setProcessChampVis(false)}>
                    GO BACK
                  </Button>
                  <Button primary onClick={() => processChampions()}>
                    <Icon name='check circle' />
                      YES, I WANT TO PROCESS CHAMPIONS
                  </Button>
                </Modal.Actions>
              </Modal>
            </Transition>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  } else {
    return (
      <div className="master-container">
        <Loader className="loader-down" active inline='centered' size='massive'> CRUNCHING NUMBERS </Loader>
      </div>
    )
  }
  
}

function mapStateToProps ({ activeGameWeek, deadlines, league }) {
  return { activeGameWeek, deadlines, league }
}

export default connect (mapStateToProps, {editUserLives, processLeagueChamps, toggleUserStatus, loadActivePool}) (ManageEntrants)
