import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Icon, Message } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { convertToRaw, CompositeDecorator, Editor, EditorState, RichUtils} from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import './../styles/draftjs.scss';
import UnderConstruction from '../other_helpers/UnderConstruction';

function EmailPool ({ umbrella, activePool, auth, league }) {
  const [subject, setSubject] = useState('');
  const [msgVis, setMsgVis] = useState(false);
  const [error, setError] = useState(null);
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  console.log('umbrella is ', umbrella);

  const Link = (props) => {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url} style={{
        color: '#3b5998',
        textDecoration: 'underline',
      }}>
        {props.children}
      </a>
    );
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  }

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(decorator),
  );

  function handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  async function sendEmail() {
    const content = editorState.getCurrentContent();
    const raw = convertToRaw(content);
    const message = raw.blocks[0].text;

    if (!message.length) {
      setError("You didn't include anything in the body of your email!");
      setMsgVis(true);
      setTimeout(() => setMsgVis(false), 3000);
    } else if (!subject.length) {
      setError("You didn't include a subject for your email!");
      setMsgVis(true);
      setTimeout(() => setMsgVis(false), 3000);
    } else {
      const html = convertToHTML({
        styleToHTML: (style) => {
          if (style === 'BOLD') {
            return <b />;
          } else if (style === 'UNDERLINE') {
            return <u />
          } else if (style === 'ITALIC') {
            return <i />
          }
        },
        blockToHTML: (block) => {
          if (block.type === 'PARAGRAPH') {
            return <p />;
          }
        },
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return <a href={entity.data.url}>{originalText}</a>;
          }
          return originalText;
        }
      })(editorState.getCurrentContent());

      let umbrellaId;
      if (umbrella === 'league') {
        umbrellaId = league.info.id
      } else if (umbrella === 'pool') {
        umbrellaId = activePool.id
      }

      const data = {
        authorEmail: auth[0].email,
        authorName: `${auth[0].nameFirst} ${auth[0].nameLast}`,
        subject,
        html,
        umbrella,
        umbrellaId
      };

      try {
        await axios.post('/admin/emailUsers', data);
        toast({
          type: 'warning',
          icon: 'check circle',
          color: 'violet',
          title: 'Email sent!',
          description: `Your email has been sent successfully!`,
          animation: 'slide down',
          time: 3000
        });
        setSubject('');
        setEditorState(() => EditorState.createEmpty(decorator));
      } catch (e) {
        toast({
          type: 'error',
          icon: 'exclamation',
          color: 'red',
          title: 'There was an error sending your email',
          description: `An error was encountered sending your email: ${e}. Please contact ALA support for help with this error.`,
          animation: 'slide down',
          time: 3000
        });
        console.log('error sending email was ', e);
      }
    }
  }

  function applyStyle (e) {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, e.target.name));
  }

  function logState () {
    const content = editorState.getCurrentContent();
    console.log(convertToRaw(content));
  };

  function onURLChange (e) {setUrlValue(e.target.value)};

  function promptForLink (e) {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setShowURLInput(true);
      setUrlValue(url);
    }
  }

  function confirmLink (e) {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: urlValue}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
    // Apply entity
    let nextEditorState = EditorState.set(editorState, 
      { currentContent: contentStateWithEntity }
    );

    // Apply selection
    nextEditorState = RichUtils.toggleLink( nextEditorState, 
      nextEditorState.getSelection(), entityKey 
    );

    setEditorState(nextEditorState);
    setShowURLInput(false);
    setUrlValue('');
  }

  function removeLink (e) {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
  }

  function onLinkInputKeyDown (e) { if (e.which === 13) { confirmLink(e) } }

  const styles = ['BOLD', 'ITALIC', 'UNDERLINE', 'ADD LINK', 'REMOVE LINK'];
  const buttons = styles
    .map((style, idx) => {
      if (idx < 3) {
        return <button key={style} onClick={(e) => applyStyle(e)} name={style}>{style}</button>
      } else if (style === 'ADD LINK') {
        return <button key={'ADD LINK'} onClick={(e) => promptForLink(e)} name={'ADD LINK'}>ADD LINK</button>
      } else if (style === 'REMOVE LINK') {
        return <button key={'REMOVE LINK'} onClick={(e) => removeLink(e)} name={'REMOVE LINK'}>REMOVE LINK</button>
      }
    })
  
  let urlInput;
  if (showURLInput) {
    urlInput =
      <div>
        <input
          onChange={(e) => onURLChange(e)}
          type="text"
          value={urlValue}
          onKeyDown={(e) => onLinkInputKeyDown(e)}
          placeholder="Enter your link's URL here, then click Confirm to add your link"
        />
        <button onMouseDown={(e) => confirmLink(e)}> Confirm </button> 
      </div>;
  }

  return (
    <>
    <Grid className='commish-tools-cont'>
      <Grid.Row columns={1}>
        <Grid.Column>
        <div className="user-pg-header">Write an Email to Your Pool</div>
        <Header size='medium'>Use this space to send an email to your pool.</Header>
        {msgVis ? <Message negative> {error} </Message> : null }
        <Form onSubmit={sendEmail}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Input label='Subject' placeholder="write your email's subject here" onChange={e => setSubject(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
              <div className='toolbar'>
                {buttons}
                {urlInput}
              </div>
                <Editor 
                  editorState={editorState} 
                  handleKeyCommand={handleKeyCommand} 
                  onChange={setEditorState} 
                  placeholder="Write the body of your email here"
                />
                <Button primary> Submit </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    </>
  )
}

function mapStateToProps ({ activePool, auth, league }) {
  return ({ activePool, auth, league })
}

export default connect (mapStateToProps, {}) (EmailPool)
