import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Loader, Icon, Image, Header, Grid, Table } from 'semantic-ui-react';

function LeagueMembers ({ league, auth }) {
  const inLeague = auth[0] && league.members.map(m => m.username).includes(auth[0].username);

  function renderLeagueMembers () {
    return league.members.map(member => {
      return (
        <Table.Row key={member.id}>
          <Table.Cell>
            <Header as='h4' image>
              {member.photo_url ?
                <Image src={member.photo_url} rounded='true' size='mini' />
              : <Icon rounded='true' size='mini' name='user circle' />}
              <Header.Content>
                {member.username}
                <Header.Subheader>{member.tagline || null}</Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell> {inLeague ? member.real_name : <i>hidden</i>} </Table.Cell>
          <Table.Cell> {inLeague && false ? <Icon size ='large' name='mail outline' /> : <i>coming soon</i>} </Table.Cell>
          <Table.Cell> {moment(member.created_at).format('MMMM D, YYYY')} </Table.Cell>
          <Table.Cell> {member.admin ? <Icon name='check' /> : null} </Table.Cell>
        </Table.Row>
      )
    })
  }

  if (!league) {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING LEAGUE </Loader>;
  } else {
    return (
      <>
      <Grid style={{marginBottom: 20, marginTop: 5}}>
        <Grid.Row className='page-header'>
          <Grid.Column width={13}>
            <Header as='h2'>
              <div style={{display: 'inline-block'}}>
                <Icon name='group' circular size='large'/>
              </div>
              <Header.Content>
                League Roster
                <Header.Subheader>
                - Use this page to view your league roster and send messages to your leaguemates. <br/>
                - League members' real names are hidden from viewers outside of your league. <br/>
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign={'right'} className='purple-text' style={{fontSize: 20}}>
            <b>{league.members.length} {league.members.length === 1 ? 'member' : 'members'}</b>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table color='purple'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Name </Table.HeaderCell>
            <Table.HeaderCell>Send Message</Table.HeaderCell>
            <Table.HeaderCell>Member Since</Table.HeaderCell>
            <Table.HeaderCell>Admin?</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderLeagueMembers()}
        </Table.Body>
      </Table>
      </>
    )
  }
}

function mapStateToProps ({ league, auth }) {
  return ({ league, auth })
}

export default connect (mapStateToProps, null) (LeagueMembers);

                // - Missing a profile photo? Head to your <a href="/profile">user profile page</a> to get one uploaded.
