import { FETCH_TEAM_SCHEDULES } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case FETCH_TEAM_SCHEDULES:
      return action.payload;
    default:
      return state;
  }
}
