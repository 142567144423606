import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Message, Header, Icon, Segment, Grid, Image, Checkbox, Input, Loader, Dropdown, List, Popup, Transition, Button, Modal, Menu, Table } from 'semantic-ui-react';
import MLBBonusTable from '.././howtoplay/mlbBonusTable';
import NBABonusExample from '.././howtoplay/nbaBonusExample';
import MLBBonusExample from '.././howtoplay/mlbBonusExample';
import BonusPoolFaq from '.././howtoplay/bonusPoolFaq';
import ContextPopup from './../other_helpers/ContextPopup';
import {activateLeaguePool, getBonusOptions} from '../../actions';
import '../styles/leagues.scss';
import '../styles/global.scss';

function LeagueGameMgmt ({ auth, league, pendingSeasonParams, bonusOptions, activateLeaguePool, getBonusOptions }) {
  const [startDate, setStartDate] = useState(null);
  const [startWeek, setStartWeek] = useState(null);
  const [startText, setStartText] = useState(null);
  const [prevPools, setPrevPools] = useState(null);
  const [canActivate, setCanActivate] = useState(true);
  const [activateMsg, setActivateMsg] = useState(null);
  const [bonusPoolActive, setBonusPoolActive] = useState(false);
  const [shootMoonActive, setShootMoonActive] = useState(false);
  const [bonusPoolStatus, setBonusPoolStatus] = useState('Set Bonus Pool to ACTIVE and View Configuration Options');
  const [shootMoonStatus, setShootMoonStatus] = useState('Shoot the Moon is OFF');
  const [bonusPoolOptions, setBonusPoolOptions] = useState(null);
  const [activePool, setActivePool] = useState(pendingSeasonParams[0]);
  const [confirmPool, setConfirmPool] = useState(false);
  const [buyin, setBuyin] = useState(50);
  const [rebuys, setRebuys] = useState(2);
  const [rebuyPrice, setRebuyPrice] = useState(25);
  const [insurance, setInsurance] = useState(0);
  const [bonusPoolCadence, setBonusPoolCadence] = useState(2);
  const [bonusPoolShutdown, setBonusPoolShutdown] = useState(5);
  const square = { width: 100, height: 100 };

  const optInOptions = [
    {key: 1, value: 1, text: 'Entrants can opt-in or out-of the Bonus Pool at any time.'},
    {key: 2, value: 2, text: 'Entrants can opt-in or out-of the Bonus Pool once per month.'},
    {key: 3, value: 3, text: 'Entrants cannot opt-in or out-of the Bonus Pool only before the season begins.'}
  ]

  useEffect(() => {
    if (activePool) {
      if (moment(activePool.start).isBefore(moment())) {
        let deadlines = JSON.parse(activePool.deadlines);
        for (var i = 0; i < deadlines.length; i++) {
          if (moment().isBefore(deadlines[i])) {
            setStartDate(deadlines[i]);
            setStartWeek(i+1);
            setStartText(`Your pool will start in Week ${i+1} of the ${activePool.game.toUpperCase()} ${activePool.type === 'reg' ? 'season' : 'preseason'}.`);
            break;
          }
        }
      } else {
        setStartDate(activePool.start);
        setStartWeek(1);
        setStartText(`Your pool will start in Week 1 of the ${activePool.game.toUpperCase()} ${activePool.type === 'reg' ? 'season' : 'preseason'}.`)
      }
      setPrevPools(league.seasons.filter(s => (s.game === activePool.game && s.season === activePool.season && s.season_type === activePool.type)).length);
      getBonusOptions(activePool.game);
    }

  }, [activePool])

  useEffect(() => {
    if (!auth) {
      setCanActivate(false);
      setActivateMsg("You must be logged in to activate this pool.");
    } else if (!JSON.parse(league.info.admins).includes(auth[0].username)) {
      setCanActivate(false);
      setActivateMsg("Only your league's administrators can activate a pool.");
    } else if (prevPools > 2) {
      setCanActivate(false);
      setActivateMsg("Each league is permitted a maximum of three pools for a given sport season.");
    } else {
      setCanActivate(true);
      setActivateMsg(null);
    }
  }, [prevPools, auth])

  useEffect(() => {
    if (bonusOptions) {
      const options = {};
      bonusOptions.forEach(bonus => {
        options[bonus.bonus_code] = {
          type: bonus.type,
          active: true,
          prize: bonus.prize_default,
          bonus_name: bonus.bonus_name,
          bonus_long_name: bonus.bonus_long_name
        }
      })
      setBonusPoolOptions(options);
    }
  }, [bonusOptions])

  function renderBonusOptions () {
    return Object.keys(bonusPoolOptions).map(b => {
      return (
        <Table.Row key={b} className={bonusPoolOptions[b].active ? null : 'inactive-bonus'}>
          <Table.Cell> <Checkbox toggle checked={bonusPoolOptions[b].active ? true : false} onChange={() => setBonusActive(b)} /> </Table.Cell>
          <Table.Cell> <Input disabled={!bonusPoolOptions[b].active} label='$' value={bonusPoolOptions[b].prize} onChange={(e, {value}) => setBonusPrize(b, value)} className='bonus-option'/> </Table.Cell>
          <Table.Cell> {bonusPoolOptions[b].bonus_long_name} </Table.Cell>
        </Table.Row>
      )
    })
  }

  function setBonusActive (code) {
    let prevOptions = { ...bonusPoolOptions};
    prevOptions[code].active = !prevOptions[code].active;
    setBonusPoolOptions(prevOptions);
  }

  function setBonusPrize (code, value) {
    let prevOptions = { ...bonusPoolOptions};
    prevOptions[code].prize = value;
    setBonusPoolOptions(prevOptions);
  }

  function changeActivePool (id) {
    setActivePool(pendingSeasonParams.find(pool => pool.id === id));
  }

  function activatePool () {
    if (canActivate) {
      activateLeaguePool({
        buyin, rebuys, rebuyPrice, bonusPoolActive, bonusPoolCadence, bonusPoolShutdown, startDate, startWeek,
        activePool, prevPools, bonusPoolOptions, insurance, shootMoonActive,
        league: league.info,
        members: league.members,
        user: auth[0]
      });
    } else {
      alert('Only a league admin can activate a pool.')
    }
  }

  function toggleBonusPool () {
    if (!bonusPoolActive) {
      setBonusPoolActive(true);
      setBonusPoolStatus('Bonus Pool ACTIVE');
    } else {
      setBonusPoolActive(false);
      setBonusPoolStatus('Set Bonus Pool to ACTIVE and View Configuration Options');
    }
  }

  function toggleShootMoon () {
    if (!shootMoonActive) {
      setShootMoonActive(true);
      setShootMoonStatus('Shoot the Moon is ON');
    } else {
      setShootMoonActive(false);
      setShootMoonStatus('Shoot the Moon is OFF');
    }
  }

  function renderGameMenu () {
    return pendingSeasonParams.map((pool, idx) => {
      return (
        <Menu.Item
            key={idx}
            name={pool.game_name}
            active={pool.id === activePool.id}
            onClick={() => changeActivePool(pool.id)}
          />
      )
    })
  }

  function displayBonuses () {
    let bonuses = [];
    Object.keys(bonusPoolOptions).forEach(b => {
      if (bonusPoolOptions[b].active === true) {
        bonuses.push(`${bonusPoolOptions[b].bonus_name} ($${bonusPoolOptions[b].prize})`);
      }
    })
    return bonuses.join(', ');
  }

  function renderGame (sp) {
      return (
        <>
          <Grid key={sp.id} className="upcEventGrid">
            <Grid.Row>
              <Grid.Column width={16} textAlign='center'>
                <Image centered circular inline bordered size='small' src={sp.image_url} />
                <div style={{display: 'inline', marginLeft: 20}}>
                  <div className='game-mgmt-header' style={{fontSize: 48, display: 'inline', position: 'relative'}}>
                    <b>{sp.game_name.toUpperCase()}</b>
                    <div className='game-mgmt-subtext'>
                      <div> <b>{sp.game === 'nba' ? 'TIP-OFF' : 'FIRST PITCH'}</b>: {moment(startDate).format('M/D/YY, h:mm A zz')} </div>
                      <div className='game-mgmt-start'> <i> {startText} </i> </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {prevPools > 0 ?
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Message compact negative={prevPools > 2} warning={prevPools === 2 || prevPools === 1}> <Icon name='exclamation'/>
                  This league currently has {prevPools} active {activePool.game_name} Pools. Leagues are permitted a maximum of three pools per sport, per season.</Message>
                </Grid.Column>
              </Grid.Row>
            : null }
            <Grid.Row style={{marginTop: 30}} centered>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>1</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Initial Buy-in
                        <Header.Subheader>
                          This is the standard entry fee into your league's pool.
                          <Input label='$' value={buyin} onChange={(e, {value}) => setBuyin(value)} />
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>2</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Extra Lives/Rebuys
                        <ContextPopup
                        header="Rebuys and Extra Lives"
                        content="The number of lives an entrant will have before being eliminated from the pool is their initial life + the permitted number of rebuys -- so if you allow two rebuys, an entrant will have three lives over the course of the season (provided they utilize those rebuys). If you'd prefer to allow entrants multiple lives without having any cost associated with their extra lives, set a number of rebuys/lives here, but then set the rebuy cost to $0."
                        trigger={<Icon style={{fontSize: 14, top: -10, left: 260}} className="context-icon purple-text" name='question circle' />}
                        />
                        <Header.Subheader>
                        Set the number of extra lives/rebuys permitted. If you'd prefer to allow an entrant only one life, set to 0.
                        <Input type="number" value={rebuys} onChange={(e, {value}) => setRebuys(value)} label="rebuys" labelPosition="right"/>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{marginTop: 30}} centered>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>3</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Set Rebuy Cost
                        <ContextPopup
                        header="Rebuy Cost"
                        content="Set this value to $0 if you would prefer to allow your pool's entrants multiple lives without having to pay for their extra lives."
                        trigger={<Icon style={{fontSize: 14, top: -10, left: 177}} className="context-icon purple-text" name='question circle' />}
                        />
                        <Header.Subheader>
                          Set the cost of each rebuy/extra life. If you are not allowing rebuys, this can be ignored.
                          <Input disabled={rebuys<1} label='$' value={rebuyPrice} onChange={(e, {value}) => setRebuyPrice(value)} />
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>4</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Offer Insurance?
                        <Header.Subheader>
                        Permit entrants to pay a one-time fee that protects them against omitted picks.
                        <ContextPopup
                          header="What's Insurance?"
                          content="Enter a dollar value to allow your pool's entrants to buy a one-time insurance policy that protects them against missed picks. If an entrant purchases insurance and forgets to make a pick in a given week, the entrant will automatically be given the most popular pick in your pool that the entrant still has available. The proceeds from insurance policies are automatically included in your league's prize pool calculations."
                          trigger={<span style={{marginLeft: 2}}><b><u>More info</u></b></span>}
                        />
                        <Input className="wider" type="number" value={insurance} onChange={(e, {value}) => setInsurance(value)} icon='dollar sign' iconPosition='left' labelPosition="right" label={{ tag: true, content: insurance > 0 ? 'OFFERED' : 'NOT OFFERED', color: insurance > 0 ? 'green' : 'red'}}/>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            {activePool.game === 'mlb' && 
              <Grid.Row style={{marginTop: 30}} centered columns={1} verticalAlign='middle'>
              <Grid.Column width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Segment circular inverted style={square} floated='right'>
                        <div className='create-pool-step'>5</div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Header as='h2'>
                        Shoot the Moon?
                        <ContextPopup
                        header="Activate SHOOT THE MOON?"
                        content="For MLB Pools only. If Shoot the Moon is active and an entrant's selection loses *every game* they play that week (0-6 or worse), not only will they not lose a life, but they will GAIN a life."
                        trigger={<Icon style={{fontSize: 14, top: -10, left: 198}} className="context-icon purple-text" name='question circle' />}
                        />
                        <Header.Subheader>
                          If active, an entrant will *gain* a life if their weekly selection loses <i>every</i> game they play that week (0-6 or worse).
                          <div style={{marginTop: 5}}>
                            <Checkbox className="lg-toggle" toggle label={shootMoonStatus} checked={shootMoonActive} onChange={() => toggleShootMoon()} />
                          </div>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            }
            <Grid.Row centered columns={1} verticalAlign='middle'>
              <Grid.Column textAlign='center' width={12}>
                <div style={{display: 'inline-block', marginTop: 20, marginBottom: 20}}>
                  <Segment circular inverted style={square} textAlign='center'>
                    <Icon name='rocket' />
                  </Segment>
                </div>
                <div style={{display: 'inline-block'}}>
                  <Header as='h1'>
                    Activate Performance Bonus Pool?
                    <div>
                      <Checkbox className="lg-toggle" toggle label={bonusPoolStatus} checked={bonusPoolActive} onChange={() => toggleBonusPool()} />
                    </div>
                    <Header.Subheader>
                      The Performance Bonus Pool is an advanced game component available to leagues that would like to raise the stakes and have additional rooting interest in their Survivor Pool beyond whether their chosen team will win their games. Use the switch above to view Bonus Pool configuration options and additional details.
                    </Header.Subheader>
                  </Header>
                </div>
              </Grid.Column>
            </Grid.Row>
        </Grid>
        <Transition visible={bonusPoolActive} animation='fade' duration={600}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <List size='large' bulleted>
                  <List.Item>
                    The Performance Bonus Pool awards prizes to participating entrants for outstanding team or player achievements from their weekly Survivor selection, which are collectively paid out by the other entrants who have opted-in to the Peformance Bonus Pool. If activated, participation in the Bonus Pool will be optional for your league's entrants.
                  </List.Item>
                  <List.Item>
                    View the Performance Bonuses for {sp.game.toUpperCase()} Survivor in the table to the right.
                  </List.Item>
                  <List.Item>
                    {sp.game === 'nba' ? <NBABonusExample /> : <MLBBonusExample />}
                  </List.Item>
                  <List.Item>
                    Bonus Pool prizes and penalties are automatically calculated and tracked by the Survivor software, but it will be up to each league's Commissioner to follow up with each entrant and settle everyone's balances at the end of the season.
                  </List.Item>
                  <List.Item>
                    There are additional components to consider and options to configure if your pool is utilizing the Bonus Pool. Both of these options can be changed after your pool is set up while your season is ongoing.
                      <List.List>
                        <List.Item> <b>Determining when (or whether) entrants can opt-in and out-of the pool.</b> Some leagues may prefer to allow their entrants the strategic flexibility of coming and going from the Bonus Pool at their leisure; others may prefer to lock entrants in for a certain amount of time. We give you three options there. </List.Item>
                        <List.Item> <b>Determining when the bonus pool concludes.</b> We recommend shutting down the Bonus Pool when it is reduced to around five entrants, as at that point penalties may become unexpectedly expensive. But that is up to you. </List.Item>
                      </List.List>
                  </List.Item>
                  <List.Item>
                    For more information, take a look at the <Popup content={<BonusPoolFaq />} wide='very' trigger={<b><u>Bonus Pool FAQ</u></b>} />.
                  </List.Item>
                  <List.Item className='purple-text'>
                    <b>Your Bonus Pool settings can be modified after you set up your pool and before the season begins.</b>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6} textAlign='center'>
                <Header size='large' className='purple-text' style={{marginBottom: 0}}>{sp.game.toUpperCase()} Survivor: Bonus Pool Options </Header>
                <i> Select which bonuses will be active in your pool and modify the prize values to fit your pool's preferences. (These options can be changed later) </i>
    
                  <div>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Active</Table.HeaderCell>
                          <Table.HeaderCell>Payout</Table.HeaderCell>
                          <Table.HeaderCell>Achievement</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {renderBonusOptions()}
                      </Table.Body>
                    </Table>
                  </div>

              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid columns={4}>
                <Grid.Column></Grid.Column>
                <Grid.Column>
                  <Header as='h2' style={{position: 'relative'}}>
                    Set Opt-in/Opt-out Cadence
                    <ContextPopup
                    header="Why does this matter?"
                    content="A Bonus Pool's Opt-in/Opt-out Cadence controls how liberal entrants can be in 'strategically' participating in the Bonus Pool. Some leagues may prefer to give their entrants free reign in choosing to gamble on bonuses whenever they feel like it; others may find it dispiriting to allow entrants to only participate (and risk paying out penalties) during weeks in which they may be utilizing teams more likely to earn bonuses. Note that an entrant is never allowed to enter or leave a bonus pool DURING a week; any change to an entrant's Bonus Pool status does not become effective until the following week."
                    trigger={<Icon style={{fontSize: 14, top: 20, left: 95}} className="context-icon purple-text" name='question circle' />}
                    />
                    <Header.Subheader>
                      When can your pool's entrants come and go from the Bonus Pool?
                      <Dropdown
                        onChange={(e, {value}) => setBonusPoolCadence(value)}
                        options={optInOptions}
                        selection
                        value={bonusPoolCadence}
                        style={{marginTop: 10}}
                      />
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h2'>
                    Set Shutdown Number
                    <ContextPopup
                    header="Why does this matter?"
                    content="As Bonus Pools shrink in size, bonuses become more rare on account of fewer teams and entrants being eligible to earn them -- but they are also more proportionally expensive for the non-prize-winning entrants, since any bonus earned is paid out collectively by the other entrants remaining in the Bonus Pool. If your Bonus Pool prizes are for any meaningful amount of money, ALA recommends shutting down the pool with no less than five entrants remaining, lest one of the last remaining entrants end up getting particularly unlucky and owing a hefty bill all on his (or her) own. But it's up to you and how much you and your leaguemates want to gamble!"
                    trigger={<Icon style={{fontSize: 14, top: 5, left: 260}} className="context-icon purple-text" name='question circle' />}
                    />
                    <Header.Subheader>
                      Decide when your Bonus Pool should shut down. If you would like the pool to run all season regardless of how many entrants remain in the Bonus Pool, set to 0.
                      <Input style={{marginTop: 15}} type="number" value={bonusPoolShutdown} label="entrants remaining" labelPosition="right" onChange={(e, {value}) => setBonusPoolShutdown(value)}/>
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>
        </Transition>
      </>
    )
  }

  if (pendingSeasonParams !== null && bonusOptions !== null && bonusPoolOptions !== null) {
    return (
      <div>
        <Grid style={{marginBottom: 20, marginTop: 5}}>
          <Grid.Row className='page-header create-pool'>
            <Grid.Column width={16}>
              <Header as='h2'>
                <div style={{display: 'inline-block'}}>
                  <Icon name='birthday cake' circular size='large'/>
                </div>
                <Header.Content>
                  Configure & Activate Pools for Your League
                  <Header.Subheader>- <b>Your league's Commissioner</b> can use this page to activate upcoming games for your league. <br/>
                  - Once a pool is activated, league members can sign up and begin making selections. <br/>
                  - ALA's default settings have been loaded to get you started; modify those to fit your league's preferences.
                  </Header.Subheader>
                </Header.Content>
                {activePool ?
                  <div style={{position: 'absolute', top: -7, right: 10}}>
                    <div className='league-label'>Select Game:</div>
                      <Menu vertical inverted>
                        {renderGameMenu()}
                      </Menu>
                    </div>
                  : null }
              </Header>
            </Grid.Column>
          </Grid.Row>
          {activePool ?
            renderGame(activePool)
            :
            <Grid.Row>
              <Grid.Column textAlign='center' style={{fontSize: 24, marginTop: 60, marginBottom: 80}}>
                There are no upcoming pools available to activate. Check back here as the next season draws closer.
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
        {activePool ?
          <Grid centered style={{marginBottom: 20}} className="confirm-row">
            <Button size='massive' onClick={() => setConfirmPool(true)} disabled={!canActivate}> CONFIRM SETTINGS & ACTIVATE POOL </Button>
            {activateMsg ?
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Message compact negative> <Icon name='exclamation'/>
                  {activateMsg}</Message>
                </Grid.Column>
              </Grid.Row>
            : null }
            <Modal size="small" centered open={confirmPool} onClose={() => setConfirmPool(false)} className="confirm-pick-modal">
            <Header as='h2' style={{backgroundColor: '#6638f0', color: 'white'}}>
              <div className='league-header-cont'>
                <span className='league-header'>{league.info.name}</span>
                Review Settings & Activate Pool
              </div>
            </Header>
              <Modal.Content style={{fontSize: 20}}>
                <p>Your <b>{`${activePool.game.toUpperCase()} ${activePool.season}`}</b> Survivor Pool will be configured with the following settings:</p>
                <div> - Initial Buy-in: <b>${buyin}</b> </div>
                <div> - Lives per Entrant: <b>{1+parseInt(rebuys)} LIVES</b> </div>
                <div> - Rebuy/Extra Life Cost: <b>${rebuyPrice}</b> </div>
                <div> - Insurance: <b>{insurance > 0 ? `AVAILABLE ($${insurance})` : 'NOT OFFERED'}</b> </div>
                <div> - Bonus Pool: <b>{bonusPoolActive ? 'ACTIVATED' : 'INACTIVE'}</b> </div>
                {activePool.game === 'mlb' && 
                  <div> - Shoot the Moon: <b>{shootMoonActive ? 'ACTIVATED' : 'INACTIVE'}</b> </div>
                }
                {bonusPoolActive ?
                  <>
                    <div> - Bonus Pool Cadence: Entrants can enter/leave Bonus Pool
                      {bonusPoolCadence === 2 ? <b> Once Per Month </b> :
                        bonusPoolCadence === 1 ? <b> at Any Time </b> : <b> at Beginning of Season </b>
                      }
                    </div>
                    <div> - Bonus Pool Will Shut Down With: <b>{bonusPoolShutdown} or Less Entrants Remaining</b> </div>
                    <div> - Your Pool's Active Bonuses are: <b>{displayBonuses()}</b></div>
                  </>
                  : null
                }
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => setConfirmPool(false)}>
                  Back
                </Button>
                <Button onClick={activatePool} style={{backgroundColor: '#6638f0', color: 'white'}}>
                  <Icon name='check circle' />
                  ACTIVATE POOL
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid>
        : null}
      </div>
    )
  } else {
    return <Loader className="loader-down" active inline='centered' size='massive'> LOADING POOL OPTIONS ... </Loader>;
  }
}

function mapStateToProps ({ auth, league, pendingSeasonParams, bonusOptions }) {
  return ({ auth, league, pendingSeasonParams, bonusOptions })
}

export default connect (mapStateToProps, {activateLeaguePool, getBonusOptions}) (LeagueGameMgmt);
