import React from 'react';

export default function LeagueBanner ({ name }) {
  return (
    <div className="league-banner">
      <img src={'https://alasurvivor.s3-us-west-2.amazonaws.com/purple_kobe.jpg'} alt='kobe'/>
      <div className="banner-text">
      {name}
      </div>
    </div>
  )
}
