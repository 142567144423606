import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Table, Image } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { recalculateBonus } from '../../actions';
import logos from '../../modules/logos';
import "../styles/usage-grid.scss";

const BonusRows = props => {
  const formatDollars = d => d >= 0 ? `$${(d).toFixed(2)}` : `-$${(Math.abs(d)).toFixed(2)}`;
  const headshot = p => props.game === 'nba' ? `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${p}.png` : `https://securea.mlb.com/mlb/images/players/head_shot/${p}.jpg`;
  
  const recalculateBonus = async (poolId, bonusId) => {
    console.log('poolId is ', poolId, ' and bonusId is ', bonusId);
    try {
      await axios.post('/api/bonus/recalculate', {poolId, bonusId});
      toast({
        type: 'warning',
        icon: 'check circle',
        color: 'violet',
        title: 'Bonus updated',
        description: `Bonus has been successfully recalculated`,
        animation: 'slide down',
        time: 3000
      });
    } catch (e) {
      toast({
        type: 'error',
        icon: 'exclamation',
        color: 'red',
        title: 'There was an error recalculating this bonus',
        description: `An error was encountered updating this bonus: ${e}. Please contact ALA support for help with this error.`,
        animation: 'slide down',
        time: 3000
      });
      console.log('error updating user settings was ', e);
    }
  }

  
  return props.bonusEvents.map(event => {
    return (
      <Table.Row className="bonus-row" key={event.id}>
        <Table.Cell> {props.admin ? <>
          <Button primary onClick={() => recalculateBonus(props.poolId, event.bonus_id)}> Recalculate </Button>
        </> 
        :  <>{event.username}</> 
        }
        </Table.Cell>
        <Table.Cell> {event.week} </Table.Cell>
        <Table.Cell> {moment(props.admin ? event.bonus_date : event.updated_at).format('M.D.YY')}</Table.Cell>
        <Table.Cell> 
          <a href={props.game === 'nba' 
            ? `https://stats.nba.com/game/00${event.gid}` 
            : `https://www.mlb.com/gameday/${event.gid}`} target="_blank" rel='noopener noreferrer'
          >{event.game_summary}</a>
        </Table.Cell>
        <Table.Cell> {event.bonus_name} </Table.Cell>
        <Table.Cell> <Image size='mini' src={logos[`${props.game}_${event.team_abb}`]} /> </Table.Cell>
        <Table.Cell> {!event.team_bonus ? <Image style={{display:'inline'}} src={headshot(event.player)} rounded size='mini' /> : <Image style={{display:'inline'}} src={logos[`${props.game}_${event.team_abb}`]} rounded size='mini' />} <span style={{display:'inline'}}>{event.desc}</span> </Table.Cell>
        { !props.admin && props.dollars !== 'Prizes' ?
          <Table.Cell> {event.dollars_total < 0 ? event.bonus_winners[0].length : null} </Table.Cell>
          : null
        }
        { !props.admin && 
          <Table.Cell style={event.dollars_total < 0 ? {color: '#CF2E2E'} : {fontWeight: '800'}}> {formatDollars(event.dollars_total)} </Table.Cell>
        }
      </Table.Row>
    )
  })
}

export default BonusRows;

