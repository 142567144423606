import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchFeed, filterFeed } from '../actions';
import { Table, Loader, Header, Icon, Grid, Segment } from 'semantic-ui-react';
import lillard from '../images/lillard_game_winner.jpg';
import './styles/user-profile.scss';
import './styles/open-pools.scss';
import EventFeed from './Feed';
import AuthModal from './AuthModal';
import ALAHeader from './Header';
import LeagueBanner from './leagues/LeagueBanner';

const profTheme = {
  "--color-main": "#6638f0"
};

const formatDollars = d => d >= 0 ? `$${(d).toFixed(2)}` : `-$${(Math.abs(d)).toFixed(2)}`;

function User ({ auth, feedEvents, fetchFeed, allLeagues }) {
  useEffect(() => {
    Object.keys(profTheme).map(key => {
      const value = profTheme[key];
      document.documentElement.style.setProperty(key, value);
    });
    fetchFeed();
  }, [auth])

  function renderTransactions() {
    return auth[0].transactions.map(t => {
      return (
        <Table.Row key={t.id}>
          <Table.Cell>{t.game}</Table.Cell>
          <Table.Cell>{t.season}</Table.Cell>
          <Table.Cell>{t.type}</Table.Cell>
          <Table.Cell>{formatDollars(t.amount)}</Table.Cell>
          <Table.Cell>{t.notes}</Table.Cell>
        </Table.Row>
      )
    })
  }

  function renderLeagues () {
    if (auth[0].leagues.length || auth[0].lgRequests.length) {
      let leagues = [];
      auth[0].lgRequests.filter(r => r.status !== 1).forEach(r => {
        leagues.push(
          <Table.Row key={r.league_id}>
            <Table.Cell> <Link to={`/league/${r.league_abb}`}>{allLeagues.find(l => l.id === r.league_id).name}</Link> </Table.Cell>
            <Table.Cell> {r.status === 2 ? 'Pending' : 'Rejected'}</Table.Cell>
          </Table.Row>
        )
      })
      auth[0].leagues.forEach(l => {
        leagues.push(
          <Table.Row key={l.id}>
            <Table.Cell> <Link to={`/league/${l.league_abb}`}>{l.league_name}</Link> </Table.Cell>
            <Table.Cell> Joined {moment(l.created_at).format('MM.DD.YY')}</Table.Cell>
          </Table.Row>
        )
      })
      return leagues;
    } else {
      return (
        <Table.Row>
          <Table.Cell> You have not requested to join any leagues yet.</Table.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Row>
      )
    }

  }

  if (auth !== null && feedEvents && allLeagues !== null) {
    return (
      <div>
        <ALAHeader />
        <div className="master-container">
          <img src={lillard} className="home-bg" alt='dame'></img>
          <LeagueBanner name={auth ? (`${auth[0].username}'s Profile`) : 'User Profile'} />
          <Segment attached='bottom'>
            <Grid>
              <Grid.Row className={`page-header profile`}>
                <Grid.Column width={16}>
                  <Header as='h2'>
                    <div style={{display: 'inline-block'}}>
                      <Icon name='user' circular size='large' inverted  />
                    </div>
                    <Header.Content>
                      Your User Profile
                      <Header.Subheader>
                        - <span className='error-color'><b>This page is currently under construction.</b></span> <br />
                        - For now, use this page to manage your league requests and view your activity and payment/prize transactions across all leagues and pools.
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid columns={2}>
              <Grid.Column width={11}>
                <div>
                  <div className="user-pg-header">Your Leagues and League Access Requests</div>
                  {auth ?
                    <>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell> League Name </Table.HeaderCell>
                            <Table.HeaderCell> Status </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {renderLeagues()}
                        </Table.Body>
                      </Table>
                    </>
                    :
                    <i>You are not logged in! <AuthModal/> to see your user activity here.</i>
                  }
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                <div className="user-pg-header">Your Game Activity</div>
                <Segment className="user-feed-seg">
                  {auth ? (
                    <EventFeed
                      events={feedEvents}
                      username={auth[0].username}
                    />
                  ) :
                    <i>You are not logged in! <AuthModal/> to see your user activity here.</i>
                  }
                </Segment>
                <div className="user-pg-header">Your Payments & Prizes</div>
                  {auth && auth[0].transactions.length > 0 ?
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell> Game </Table.HeaderCell>
                          <Table.HeaderCell> Season </Table.HeaderCell>
                          <Table.HeaderCell> Type </Table.HeaderCell>
                          <Table.HeaderCell> Amount </Table.HeaderCell>
                          <Table.HeaderCell> Notes </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {renderTransactions()}
                      </Table.Body>
                    </Table>
                    : <div> <i> No transactions in system </i> </div>
                  }
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <ALAHeader />
        <Loader className="loader-down" active inline='centered' size='massive'> RETRIEVING USER DATA ... </Loader>;
      </div>
    )
  }
}

function mapStateToProps ({ auth, feedEvents, allLeagues }) {
  return ({ auth, feedEvents, allLeagues })
}

export default connect (mapStateToProps, {fetchFeed, filterFeed } )(User)
