import React from 'react';
import { Table } from 'semantic-ui-react';

const NBABonusTable = ({ width }) => {
  return (
    <div>
      <Table celled style={{width: `${width}%`}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Bonus</Table.HeaderCell>
            <Table.HeaderCell>Achievement</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell> <b>$50</b> </Table.Cell>
            <Table.Cell> 50-point player game </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> <b>$50</b> </Table.Cell>
            <Table.Cell> 20-20 player game </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> <b>$40</b> </Table.Cell>
            <Table.Cell> 140 team points (in regulation) </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> <b>$30</b> </Table.Cell>
            <Table.Cell> Undefeated week (team must go 4-0 or better) </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> <b>$25</b> </Table.Cell>
            <Table.Cell> 30-point team victory </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> <b>$20</b> </Table.Cell>
            <Table.Cell> Double-OT (or later) team victory </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> <b>$15</b> </Table.Cell>
            <Table.Cell> Player triple-double </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default NBABonusTable;

// <Table.Row>
//   <Table.Cell> <b>$40</b> </Table.Cell>
//   <Table.Cell> 20-0 team scoring run </Table.Cell>
// </Table.Row>
